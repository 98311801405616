import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { FC, useState } from 'react'
import { Controller } from 'react-hook-form'
import { MUISelectModal } from '../../../Models'
import { ClearIcon } from '@mui/x-date-pickers'

const MUISelectChange: FC<MUISelectModal> = ({
  options = [],
  error,
  lable,
  name,
  value,
  control,
  rules,
  labelKey = 'label',
  valueKey = 'value',
  required = false,
  disabled = false,
  noClear = false,
  defaultValueIndex = NaN,
  onChange,
}) => {
  const notClearable =
    options.length === 2 &&
    options.some((option) => option.value === 'ACTIVE') &&
    options.some((option) => option.value === 'INACTIVE')

  const [showClear, setShowClear] = useState(false)

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <FormControl
            className='c-select'
            fullWidth
            error={!!error?.[name]}
            sx={{ textAlign: 'left' }}
            onMouseEnter={() => setShowClear(true)}
            onMouseLeave={() => setShowClear(false)}
          >
            <div className='select-wrapper'>
              <InputLabel id='demo-simple-select-label'>
                <span>
                  {lable}
                  {required && <span style={{ color: '#D4351C' }}>*</span>}
                </span>
              </InputLabel>
              <Select
                className='c-select-menu-item'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                data-testid={lable}
                onClose={() => setShowClear(false)}
                label={
                  <span>
                    {lable}
                    {required && <span style={{ color: '#D4351C' }}>*</span>}
                  </span>
                }
                inputProps={{ id: name }}
                {...field}
                defaultValue={options?.length > 0 ? options?.[defaultValueIndex]?.value : ''}
                IconComponent={KeyboardArrowDownIcon}
                disabled={disabled}
                value={(value || field.value) ?? ''}
                onChange={onChange}
              >
                {options?.length > 0 &&
                  options?.map((item: any, index: number) => {
                    return (
                      <MenuItem
                        disabled={item?.disable || false}
                        key={`${item[valueKey]}_${item[labelKey]}`}
                        value={item[valueKey]}
                        data-testid={item?.disable ? 'disabledPlatform' : 'enabledPlatform'}
                      >
                        {item[labelKey]}
                      </MenuItem>
                    )
                  })}
              </Select>
              {field.value &&
                (typeof field?.value === 'number' || field.value?.length > 0) &&
                !noClear &&
                !notClearable &&
                showClear &&
                !disabled && (
                  <IconButton
                    data-testid='clear-select'
                    size='small'
                    onClick={() => {
                      onChange?.({
                        target: {
                          name,
                          value: '',
                        },
                      })
                    }}
                    sx={{
                      position: 'absolute',
                      right: '1.5rem',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    <ClearIcon sx={{ height: '1rem', width: '1rem' }} />
                  </IconButton>
                )}
            </div>
            <FormHelperText>{error?.[name]?.message as string}</FormHelperText>
          </FormControl>
        )
      }}
    />
  )
}

export default MUISelectChange
