import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../../Constants'
import { TIPS_MODULE } from '../../../Helpers/ManageTips'
import { findKeyByWords, getPageTitle, isValidObject } from '../../../Helpers/Util'
import {
  BreadcrumsModel,
  ParameterEditorComponentModal,
  defaultParameterData,
} from '../../../Models'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import '../../MangeRole/ManageRole.scss'
import { BackButton, Breadcrumbs, MUITipsCard } from '../../Shared'
import Parameterform from './Parameterform'
import ParameterInformation from './ParameterInformation'
import { viewParameterService } from '../../../Store/Slice/parameter.slice'
import { setLoader } from '../../../Store/Slice/loader.slice'
import updateTagMetaData from '../../../Helpers/UpdateTagMetaData'

const ParameterEditor: React.FC<ParameterEditorComponentModal> = ({
  parameterPageType,
  parameterid,
}: ParameterEditorComponentModal) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const initialParameterData: defaultParameterData = {
    parameterName: '',
    fieldName: '',
    fieldType: '',
    dataType: '',
    master: '',
    multiSelect: 'no',
    defaultValue: '',
    status: 'ACTIVE',
    esField: '',
    mandatory: 'no',
    addMore: 'no',
    placeholder: '',
    parameters: [],
    purpose: '',
    _id: '',
  }
  const [parameterDetail, setParameterDetail] = useState<defaultParameterData>(initialParameterData)

  const { VIEW, CREATE, EDIT } = CONSTANTS.PAGE_TYPE

  const getParameterData = useCallback(
    async (id: string) => {
      if (parameterPageType !== CREATE && (parameterPageType || parameterid)) {
        const _id = id
        dispatch(setLoader(false))
        const result = await dispatch(viewParameterService(_id as string) as any)
        if (!result?.payload?.data && isValidObject(result?.payload?.data) === false) {
          dispatch(showAlert([true, 'Data Not Found', 'error']))
          navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ParameterRepository}`)
        } else {
          const reqData: defaultParameterData & { parameterRepositoryId?: string } = {
            parameterName: result?.payload?.data?.parameterName,
            parameters: result?.payload?.data?.parameterRef?.map((item: any) => {
              return { key: item?._id, value: item?.parameterName }
            }),
            status:
              result?.payload?.data?.status?.toLowerCase() === 'active' ? 'ACTIVE' : 'INACTIVE',
            fieldName: result?.payload?.data?.fieldName,
            fieldType: result?.payload?.data?.fieldType,
            master: result?.payload?.data?.master,
            multiSelect: result?.payload?.data?.multiSelect ? 'yes' : 'no',
            purpose: result?.payload?.data?.purpose,
            defaultValue: result?.payload?.data?.defaultValue,
            esField: result?.payload?.data?.esField,
            mandatory: result?.payload?.data?.mandatory ? 'yes' : 'no',
            addMore: result?.payload?.data?.addMore ? 'yes' : 'no',
            placeholder: result?.payload?.data?.placeholder,
            dataType: result?.payload?.data?.dataType,
            _id: parameterid,
          }
          setParameterDetail(reqData)
          dispatch(setLoader(false))
        }
      }
    },
    [dispatch],
  )
  useEffect(() => {
    if (parameterid) {
      getParameterData(parameterid)
    }
  }, [parameterid, getParameterData])

  const pageType = ['view', 'create'].includes(parameterPageType as string)
    ? parameterPageType
    : 'edit'

  useEffect(() => {
    const title =
      pageType === EDIT || pageType === VIEW ? parameterDetail.parameterName : 'Create Parameter'

    updateTagMetaData({ title })

    return () => {
      updateTagMetaData({})
    }
  }, [pageType, parameterDetail])

  return (
    <div className={`role__editor p-16 ${parameterPageType !== VIEW ? 'pb-120' : ''}`}>
      <div className='p-8'>
        <Breadcrumbs
          breadcrums={
            BREADCRUMS.CREATE_PARAMETER(
              parameterPageType,
              getPageTitle(parameterPageType as string),
            ) as BreadcrumsModel[]
          }
        />
      </div>
      <div className='p-8'>
        <BackButton
          label={`${getPageTitle(parameterPageType as string)} Parameter`}
          className='col73'
          handleOnClick={() =>
            navigate(
              `/${PAGES_ROUTES.BoltMaster}/${
                PAGES_ROUTES.ParameterRepository +
                (parameterPageType === EDIT
                  ? '/' + PAGES_ROUTES.ViewTypeParameter + ('/' + parameterDetail?._id)
                  : '')
              }`,
            )
          }
        />
      </div>
      <div className='mt-1'>
        <div className='role__editor-wrapper'>
          <div className='role__editor-content p-8'>
            {parameterPageType === VIEW ? (
              <div className=''>
                <ParameterInformation getParameterData={getParameterData} view={parameterDetail} />
              </div>
            ) : (
              <Parameterform
                getParameterData={getParameterData}
                parameterDetail={parameterDetail}
                parameterid={parameterDetail?._id}
                parameterPageType={parameterPageType}
              />
            )}
          </div>
          <div className=' p-8  ml-1 '>
            <div>
              <MUITipsCard
                tipsArr={CONSTANTS.CREATE_PARAMETER_TIPS}
                module={TIPS_MODULE.MANAGE_USER}
                pageType={pageType}
                page={findKeyByWords(`parameter_repository ${pageType}`)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ParameterEditor
