import React, { useEffect, useState } from 'react'
import { Box, TextField, Checkbox, MenuItem, ListItemText, Popover, Radio } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { MUIButton } from '../../Shared'
import styles from './ListingFilter.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'

interface ListingFilterProps {
  lable: string
  id: string
  searchable: boolean
  clearAll: boolean
  clearFilter?: boolean
  options: { key: string; value: string }[]
  searchFunction?: (filter: any, id: string) => void
  isFilterSingleSelect?: boolean
  filters?: any
}

const ListingFilter: React.FC<ListingFilterProps> = ({
  searchable,
  lable,
  id,
  clearAll,
  clearFilter,
  options,
  searchFunction,
  isFilterSingleSelect,
  filters,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [clearLocationSearch, setClearLocationSearch] = useState(false)

  const open = Boolean(anchorEl)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const stateParam = new URLSearchParams(location?.search)?.get('state')
    if (stateParam && options?.some((option) => option?.key === stateParam)) {
      setSelectedOptions([stateParam])
    }
  }, [location?.search, options])
  useEffect(() => {
    if (clearFilter) {
      handleClearAll()
    }
  }, [clearFilter])
  useEffect(() => {
    if (clearLocationSearch) {
      if (id === 'state') {
        const searchParams = new URLSearchParams(location?.search)
        searchParams?.delete('state')
        navigate({
          pathname: location?.pathname,
          search: searchParams?.toString(),
        })
      }
      setClearLocationSearch(false)
    }
  }, [clearLocationSearch])

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedOptions(filters)
  }

  const handleSelectOption = (key: any) => {
    let updatedSelection = [...selectedOptions]
    if (updatedSelection.includes(key)) {
      updatedSelection = updatedSelection?.filter((option) => option !== key)
    } else {
      updatedSelection = [...updatedSelection, key]
    }
    setSelectedOptions(updatedSelection)
  }

  const handleSingleSelect = (key: any) => {
    setSelectedOptions([key])
  }

  const handleClearAll = () => {
    if (filters.length > 0) {
      if (searchFunction) searchFunction({}, id)
    }
    setSelectedOptions([])
    setSearchTerm('')
    setClearLocationSearch(true)
  }

  const handleCancel = () => {
    setSearchTerm('')
    handleClose()
  }

  const handleSave = () => {
    const filter: any = {}
    if (selectedOptions.length > 0) {
      filter.filterBy = id
      filter.filterCondition = 'in'
      filter.filterValue = selectedOptions
    }
    if (searchFunction) searchFunction(filter, id)
    setSearchTerm('')
    setAnchorEl(null)
    setClearLocationSearch(true)
  }

  useEffect(() => {
    if (filters?.length > 0) {
      setSelectedOptions(filters)
    }
  }, [filters])

  return (
    <div style={{ position: 'relative', top: '20px' }}>
      <MUIButton
        width={160}
        color='secondary'
        height='40px'
        type='button'
        fontSize={'15px'}
        label={lable}
        variant='outlined'
        endIcon={
          open ? (
            <KeyboardArrowUpIcon sx={{ fontSize: '24px !important' }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ fontSize: '24px !important' }} />
          )
        }
        handleClickCB={handleClick}
        sx={{
          borderRadius: '8px',
          opacity: '0.7',
          backgroundColor: '#ffffff',
          border: filters.length > 0 ? '2px solid #402080' : '',
          minWidth: 'max-content',
          '&:hover': {
            border: filters.length > 0 ? '2px solid #402080' : '',
          },
        }}
      />
      <Popover
        data-testid='popover_dialog'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: '10px' }}
      >
        <Box
          className={`${styles['popover-dialog']} filter-popover`}
          sx={{ width: '300px', height: '320px', position: 'relative' }}
        >
          <Box
            className={`${styles['search-container']}`}
            sx={{ textAlign: searchable ? 'center' : '', width: '100%', paddingInline: '16px' }}
          >
            {searchable ? (
              <TextField
                placeholder='Search...'
                value={searchTerm}
                onChange={handleSearch}
                sx={{
                  paddingTop: '8px',
                  width: '100%',
                  '& .MuiInputBase-root': {
                    height: '40px',
                  },
                }}
              />
            ) : (
              <Box
                sx={{
                  alignContent: 'center',
                  fontWeight: '600',
                  fontSize: '20px',
                  paddingLeft: '24px',
                  paddingTop: '12px',
                }}
              >
                {lable}
              </Box>
            )}
          </Box>

          {isFilterSingleSelect ? (
            <Box sx={{ maxHeight: '210px', overflowY: 'scroll' }}>
              {options
                .filter((option) => option.value.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((item) => (
                  <MenuItem
                    key={item.key}
                    value={item.key}
                    onClick={() => handleSingleSelect(item.key)}
                    sx={{ height: '50px' }}
                  >
                    <Radio
                      data-testid={`filter_checkbox_${item.key}`}
                      checked={selectedOptions[0] === item.key}
                    />
                    <ListItemText primary={item.value} />
                  </MenuItem>
                ))}
            </Box>
          ) : (
            <Box sx={{ maxHeight: '210px', overflowY: 'scroll' }}>
              {options
                .filter((option) => option.value.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((item) => (
                  <MenuItem
                    key={item.key}
                    value={item.key}
                    onClick={() => handleSelectOption(item.key)}
                    sx={{ height: '50px' }}
                  >
                    <Checkbox
                      data-testid={`filter_checkbox_${item.key}`}
                      checked={selectedOptions.includes(item.key)}
                    />

                    <ListItemText
                      primary={item.value}
                      className={`${styles['listingFilter-Dropdown']}`}
                    />
                  </MenuItem>
                ))}
            </Box>
          )}

          <Box
            className='d-flex'
            sx={{
              position: 'absolute',
              bottom: '0',
              width: 'inherit',
              gap: 0.5,
              paddingRight: '8px',
              paddingBottom: '8px',
            }}
          >
            {clearAll && (
              <MUIButton
                width={80}
                fontSize={14}
                height='40px'
                type='button'
                label={'Clear All'}
                variant='text'
                handleClickCB={handleClearAll}
              />
            )}

            <Box
              className='d-flex'
              sx={{
                gap: 2,
              }}
            >
              <MUIButton
                width={50}
                fontSize={14}
                height='36px'
                type='button'
                label={'Cancel'}
                variant='outlined'
                handleClickCB={handleCancel}
              />
              <MUIButton
                width={50}
                fontSize={14}
                height='36px'
                type='button'
                label={'Submit'}
                variant='contained'
                handleClickCB={handleSave}
              />
            </Box>
          </Box>
        </Box>
      </Popover>
    </div>
  )
}

export default ListingFilter
