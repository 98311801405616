import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BackButton,
  Breadcrums,
  MUIAutocompleteMultiSelect,
  MUIBox,
  MUIButton,
  MUICheckbox,
  MUISelect,
  MUISelectWithoutControl,
} from '../../Components/Shared'
import CONSTANTS, { BREADCRUMS, PAGE_NAME } from '../../Constants'
import { BreadcrumsModel } from '../../Models'
import { Grid, Checkbox } from '@mui/material'
import MagnifyGlass from '../../Components/Icons/MagnifyGlass'
import { useForm } from 'react-hook-form'
import { DragableIcon } from '../../Components/Icons'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import { LISTING_PREFERENCES, USER_CONFIGURATION } from '../../Constants/ApiDefinations'
import mainApiService from '../../Services'
import { showAlert } from '../../Store/Slice/alert.slice'
import { useAppDispatch } from '../../Store/Hooks'
import { setLocalStorage } from '../../Helpers/Storage'
import { setLoader } from '../../Store/Slice/loader.slice'

const ListingScreenPreferences = () => {
  const navigate = useNavigate()
  const [module, setModule] = useState('container')
  const [parameterOptions, setParameterOptions] = useState<any[]>([])
  const [selectedParameter, setSelectedParameter] = useState<any[]>([])
  const [checkedState, setCheckedState] = useState<{
    [key: string]: { sort: boolean; filter: boolean }
  }>({})
  const [disabledOption, setDisabledOptions] = useState<any[]>([])
  const dispatch = useAppDispatch()

  const { control, formState } = useForm<any>()

  useEffect(() => {
    setSelectedParameter([])
    getPreselectedData()
  }, [module])

  const getPreselectedData = async () => {
    const parameterList = LISTING_PREFERENCES.LISTING_PARAMETERS(module)
    dispatch(setLoader(true))
    const result: any = await mainApiService(parameterList)
    if (result?.responseCode === 200) {
      dispatch(setLoader(false))
      const parameterData = result?.data?.parameters
      setParameterOptions(parameterData)

      const disabledOptions = parameterData.filter((item: any) => item?.isDisabled)
      disabledOptions.sort((prevItem: any, nextItem: any) => prevItem.sequence - nextItem.sequence)
      const disaleList = disabledOptions.map((item: any) => item?.label)

      const preSelected = parameterData.filter((item: any) => item?.isSelected)
      preSelected.sort((prevItem: any, nextItem: any) => prevItem.sequence - nextItem.sequence)

      setDisabledOptions(disaleList)
      setSelectedParameter(preSelected)
    } else {
      dispatch(setLoader(false))
      dispatch(showAlert([true, result?.message, 'error']))
    }
  }

  const handleModuleChange = (event: any) => {
    setModule(event)
  }

  const handleCheckboxChange = (e: any, id: string, type: 'yes' | 'filter' | 'sort') => {
    const checked = e.target.checked
    if (type === 'filter' || type === 'sort') {
      setSelectedParameter((prev) => {
        const updated = prev.map((param) => {
          if (param.id === id) {
            if (type === 'sort') {
              return { ...param, sort: checked }
            }
            if (checked === true) {
              return { ...param, showFilter: checked, sort: true }
            }
            return { ...param, showFilter: checked }
          }
          return param
        })
        return updated
      })
    } else if (type === 'yes') {
      setSelectedParameter((prev) => prev.filter((param) => param.id !== id))
    }
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return

    const items = Array.from(selectedParameter)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    items.forEach((item, index) => {
      item.sequence = index + 1
    })

    setSelectedParameter(items)
  }

  const handleOnSubmit = async (e: any) => {
    e.preventDefault()
    const payload = {
      module,
      parameters: selectedParameter,
    }

    const updatePreference = LISTING_PREFERENCES.UPDATE_USER_LISTING_PREERENCES(payload)
    const result: any = await mainApiService(updatePreference)

    if (result?.responseCode === 200) {
      dispatch(showAlert([true, result?.message, 'success']))
      const requiredApiData = USER_CONFIGURATION.GET_LISTING_CONFI()
      const userConfig = await mainApiService(requiredApiData)
      setLocalStorage(CONSTANTS.LOCAL_KEYS.LISTING_COLUMN, userConfig.data || {})
    }
  }

  const handleParamInputChange = (newValue: any, details: any) => {
    const disabledItems = selectedParameter.filter((item: any) => item?.isDisabled === true)
    let updatedValues = []

    if (newValue.length === 0) {
      updatedValues = disabledItems.map((item: any, index: any) => ({
        ...item,
        isSelected: true,
        sequence: index + 1,
      }))
    } else {
      updatedValues = newValue.map((item: any, index: any) => ({
        ...item,
        isSelected: true,
        sequence: index + 1,
      }))
    }

    updatedValues = updatedValues.map((data: any) =>
      data?.id === details?.option?.id ? { ...data, sort: false, showFilter: false } : data,
    )

    setSelectedParameter(updatedValues)
  }

  return (
    <div className='listing-screen p-16'>
      <form action=''>
        <div className='p-8'>
          <Breadcrums breadcrums={BREADCRUMS?.LISTING_SCREEN_PREFERENCE() as BreadcrumsModel[]} />
        </div>
        <div className='p-8'>
          <BackButton
            label={PAGE_NAME.ListingScreenPreferance}
            className='col73'
            handleOnClick={() => navigate(-1)}
          />
        </div>

        <MUIBox>
          <div className='heading-6 d-flex text-left rolebox__header-padding'>Filter</div>
          <Grid container columnSpacing={10} rowSpacing={4} className='rolebox__header-padding'>
            <Grid item xs={6}>
              <MUISelectWithoutControl
                name='module'
                lable='Module'
                valueKey='key'
                labelKey='value'
                control={control}
                options={CONSTANTS.BOLT_MODULES}
                onChange={handleModuleChange}
                value={module}
                noClear
              />
            </Grid>
            <Grid item xs={6}>
              <MUIAutocompleteMultiSelect
                // width='480px'
                label='Search by Parameter Name'
                LabelIcon={<MagnifyGlass height={25} width={25} />}
                options={parameterOptions}
                labelKey='label'
                onInputChange={(e: any, newValue: any, reason: any, details: any) =>
                  handleParamInputChange(newValue, details)
                }
                limitTags={4}
                highlighSelectedField={true}
                disabledOptions={disabledOption}
                value={selectedParameter}
                disabledOptionsByLable='label'
              />
            </Grid>
          </Grid>
        </MUIBox>
        <div className='mt-2 mb-5'>
          <DragDropContext onDragEnd={onDragEnd}>
            <MUIBox>
              <div className='heading-6 d-flex text-left rolebox__header-padding bg-col5'>
                Table Headers
              </div>

              <Droppable droppableId='droppable'>
                {(provided) => (
                  <Grid
                    container
                    className='bg-col27 p-4'
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <Grid item xs={12}>
                      <Grid container spacing={2} className='rolebox__header-padding'>
                        <Grid item xs={5} sx={{ textAlign: 'left' }}>
                          <b>Parameters</b>
                        </Grid>
                        <Grid item xs={2}>
                          <b>Yes</b>
                        </Grid>
                        <Grid item xs={2}>
                          <b>Sorting</b>
                        </Grid>
                        <Grid item xs={2}>
                          <b>Filter</b>
                        </Grid>
                      </Grid>

                      {selectedParameter.map((param, index) => (
                        <Draggable draggableId={param.id} key={param.id} index={index}>
                          {(provided) => (
                            <Grid
                              container
                              key={param.key}
                              className='param-listing'
                              alignItems='center'
                              ref={provided.innerRef}
                              {...provided.draggableProps} // Keep draggable props on the row
                            >
                              {/* Drag Handle - Apply dragHandleProps to icon only */}

                              <Grid item xs={5} className='d-flex align-items-center'>
                                <div {...provided.dragHandleProps} className='drag-icon-container'>
                                  <DragableIcon />
                                </div>
                                <span className='icon' /> {param?.label}
                              </Grid>
                              <Grid item xs={2} className='text-center'>
                                <MUICheckbox
                                  checked
                                  disabled={param.isDisabled}
                                  handleChange={(e) => handleCheckboxChange(e, param.id, 'yes')}
                                />
                              </Grid>
                              <Grid item xs={2} className='text-center'>
                                <MUICheckbox
                                  checked={param.sort}
                                  disabled={param.isDisabled}
                                  handleChange={(e) => handleCheckboxChange(e, param.id, 'sort')}
                                />
                              </Grid>
                              <Grid item xs={2} className='text-center'>
                                <MUICheckbox
                                  checked={param.showFilter}
                                  disabled={param.isDisabled}
                                  handleChange={(e) => handleCheckboxChange(e, param.id, 'filter')}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Grid>
                  </Grid>
                )}
              </Droppable>
            </MUIBox>
          </DragDropContext>
        </div>

        <div className='submit__handler'>
          <div className='submit__handler-btn-cont listing-pref'>
            <MUIButton
              size='large'
              type={'button'}
              label={'Submit'}
              variant='contained'
              width={200}
              handleClickCB={(e: any) => handleOnSubmit(e)}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ListingScreenPreferences
