import React, { useEffect, useState } from 'react'
import { useForm, Resolver } from 'react-hook-form'
import { Grid } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { MUIAutocompleteMultiSelect, MUIBox, MUIButton, MUIField, MUISelect } from '../../Shared'
import CONSTANTS, {
  PAGES_ROUTES,
  PARAMETER_VALIDATION_SCHEMA,
  VALIDATIONS,
} from '../../../Constants'
import { FormValues, ParameterListResponse, createParameterReqData } from '../../../Models'
import {
  createParameterService,
  updateParameterService,
} from '../../../Store/Slice/parameter.slice'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { unwrapResult } from '@reduxjs/toolkit'
import { combineErrorMessage, handleResponse, trimStrings } from '../../../Helpers/Util'
import { useNavigate } from 'react-router-dom'
import { setLoader } from '../../../Store/Slice/loader.slice'
import { PARAMETER_MANAGEMENT_CRUD } from '../../../Constants/ApiDefinations'
import mainApiService from '../../../Services'
import useFetchConfigurationData from '../../../Hooks/useFetchConfigurationData'
import { PARAMETER_REPOSITORY } from '../../../Constants/Texts'
interface ParameterFormProps {
  parameterid?: string
  getParameterData: CallableFunction
  parameterPageType: 'create' | 'edit' | 'view'
  parameterDetail: FormValues
}
interface ParameterMaster {
  key: string
  value?: string
}
const ParameterForm: React.FC<ParameterFormProps> = ({
  parameterid,
  parameterPageType,
  parameterDetail,
  getParameterData,
}) => {
  const [parameterListData, setParameterListData] = useState<ParameterMaster[]>([])
  const [initialValues, setInitialValues] = useState<FormValues>(parameterDetail)
  const [selectedParameters, setSelectedParameters] = useState<any[]>([])
  const resolver: Resolver<any> = yupResolver(PARAMETER_VALIDATION_SCHEMA)
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setError,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: resolver,
    defaultValues: initialValues,
    mode: 'onChange',
  })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { VIEW, CREATE, EDIT } = CONSTANTS.PAGE_TYPE
  const { state, fetchConfigurationData } = useFetchConfigurationData()
  const [
    isMasterMandatory,
  ] = useState<boolean>(false)
  useEffect(() => {
    setSelectedParameters(parameterDetail?.parameters || [])
  }, [parameterDetail])

  useEffect(() => {
    fetchConfigurationData('PARAMETER_MASTER')
    fetchConfigurationData('DATA_TYPE')
    fetchConfigurationData('FIELD_TYPE')
  }, [fetchConfigurationData])
  const getParameterListFunction = async () => {
    dispatch(setLoader(true))
    const requestedData: any = PARAMETER_MANAGEMENT_CRUD.COMPLEX_PARAMETER_LIST()
    const result: any = await mainApiService(requestedData)

    if (result?.responseCode === 200) {
      const tempData: ParameterMaster[] = []
      const currentParameterId = parameterDetail?._id
      result?.data?.records?.forEach((ele: ParameterListResponse) => {
        if (ele?._id !== currentParameterId)
          tempData.push({
            value: ele?.parameterName,
            key: ele?._id || '',
          })
      })
      setParameterListData(tempData)
    } else {
      dispatch(showAlert([true, combineErrorMessage(result), 'error']))
    }
    dispatch(setLoader(false))
  }
  const saveParameter = async (data: any) => {
    const reqData: createParameterReqData & { parameterRepositoryId?: string } = {
      parameter: selectedParameters?.map((item) => item?.key || ''),
      parameterName: data?.parameterName,
      status: data?.status?.toLowerCase() === 'active' ? 'ACTIVE' : 'INACTIVE',
      fieldName: data?.fieldName,
      fieldType: data?.fieldType,
      master: data?.master,
      multiSelect: data?.multiSelect?.toLowerCase() === 'yes',
      purpose: data?.purpose,
      defaultValue: data?.defaultValue,
      esField: data?.esField,
      mandatory: data?.mandatory?.toLowerCase() === 'yes',
      addMore: data?.addMore?.toLowerCase() === 'yes',
      placeholder: data?.placeholder,
      dataType: data?.dataType,
    }
    if (parameterPageType === EDIT && parameterid) {
      reqData.parameterRepositoryId = parameterid
    }
    const service = parameterPageType === EDIT ? updateParameterService : createParameterService
    const result = await dispatch(service(reqData) as any)

    handleResult(result)
    const { message, error } = handleResponse(result)
    dispatch(showAlert([true, message, error ? 'error' : 'success']))
  }
  const findError = (unwrappedRes: any, altMessage: string) => {
    const errorFinder = (err: string) => err === altMessage
    return (
      unwrappedRes?.error?.errors?.find(errorFinder) ||
      unwrappedRes?.error?.data?.find(errorFinder) ||
      ''
    )
  }
  const handleResult = (result: any) => {
    const unwrappedRes = unwrapResult(result)
    if (unwrappedRes?.error) {
      // Handle specific validation errors
      const doesParameterNameExist = findError(
        unwrappedRes,
        VALIDATIONS.PARAMETER_NAME_ALREADY_EXIST,
      )
      const doesFieldNameExist = findError(unwrappedRes, VALIDATIONS.FIELD_NAME_ALREADY_EXIST)
      const isMasterInvalid = findError(unwrappedRes, VALIDATIONS.MASTER_INVALID_VALUE)
      const doesParameterExist = findError(unwrappedRes, VALIDATIONS.DEPENDENCY_ERROR_FOR_CONTAINER)
      if (doesParameterNameExist) {
        setError('parameterName', {
          type: 'manual',
          message: VALIDATIONS.PARAMETER_NAME_ALREADY_EXIST,
        })
      }
      if (doesFieldNameExist) {
        setError('fieldName', {
          type: 'manual',
          message: VALIDATIONS.FIELD_NAME_ALREADY_EXIST,
        })
      }
      if (isMasterInvalid) {
        setError('master', {
          type: 'manual',
          message: VALIDATIONS.MASTER_INVALID_VALUE,
        })
      }
      if (doesParameterExist) {
        setError('parameters', {
          type: 'manual',
          message: VALIDATIONS.DEPENDENCY_ERROR_FOR_CONTAINER,
        })
      }
      // Show general error alert if no specific validation error
      if (
        !doesParameterNameExist &&
        !doesFieldNameExist &&
        !isMasterInvalid &&
        !doesParameterExist
      ) {
        dispatch(showAlert([true, unwrappedRes?.error?.message, 'error']))
      }
      return
    }

    // Success Handling
    dispatch(showAlert([true, unwrappedRes?.message, 'success']))
    if (parameterPageType === CREATE) {
      navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ParameterRepository}`)
    } else if (!(parameterPageType === VIEW || parameterPageType === CREATE)) {
      getParameterData(parameterid)
      navigate(
        `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ParameterRepository}/${PAGES_ROUTES.PageTypeView}/${parameterid}`,
      )
    }
  }
  const onSubmit = (data: FormValues) => {
    const trimmedData = trimStrings(data)
    saveParameter(trimmedData)
  }
  useEffect(() => {
    if (parameterPageType === EDIT && parameterid) {
      const trimmedParameterDetail = trimStrings(parameterDetail)
      setInitialValues(trimmedParameterDetail)
      reset(trimmedParameterDetail)
    }
  }, [parameterid, parameterPageType, reset])
  const fieldType = watch('fieldType')
  useEffect(() => {
    if (fieldType?.toLowerCase() !== 'complextype') {
      setSelectedParameters([])
    }
    if (fieldType?.toLowerCase() === 'complextype') getParameterListFunction()
  }, [fieldType])
  const handleMultiSelectChange = (e: any, value: any) => {
    setSelectedParameters(value)
  }
  const disableSaveBtn = () => {
    const isComplexType = fieldType?.toLowerCase() === 'complextype'
    if (!isComplexType) return !isValid
    return !(selectedParameters?.length > 0 && isValid)
  }

  return (
    <MUIBox>
      <div className='heading-6 d-flex text-left rolebox__header-padding'>
        {PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.SUB_HEADER}
      </div>
      <div className={'hr'}></div>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' style={{ padding: 20 }}>
        <Grid container columnSpacing={10} rowSpacing={4}>
          <Grid item xs={6} md={6} lg={6} xl={6}>
            <MUIField
              name='parameterName'
              label={
                PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.PARAMETER_NAME
              }
              required
              type='text'
              control={control as any | undefined}
              error={errors}
              maxLength={100}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6} xl={6}>
            <MUIField
              name='fieldName'
              label={
                PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.FIELD_NAME
              }
              required
              type='text'
              control={control as any | undefined}
              error={errors}
              maxLength={100}
            />
          </Grid>
          <Grid item xs={6}>
            <MUISelect
              name='fieldType'
              lable={
                PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.FIELD_TYPE
              }
              valueKey='key'
              labelKey='value'
              required
              control={control as any | undefined}
              error={errors}
              options={state['FIELD_TYPE']}
              onChange={(e: any) => setValue('fieldType', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <MUISelect
              name='dataType'
              lable={PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.DATA_TYPE}
              required
              labelKey='value'
              valueKey='key'
              control={control as any | undefined}
              error={errors}
              options={state['DATA_TYPE']}
              onChange={(e: any) => setValue('dataType', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <MUISelect
              name='master'
              lable={PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.MASTER}
              control={control as any | undefined}
              error={errors}
              valueKey='key'
              labelKey='value'
              required={isMasterMandatory}
              options={state['PARAMETER_MASTER']}
              onChange={(e: any) => setValue('master', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <MUISelect
              name='multiSelect'
              lable={
                PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.MULTISELECT
              }
              required
              control={control as any}
              error={errors}
              defaultValueIndex={0}
              rules={{
                required: VALIDATIONS.REQUIRED,
              }}
              options={CONSTANTS.COMMON_YES_NO}
              onChange={(e: any) => setValue('multiSelect', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6} xl={6}>
            <MUIField
              name='defaultValue'
              label={
                PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.DEFAULT_VALUES
              }
              type='text'
              control={control as any | undefined}
              error={errors}
              maxLength={100}
            />
          </Grid>
          <Grid item xs={6}>
            <MUISelect
              name='status'
              lable={PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.STATUS}
              required
              control={control as any}
              error={errors}
              defaultValueIndex={0}
              rules={{ required: VALIDATIONS.REQUIRED }}
              options={CONSTANTS.ROLE_STATUS}
              onChange={(e: any) => setValue('status', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6} xl={6}>
            <MUIField
              name='esField'
              label={PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.ES_FIELD}
              type='text'
              control={control as any | undefined}
              error={errors}
              maxLength={100}
            />
          </Grid>
          <Grid item xs={6}>
            <MUISelect
              name='mandatory'
              lable={PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.MANDATORY}
              required
              control={control as any}
              error={errors}
              rules={{ required: VALIDATIONS.REQUIRED }}
              options={CONSTANTS.COMMON_YES_NO}
              onChange={(e: any) => setValue('mandatory', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6} xl={6}>
            <MUIField
              name='placeholder'
              label={
                PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.PLACEHOLDER
              }
              type='text'
              required
              control={control as any | undefined}
              error={errors}
              maxLength={300}
            />
          </Grid>
          <Grid item xs={6}>
            <MUISelect
              name='addMore'
              lable={PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.ADD_MORE}
              control={control as any}
              error={errors}
              rules={{ required: VALIDATIONS.REQUIRED }}
              options={CONSTANTS.COMMON_YES_NO}
              onChange={(e: any) => setValue('addMore', e.target.value)}
            />
          </Grid>

          {fieldType?.toLowerCase() === 'complextype' && (
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <MUIAutocompleteMultiSelect
                label={
                  PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.PARAMETERS
                }
                name='parameters'
                options={parameterListData || []}
                labelKey={'value'}
                multiple={true}
                error={errors}
                value={selectedParameters || []}
                onInputChange={(e: any, value: any) => handleMultiSelectChange(e, value)}
                required={true}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <MUIField
              name='purpose'
              label={PARAMETER_REPOSITORY.VIEW.SECTIONS.PARAMETER_INFORMATION.FIELD_NAMES.PURPOSE}
              required
              type='text'
              control={control as any | undefined}
              error={errors}
              maxLength={100}
            />
          </Grid>
          <Grid item xs={12} className='d-flex justify-content-end'>
            <MUIButton
              size='large'
              disabled={disableSaveBtn()}
              type={'submit'}
              label={'Save'}
              variant='contained'
              width={200}
              className={isValid ? 'c-btn' : ''}
            />
          </Grid>
        </Grid>
      </form>
    </MUIBox>
  )
}
export default ParameterForm
