import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { ClearIcon } from '@mui/x-date-pickers'
import { FC, useState } from 'react'

const MUISelectWithoutControl: FC<any> = ({
  options = [],
  error,
  lable,
  name,
  labelKey = 'label',
  valueKey = 'value',
  required = false,
  placeholder = '',
  onChange,
  value = '',
  id = '',
  disabled = false,
  disabledOptions = [],
  noClear,
}) => {
  const [showClear, setShowClear] = useState(false)

  return (
    <FormControl
      fullWidth
      error={!!error?.[name]}
      sx={{ textAlign: 'left', position: 'relative' }}
      onMouseEnter={() => setShowClear(true)}
      onMouseLeave={() => setShowClear(false)}
    >
      <InputLabel id='demo-simple-select-label'>
        <span>
          {lable}
          {required && <span style={{ color: '#D4351C' }}>*</span>}
        </span>
      </InputLabel>
      <div className='select-wrapper'>
        <Select
          labelId='demo-simple-select-label'
          id={id ? id : lable}
          data-testid={id ? id : lable}
          value={value}
          label={lable}
          disabled={disabled}
          placeholder={placeholder ?? lable}
          inputProps={{ id: name }}
          onChange={(event: any, arr: any) => onChange(event?.target.value, arr)}
          onClose={() => setShowClear(false)}
          IconComponent={KeyboardArrowDownIcon}
        >
          {options?.length &&
            options?.map((item: any) => (
              <MenuItem
                key={item[valueKey]}
                value={item[valueKey]}
                disabled={disabledOptions?.includes(item[valueKey])}
              >
                {item[labelKey]}
              </MenuItem>
            ))}
        </Select>
        {value &&
          (typeof value === 'number' || value?.length > 0) &&
          !noClear &&
          showClear &&
          !disabled && (
            <IconButton
              size='small'
              onClick={() => onChange?.('', null)}
              sx={{
                position: 'absolute',
                right: '1.5rem',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              <ClearIcon sx={{ height: '1rem', width: '1rem' }} />
            </IconButton>
          )}
      </div>
      <FormHelperText>{error?.[name]?.message as string}</FormHelperText>
    </FormControl>
  )
}

export default MUISelectWithoutControl
