export const FieldFunctions = {
  EQUALS: 'equals',
  ALPHA: 'alpha',
  ALPHANUM: 'alphanum',
  ALNUM_WITH_SPECIAL_CHARS: 'alnumWithSpecialChars',
  MAX: 'max',
  MIN: 'min',
  PATTERN: 'pattern',
  URI: 'uri',
  DATE_GREATER_THAN: 'dateGreaterThan',
  DATE_LESS_THAN: 'dateLessThan',
  DATE_LESS_THAN_EQUALS: 'dateLessThanEquals',
  DATE_GREATER_THAN_EQUALS: 'dateGreaterThanEquals',
  ISO: 'iso',
  VALIDATE_ASPECT_RATIO: 'validateAspectRatio',
  VALIDATE_FILE_TYPE: 'validateFileType',
  VALIDATE_RESOLUTION: 'validateResolution',
  COUNTRY_FOR_RATINGS: 'countryForRatings',
  LIMIT: 'Limit',
}

export const widgetDefinitionItemList = [
  { key: 'asset', value: 'Asset' },
  { key: 'widget', value: 'Widget' },
  { key: 'container', value: 'Container' },
]

export const containerDefinitionItemList = [
  { key: 'asset', value: 'Asset' },
  { key: 'widget', value: 'Widget' },
]
