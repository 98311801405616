import { memo, useEffect } from 'react'
import Dashboard from '../../Components/Home/Dashboard/Dashboard'
import RecentActivity from '../../Components/Home/RecentActivity'
import ItemStates from '../../Components/Home/ItemStates'
import updateTagMetaData from '../../Helpers/UpdateTagMetaData'
const Home = () => {
  useEffect(() => {
    updateTagMetaData({ title: 'Home' })
    return () => {
      updateTagMetaData({})
    }
  }, [])
  return (
    <div className='homepage-wrapper'>
      <div className='mb-2'>
        <Dashboard />
      </div>

      <div className='d-flex column-gap-20 mb-2'>
        <ItemStates />
        <RecentActivity />
      </div>
    </div>
  )
}

export default memo(Home)
