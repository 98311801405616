import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setLoader } from './loader.slice'
import mainApiService from '../../Services'
import { CONTAINER_MANAGEMENT_CRUD } from '../../Constants/ApiDefinations/index'
import { IManageContainerSlice } from '../../Models/ManageContainer'

const initialState: IManageContainerSlice = {
  containerDefinitionDetails: {},
  containerDetails: {},
  containerWidgetsList: [],
  containerAssetsList: [],
  parentContainerDetail: {},
}

const manageContainerSlice = createSlice({
  name: 'manageContainer',
  initialState,
  reducers: {
    setContainerDefinitionDetails: (state, action: PayloadAction<any>) => {
      state.containerDefinitionDetails = { ...action.payload }
    },
    setContainerDetails: (state, action: PayloadAction<any>) => {
      state.containerDetails = { ...action.payload }
    },
    replaceContainerWidgetsList: (state, action: PayloadAction<any>) => {
      state.containerWidgetsList = action.payload
    },
    // addContainerWidgetsList: (state, action: PayloadAction<any>) => {
    //   const existingIds = new Set(
    //     state.containerWidgetsList?.map((widget) => {
    //       const widgetId = widget?.widgetId?.value || widget?._id?.value
    //       if (!widgetId) {
    //         console.warn('Invalid widget ID found:', widget)
    //       }
    //       return widgetId
    //     }),
    //   )
    //   const newAssets = action.payload.filter((widget: any) => {
    //     const widgetId = widget?.widgetId?.value || widget?._id?.value
    //     if (!widgetId) {
    //       return false
    //     }
    //     return !existingIds.has(widgetId)
    //   })
    //   if (newAssets.length > 0) {
    //     state.containerWidgetsList = [...state.containerWidgetsList, ...newAssets]
    //   }
    // },
    addContainerAssetsList: (state, action: PayloadAction<any>) => {
      state.containerAssetsList = [...action.payload]
    },
    setParentContainerDetail: (state, action: PayloadAction<any>) => {
      state.parentContainerDetail = { ...action.payload }
    },
    resetContainerData: () => initialState,
  },
})
export const {
  setContainerDefinitionDetails,
  setContainerDetails,
  addContainerAssetsList,
  // addContainerWidgetsList,
  replaceContainerWidgetsList,
  setParentContainerDetail,
  resetContainerData,
} = manageContainerSlice.actions
export default manageContainerSlice

export const manageContainerSelector = (state: any) => state.manageContainer

export const containerDetailSelector = createSelector(
  [manageContainerSelector],
  (parameters) => parameters.containerDetails,
)
export const containerDefinitionDetailSelector = createSelector(
  [manageContainerSelector],
  (parameters) => parameters.containerDefinitionDetails,
)
export const containerWidgetListSelector = createSelector(
  [manageContainerSelector],
  (parameters) => parameters.containerWidgetsList,
)
export const containerAssetListSelector = createSelector(
  [manageContainerSelector],
  (parameters) => parameters.containerAssetsList,
)
export const parentContainerDetailSelector = createSelector(
  [manageContainerSelector],
  (parameters) => parameters.parentContainerDetail,
)

export const getContainerDefinitionDetailById = createAsyncThunk(
  'manageContainer/getContainerDefinitionDetailById',
  async (data: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: ContainerCRUDAPIDefinitionModal =
      CONTAINER_MANAGEMENT_CRUD.GET_CONTAINER_DEFINITION_DETAIL_BY_ID(data)
    const result: any = await mainApiService(requestedData)
    dispatch(setContainerDefinitionDetails(result?.data))
    dispatch(setLoader(false))
    return result
  },
)
export const getContainerDetailById = createAsyncThunk(
  'manageContainer/getContainerDetailById',
  async (data: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: ContainerCRUDAPIDefinitionModal =
      CONTAINER_MANAGEMENT_CRUD.GET_CONTAINER_DETAIL_BY_ID(data)
    const result: any = await mainApiService(requestedData)
    dispatch(setContainerDetails(result?.data))
    dispatch(setLoader(false))
    return result
  },
)
export const getContainerListAPICall = createAsyncThunk(
  'manageContainer/containertList',
  async (payload: any, { dispatch }) => {
    const requestedData: any = CONTAINER_MANAGEMENT_CRUD.LISTING(payload)
    const data: any = await mainApiService(requestedData)
    return data
  },
)
export const getWidgetsDetailByIds = createAsyncThunk(
  'manageContainer/getWidgetsDetailByIds',
  async (data: any, { dispatch }) => {
    // data as   ['3qdcsfu8q','y43iu4ywuy']
    dispatch(setLoader(true))
    const requestedData: ContainerCRUDAPIDefinitionModal = CONTAINER_MANAGEMENT_CRUD.WIDGET_META(
      data.data,
    )
    const result: any = await mainApiService(requestedData)
    // if (data.mode === 'replace') {
    dispatch(replaceContainerWidgetsList([...result.data.records]))
    // } else {
    //   dispatch(addContainerWidgetsList([...result.data.records]))
    // }
    // dispatch(setLoader(false))
    return result
  },
)

export interface ContainerCRUDAPIDefinitionModal {
  url: string
  method: string
  data: any
}
