export const COMMON_LISTING_FILTERS = {
  TYPE: 'Filter By Type',
  LANGUAGE: 'Filter By Language',
  STATE: 'Filter By State',
  LAYOUT: 'Filter By Layout',
  CLUSTER: 'Filter By Cluster',
  PLATFORM: 'Filter By Platform',
  COUNTRY: 'Filter By Country',
}
export const SEGMENT = {
  LISTING: {
    TITLE: 'Manage Segments',
    COLUMN_WIDGET_TYPE: 'Manage Segments Type',
    SEARCH_PLACE_HOLDER: 'Search by segment title, Id',
    CREATE_BUTTON: 'Create Segment',
  },
  VIEW: {
    PAGE: {
      HEADER: 'View Segment',
    },
    SECTIONS: {
      SEGMENT_CONTENTS: {
        SUB_HEADER: 'Segment Contents',
        FIELD_NAMES: {
          SEGMENT_NAME: 'Segment Name',
          DESCRIPTION: 'Description',
        },
        NESTED_SEGMENTS: {
          FIREBASE_SEGMENT: {
            SUB_HEADER: 'Firebase Segment',
            FIELD_NAMES: {
              AB_SEGMENT: 'AB Segments',
              PUBLISHING_SEGMENT: 'Publishing Segments',
            },
          },
          EVERGENT_SEGMENT: {
            SUB_HEADER: 'Evergent Segments',
            FIELD_NAMES: {
              EVERGENT_NAME: 'Segments',
            },
          },
          COUNTRY_SEGMENT: {
            SUB_HEADER: 'Country',
            FIELD_NAMES: {
              COUNTRY_STATE: 'State',
              CLUSTER: 'Cluster',
              PACK_NAME: 'Pack Name',
              PACK_FAMILY: 'Pack Family',
            },
          },
        },
        PLATFORM: {
          SUB_HEADER: 'Platform', // Subheader for platform section
          FIELD_NAMES: {
            PLATFORM: 'Platform',
            MIN_VERSION: 'Minimum Version',
            MAX_VERSION: 'Maximum Version',
            SELECT_VERSION: 'Select Version',
          },
        },
      },
    },
    CTAS: {
      ACTIVE: 'Active',
      INACTIVE: 'In Active',
      DELETE: 'Delete',
      EDIT: 'Edit',
    },
  },
  CREATE: {
    TITLE: 'Create Segment',
    SECTIONS: {
      SEGMENT_CONTENTS: {
        TITLE: 'Segment Content',
        INPUT_FIELDS: {
          SEGMENT_NAME: 'Segment Name',
          DESCRIPTION: 'Description',
        },
        FIREBASE_SEGMENTS: {
          TITLE: 'Firebase Segments',
          INPUT_FIELDS: {
            AB_SEGMENT: 'AB Segment',
            PUBLISHING_SEGMENT: 'Publishing Segment',
          },
        },
      },
      PARAMETERS: {
        TITLE: 'Parameters',
        COUNTRY: {
          TITLE: 'Country',
          INPUT_FIELDS: {
            COUNTRY: 'Country',
            STATE: 'State',
            CLUSTER: 'Cluster',
            PACK_FAMILY: 'Pack Family',
            PACK_NAME: 'Pack Name',
          },
        },
        PLATFORM: {
          TITLE: 'Platform',
          INPUT_FIELDS: {
            PLATFORM: 'Platform',
            MINIMUM_VERSION: 'Minimum Version',
            MAXIMUM_VERSION: 'Maximum Version',
            SELECT_VERSION: 'Select Version',
          },
        },
      },
      ADD_MORE_CTA: {
        CTA: 'Add More',
      },
    },
    SUBMIT_CTA: 'Submit',
  },
}
export const ASSOCIATED_ITEMS = {
  LINK_CTA: 'Associated Item',
  ASSOCIATED_TABS: [
    { key: 'container', value: 'Containers' },
    { key: 'widget', value: 'Widgets' },
    { key: 'siteNavigation', value: 'Site Navigation' },
  ],
  LISTING: {
    TITLE: 'Associated Item',
    COLUMN_WIDGET_TYPE: 'Manage Segments Type',
    SEARCH_PLACE_HOLDER: 'Search by Title, Id',
    FILTERS: COMMON_LISTING_FILTERS,
  },
}
export const CONTAINER_DEFINITION = {
  CONTENT_LISTING: {
    TITLE: 'Container Definition',
    SEARCH_PLACE_HOLDER: 'Search By Container Layout, Type',
    CREATE_BUTTON: 'Create Container Type',
  },
  VIEW: {
    PAGE: {
      HEADER: 'View Container Type',
    },
    SECTIONS: {
      CONTAINER_DETAIL: {
        SUB_HEADER: 'Container Detail',
        KEY_VALUES: {
          CONTAINER_TYPE: 'Container Type',
          TITLE: 'Container Type Title',
        },
      },
    },
  },
}
export const MANAGE_CONTAINER = {
  LISTING: {
    TITLE: 'Manage Containers',
    SEARCH_PLACE_HOLDER: 'Search by Container title or Id',
    CREATE_BUTTON: 'Create Container',
    FILTERS: COMMON_LISTING_FILTERS,
  },
  VIEW: {
    PAGE: {
      HEADER: 'View Container',
    },
    SECTIONS: {
      CONTAINER_DETAILS: {
        SUB_HEADER: 'Container Details',
        KEY_VALUES: {
          CONTAINER_NAME: 'Container Name',
          STATUS: 'Status',
        },
        CTAS: {
          EDIT: 'Edit',
          DELETE: 'Delete',
        },
      },
    },
  },
  CREATE: {
    TITLE: 'Trays',
    HEADER: {
      HEADER_LABEL: 'Widget',
      KEY_VALUE_TEXTS: {
        WIDGET_TYPE: 'Widget Type',
        LAYOUT: 'Layout',
        TITLE: 'Title',
        SEGMENT: 'Segment',
        DATA_ENGINE: 'Data Engine',
        WIDGET_ID: 'Widget ID',
        CREATED_ON: 'Created On',
        LAST_UPDATED_ON: 'Last Updated On',
        LAST_FEATURED_ON: 'Last Featured On',
      },
    },
    MAIN_SECTION: {
      LEFT_BAR: {
        HEADER: 'Parameter Catagories',
        CATEGORIES: {
          DATA: 'Data',
          PRESENTATION: 'Presentation',
          BEHAVIOR: 'Behavior',
          CHECKLIST: 'Checklist',
        },
      },
      MIDDLE_SECTION: {
        HEADER: 'Data',
        DATA_SELECTION_ENGINE: {
          CTA_TEXT: 'Please choose the data engine to curate the content inside the widget.',
          ENGINES: {
            MANUAL: 'Manual',
            AUTOMATED: 'Automated',
            RECOMMENDATION: 'Recommendation',
          },
          ACTIONS: {
            SUBMIT: 'Submit',
            MARK_AS_DONE: 'Mark as Done',
          },
        },
      },
    },
  },
}
export const WIDGET_DEFINITION = {
  CONTENT_LISTING: {
    TITLE: 'Widget Definition',
    SEARCH_PLACE_HOLDER: 'Search by Widget Layout, Type',
    CREATE_BUTTON: 'Create Widget Type',
  },
  VIEW: {
    PAGE: {
      HEADER: 'View Widget Type',
    },
    SECTIONS: {
      WIDGET_DETAIL: {
        SUB_HEADER: 'Widget Details',
        KEY_VALUES: {
          WIDGET: 'Widget',
          TYPE: 'Type',
        },
      },
    },
  },
}
export const MANAGE_WIDGET = {
  LISTING: {
    TITLE: 'Manage Widgets',
    SEARCH_PLACE_HOLDER: 'Search by Widget title or Id',
    CREATE_BUTTON: 'Create Widget',
    FILTERS: COMMON_LISTING_FILTERS,
  },
  VIEW: {
    PAGE: {
      HEADER: 'View Widget',
    },
    SECTIONS: {
      WIDGET_DETAILS: {
        SUB_HEADER: 'Widget Details',
        KEY_VALUES: {
          WIDGET_NAME: 'Widget Name',
          STATUS: 'Status',
        },
        CTAS: {
          EDIT: 'Edit',
          DELETE: 'Delete',
        },
      },
    },
  },
  CREATE: {
    TITLE: 'Trays',
    HEADER: {
      HEADER_LABEL: 'Widget',
      HEADER_TEXTS: {
        WIDGET_TYPE: 'Widget Type',
        LAYOUT: 'Layout',
        TITLE: 'Title',
        SEGMENT: 'Segment',
        DATA_ENGINE: 'Data Engine',
        WIDGET_ID: 'Widget ID',
        CREATED_ON: 'Created On',
        LAST_UPDATED_ON: 'Last Updated On',
        LAST_FEATURED_ON: 'Last Featured On',
      },
    },
    MAIN_SECTION: {
      LEFT_BAR: {
        HEADER: 'Parameter Catagories',
        CATEGORIES: {
          DATA: 'Data',
          PRESENTATION: 'Presentation',
          BEHAVIOR: 'Behavior',
          CHECKLIST: 'Checklist',
        },
      },
      MIDDLE_SECTION: {
        HEADER: 'Data',
        DATA_SELECTION_ENGINE: {
          CTA_TEXT: 'Please choose the data engine to curate the content inside the widget.',
          ENGINES: {
            MANUAL: 'Manual',
            AUTOMATED: 'Automated',
            RECOMMENDATION: 'Recommendation',
          },
          ACTIONS: {
            SUBMIT: 'Submit',
            MARK_AS_DONE: 'Mark as Done',
          },
        },
      },
    },
  },
}

export const ROLE_MANAGEMENT = {
  LISTING: {
    TITLE: 'Role Management',
    SEARCH_PLACE_HOLDER: 'Search by role name',
    CREATE_BUTTON: 'Add New Role',
  },
  VIEW: {
    PAGE: {
      HEADER: 'View Role',
    },
    SECTIONS: {
      ROLE_INFORMATION: {
        SUB_HEADER: 'Role Information',
        FIELD_NAMES: {
          ROLE_NAME: 'Role Name',
          ROLE_CREATION_DATE: 'Role Creation Date',
          LAST_UPDATED_ON: 'Last Updated On',
          STATUS: 'Status',
        },
        CTAS: {
          ACTIVE: 'Active',
          INACTIVE: 'Inactive',
          DELETE: 'Delete',
          EDIT: 'Edit',
        },
      },
      ACCESS_AND_PERMISSIONS: {
        SUB_HEADER: 'Access and Permissions',
        BOLT_MODULE: {
          TITLE: 'Bolt Module',
          CTA: 'Select All',
          COLUMNS: {
            PERMISSIONS: 'Permissions',
            YES: 'Yes',
          },
          MODULES: 'Modules',
        },
        TOOL_GATES: {
          TITLE: 'Tool Gates',
          CTA: 'Select All',
          COLUMNS: {
            PERMISSIONS: 'Permissions',
            STATE_TRANSITIONS: 'State Transitions',
          },
          STATE_TRANSITIONS: {
            WITH_VALIDATION: 'State Transition (With Validation)',
            WITHOUT_VALIDATION: 'State Transition (Without Validation)',
          },
        },
      },
    },
  },
}
export const USER_MANAGEMENT = {
  LISTING: {
    TITLE: 'User Management',
    SEARCH_PLACE_HOLDER: 'Search by user name',
    CREATE_BUTTON: 'Add New User',
  },
  VIEW: {
    PAGE: {
      HEADER: 'View User',
    },
    SECTIONS: {
      PROFILE_INFORMATION: {
        SUB_HEADER: 'Profile Information',
        FIELD_NAMES: {
          USER_NAME: 'User Name',
          EMAIL_ID: 'Email ID',
          CYBERARK_ID: 'CyberArk ID',
          ROLE_NAME: 'Role Name',
          USER_CREATION_DATE: 'User Creation Date',
          LAST_UPDATED_ON: 'Last Updated On',
          USER_STATUS: 'User Status',
        },
        CTAS: {
          ACTIVE_INACTIVE: 'Active/Inactive',
          EDIT: 'Edit',
        },
      },
    },
  },
}
export const ITEM_LOGS = {
  PAGE: {
    HEADER: 'Item Logs View Page',
  },
  SECTIONS: {
    LOG_INFO: {
      SUB_HEADER: 'Log Info',
      FIELD_NAMES: {
        CONTENT_ID: 'Content ID',
        IP_ADDRESS: 'IP Address',
        EVENTS: 'Events',
        STATE: 'State',
        DATE_AND_TIME: 'Date and Time',
        VERSION_ID: 'Version ID',
        TYPE: 'Type',
        LAYOUT: 'Layout',
        ACTIVITY: 'Activity',
        USER_NAME: 'User Name',
        ROLE_NAME: 'Role Name',
        RESULT: 'Result',
      },
    },
    MESSAGE: {
      SUB_HEADER: 'Message',
      CONSTANT: 'Message',
    },
    MODIFIED_DATA: {
      SUB_HEADER: 'Modified Data',
    },
  },
}
export const PARAMETER_REPOSITORY = {
  PARAMETER_LISTING: {
    TITLE: 'Parameter Repository',
    SEARCH_PLACE_HOLDER: 'Search by parameter name or type',
    CREATE_BUTTON: 'Create Parameter',
  },
  VIEW: {
    PAGE: {
      HEADER: 'View Parameter',
    },
    SECTIONS: {
      PARAMETER_INFORMATION: {
        SUB_HEADER: 'Parameter Information',
        FIELD_NAMES: {
          PARAMETER_NAME: 'Parameter Name',
          FIELD_NAME: 'Field Name',
          FIELD_TYPE: 'Field Type',
          MASTER: 'Master',
          MULTISELECT: 'Multiselect',
          DEFAULT_VALUES: 'Default Values',
          ES_FIELD: 'ES Field',
          MANDATORY: 'Mandatory',
          PLACEHOLDER: 'Placeholder',
          PURPOSE: 'Purpose',
          DATA_TYPE: 'Data Type',
          ADD_MORE: 'Add More',
          STATUS: 'Status',
          PARAMETERS: 'Parameters',
        },
        CTAS: {
          ACTIVE: 'Active',
          INACTIVE: 'Inactive',
          DELETE: 'Delete',
          EDIT: 'Edit',
        },
      },
    },
  },
}
export const MASTER = {
  LISTING: {
    TITLE: 'Master Data Management',
    SEARCH_PLACE_HOLDER: 'Search by name or id',
    CREATE_BUTTON: 'Create Master Entry',
  },
}
export const ADVANCED_SEARCH = {
  HEADER: 'Advanced Search',
  SAVED_SEARCH_CTA: 'Saved Filters',
  ADVANCED_SEARCH_FORM: {
    HEADER: 'Parameter',
    ID: 'ID',
    TITLE: 'Title',
    ITEM: 'Item',
    TYPE: 'Type',
    LAYOUT: 'Layout',
    STATE: 'State',
    LAST_FEATURED_DATE: 'Last Featured Date',
  },
  SEARCH_CTA: 'Search',
  SAVED_SEARCH_LIST: {
    TITLE: 'Saved Search',
    DELETE_CTA: 'Remove',
    DELETE_MODAL: {
      TITLE: 'Remove Save & Pin Search',
      SUB_TITLE: 'Are you sure you want to remove',
      SUCCESS_CTA: 'Delete',
      CANCEL_CTA: 'Cancel',
    },
  },
  SAVED_SEARCH_MODAL: {
    TITLE: 'Save & Pin Search',
  },
  FIELDS: {
    NAME: {
      PLACEHOLDER: 'Name',
    },
  },
  ACTIONS: {
    PIN_TO_HOMEPAGE: 'Pin to Home Page',
  },
  BUTTONS: {
    CANCEL: 'Cancel',
    SUBMIT: 'Submit',
  },
}
export const SITE_NAVIGATION = {
  LISTING: {
    TITLE: 'Site Navigation',
    COLUMN_WIDGET_TYPE: 'Site Navigation Type',
    SEARCH_PLACE_HOLDER: 'Search by segment name, title, Id',
    CREATE_BUTTON: 'Create Widget',
    FILTERS: COMMON_LISTING_FILTERS,
  },
}
export const HOME_PAGE_TEXT = {
  DASHBOARD: {
    HEADER_TITLE: 'My Dashboard',
    GRAPH: {
      TITLE: 'Item States',
      STATE_SWITCH: {
        TITLE: 'Item State',
      },
    },
    LOGS: {
      TITLE: 'Recent Activity',
    },
  },
}

export const MODULE_CONSTANTS = {
  ASSET: 'asset',
  WIDGET: 'widget',
  CONTAINER: 'container',
  SITE_NAVIGATION: 'sitenavigation',
  ASSETS: 'assets',
  WIDGETS: 'widgets',
  CONTAINERS: 'containers',
  ITEMS: 'items',
  ASSET_TEXT: 'Asset',
  WIDGET_TEXT: 'Widget',
  CONTAINER_TEXT: 'Container',
  SITE_NAVIGATION_TEXT: 'Site Navigation',
  ITEM_TEXT: 'Item',
  ASSET_TEXT_WITH_S: 'Assets',
  WIDGET_TEXT_WITH_S: 'Widgets',
  CONTAINER_TEXT_WITH_S: 'Containers',
  ITEM_TEXT_WITH_S: 'Items',
  SEARCH_PLACEHOLDER: 'Search via ID, Title',
  SEARCH_LABEL: 'Search',
  WIDGET_TYPE: 'widgetType',
  ASSET_TITLE: 'assetTitle',
  ASSET_TYPE: 'assetType',
  SEARCH_TEXT: 'searchText',
  TYPE_FILTERS: 'typefilters',
  FILTER_BY_LAYOUT: 'Filter By Layout',
  TITLE: 'title',
  FOOTER: 'footer',
  FOOTER_TITLE: 'Footer',
  SUB_FOOTER: 'sub footer',
  SUB_FOOTER_TITLE: 'Sub Footer',
}
