import React, { useState, useEffect } from 'react'
import { ContentCategorySvg } from '../Icons/ContentCategorySVG'
import { MUISelect } from '../Shared'
import { ADVANCE_SEARCH } from '../../Constants/ApiDefinations'
import mainApiService from '../../Services'
import { useForm } from 'react-hook-form'
import MUIPieChart from '../../Components/Shared/MUI-PieChart'
import { getColorForState, STATE_ORDER } from '../../Helpers/Util'
import { useNavigate } from 'react-router-dom'
import { PAGES_ROUTES } from '../../Constants'
import { HOME_PAGE_TEXT } from '../../Constants/Texts'

const ItemStates = () => {
  const [itemVal, setItemVal] = useState<any[]>([])
  const [selectedItem, setSelectedItem] = useState<string | null>(null)
  const [chartData, setChartData] = useState<any[]>([])
  const [legendData, setLegendData] = useState<any[]>([])
  const navigate = useNavigate()

  const {
    formState: { errors },
    control,
  } = useForm<any>({
    values: {
      itemVal: selectedItem,
    },
    mode: 'onChange',
  })

  const fetchItemVal = async () => {
    const requestedData = ADVANCE_SEARCH.LIST_ITEMS()
    try {
      const result: any = await mainApiService(requestedData)
      setItemVal(result?.data || [])
      const defaultItem = result?.data?.find((item: any) => item.key.toLowerCase() === 'container')
      if (defaultItem) {
        setSelectedItem(defaultItem.key)
      }
    } catch (error) {
      console.error('Error fetching item types:', error)
    }
  }

  const fetchModuleCount = async () => {
    if (!selectedItem) return
    const requestedData = ADVANCE_SEARCH.MODULE_STATE_LIST(selectedItem)
    try {
      const result: any = await mainApiService(requestedData)
      const sortedData = result?.data?.sort((a: any, b: any) => {
        const indexA = STATE_ORDER.indexOf(a.stateName)
        const indexB = STATE_ORDER.indexOf(b.stateName)
        return indexA - indexB
      })
      const formattedData = sortedData?.map((item: any) => ({
        value: item.count,
        label: item.stateDisplayName,
        color: getColorForState(item.stateName),
        key: selectedItem,
        stateName: item.stateName,
        stateDisplayName: item.stateDisplayName,
      }))

      const nonZeroData = formattedData?.filter((item: any) => item.value > 0)
      setChartData(
        nonZeroData.length > 0 ? nonZeroData : [{ value: 0, label: 'No Data', color: 'grey' }],
      )
      setLegendData(formattedData)
    } catch (error) {
      console.error('Error fetching module count:', error)
    }
  }

  useEffect(() => {
    fetchItemVal()
  }, [])
  useEffect(() => {
    if (selectedItem) {
      fetchModuleCount()
    }
  }, [selectedItem])

  const handleSelectionChange = (e: any) => {
    const newValue = e.selectedOption?.key || null
    setSelectedItem(newValue)
  }

  const series = [
    {
      data: chartData.length > 0 ? chartData : [{ value: 1, label: 'No Data', color: 'grey' }],
      highlightScope: { faded: 'global' },
      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
    },
  ]

  const handlePieClick = (item: any) => {
    const state = chartData[item.dataIndex].stateName
    const routeMap: Record<string, string> = {
      container: PAGES_ROUTES.ManageContainer,
      widget: PAGES_ROUTES.ManageWidget,
      sitenavigation: PAGES_ROUTES.SiteNavigation,
    }

    if (selectedItem && routeMap[selectedItem]) {
      navigate(`/${routeMap[selectedItem]}?state=${encodeURIComponent(state)}`)
    }
  }

  return (
    <div className='content-category-wrapper'>
      <div className='d-flex justify-content-between position-relative'>
        <h3>
          <ContentCategorySvg />
          {HOME_PAGE_TEXT.DASHBOARD.GRAPH.TITLE}
        </h3>
        <div className='d-flex category-dropdown'>
          <label> {HOME_PAGE_TEXT.DASHBOARD.GRAPH.STATE_SWITCH.TITLE}:</label>
          <MUISelect
            name='itemVal'
            placeholder='select'
            labelKey='value'
            valueKey='key'
            value={itemVal.find((item) => item.key === selectedItem) || null}
            options={itemVal}
            control={control}
            error={errors}
            onChange={(e) => handleSelectionChange(e)}
            noClear
          />
        </div>
      </div>

      <MUIPieChart
        series={series}
        legendData={legendData.map(({ stateDisplayName, stateName }) => ({
          label: stateDisplayName,
          color: getColorForState(stateName),
        }))}
        testID='example-pie-chart'
        onClick={handlePieClick}
      />
    </div>
  )
}

export default ItemStates
