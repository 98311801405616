import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ListingComponent from '../../../Components/Molecule/ListingComponent'
import CONSTANTS, { PAGES_ROUTES } from '../../../Constants'
import {
  combineErrorMessage,
  getDateInFormat,
  handleResponse,
  objectToQueryString,
} from '../../../Helpers/Util'
import { MANAGE_TIPS, SYSTEM_CONFIG_APIS } from '../../../Constants/ApiDefinations'
import mainApiService from '../../../Services'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import updateTagMetaData from '../../../Helpers/UpdateTagMetaData'

const TipsManage: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [dataCount, setDataCount] = useState(0)
  const [filterOption, setFilterOption] = useState([])
  const [listData, setListData] = useState<any[]>([])
  const [isLoader, setIsLoader] = useState<boolean>(true)

  useEffect(() => {
    getMastersData()
  }, [])

  const getMastersData = async () => {
    const requestedData = SYSTEM_CONFIG_APIS.LIST_MASTERS('MODULE_TOOL_TIP')
    const result: any = await mainApiService(requestedData)
    if (result?.responseCode === 200) {
      result?.data.forEach((item: any) => delete item._id)
      setFilterOption(result?.data)
    }
  }

  const getTipsListFunction = async (options: any) => {
    const paramsData = objectToQueryString(options)
    setIsLoader(true)
    const requestedData = MANAGE_TIPS.TIPS_LIST(paramsData)
    const result: any = await mainApiService(requestedData)
    const { responseCode } = handleResponse(result)
    if (result?.responseCode === 200) {
      const tempData: any[] = []

      result?.data?.records?.forEach((ele: any) => {
        tempData.push({
          _id: ele?._id,
          module: ele?.module?.value,
          page: ele?.value,
          createdAt: getDateInFormat(ele?.createdAt ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
          updatedAt: getDateInFormat(ele?.updatedAt ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
        })
      })
      setDataCount(result?.data?.count)
      setListData(tempData)
      setIsLoader(false)
    } else if (responseCode === 404) {
      setDataCount(0)
      setListData([])
      setIsLoader(false)
    } else {
      dispatch(showAlert([true, combineErrorMessage(result), 'error']))
      setIsLoader(false)
    }
  }
  useEffect(() => {
    updateTagMetaData({ title: 'Manage Tips' })
    return () => {
      updateTagMetaData({})
    }
  }, [])

  return (
    <ListingComponent
      columns={CONSTANTS.MANAGE_TIPS}
      headerBtnLable='Create Tooltip'
      tableTitle='Manage Tips'
      tableRows={listData}
      dataCount={dataCount}
      isLoading={isLoader}
      searchPlaceholder='Search by page name'
      createButtonClick={() => {
        navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ManageTips}/create`)
      }}
      handleRowClick={(e) => {
        navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ManageTips}/view/${e._id}`)
      }}
      searchFunction={(search, sorttype, coltype, filters, pagenumber, pagecount) => {
        getTipsListFunction({
          sortBy: sorttype && coltype,
          searchText: search,
          sortType: sorttype,
          page: pagenumber,
          limit: pagecount,
          filterBy: filters.length > 0 && filters[0].filterBy,
          filterValue: filters.length > 0 && filters[0].filterValue[0],
        })
      }}
      showFilterOptions={true}
      filterFields={[
        {
          id: 'module',
          lable: 'Filter By Module',
          searchable: true,
          clearall: true,
          options: filterOption,
        },
      ]}
      paginationOption={[
        { value: 10, label: 10 },
        { value: 20, label: 20 },
        { value: 50, label: 50 },
      ]}
      noDataPlaceHolder='No tooltip Created'
      isFilterSingleSelect={true}
    />
  )
}

export default React.memo(TipsManage)
