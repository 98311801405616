import { MoreVertOutlined, Search, SortByAlpha } from '@mui/icons-material'
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import LastPageIcon from '@mui/icons-material/LastPage'
import React, { ChangeEvent, CSSProperties, memo, ReactNode, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  getDynamicRowValue,
  getListingRowData,
  getSessionStorage,
  removeLocalFilters,
  replaceUnwanted,
  setSessionStorage,
} from '../../../Helpers/Util'
import useDebounce from '../../../Hooks/useDebounce'
import { UserMuiTableModal } from '../../../Models/ComponentModel'
import { BackButton, MUIButton, MUIGrid, MUIPagination, SimpleDropdown } from '../../Shared'
import { DescendingIcon } from '../../Shared/MUI-Table/MUI-Table.svgicons'
import { NoAssetIcon } from '../../../Components/Icons'
import CloneModal from '../../Shared/CloneModal'
import ListingFilter from '../../Atoms/ListingFilter'
import CONSTANTS, { PAGES_ROUTES } from '../../../Constants'
import mainApiService from '../../../Services'
import {
  AUDIT_LOGS,
  CONTAINER_MANAGEMENT_CRUD,
  SITE_NAVIGATION_CRUD,
  WIDGET_MANAGEMENT_CRUD,
} from '../../../Constants/ApiDefinations'
import VersionIcon from '../../../Assets/Images/svgs/version-icon'
import ProgressBarIcon from '../../../Assets/Images/svgs/progress-bar-icon'
import { TooltipText } from '../../Shared/TooltipText/TooltipText'
import { EditIcon } from '../../Icons'
import SortIcon from '@mui/icons-material/Sort'
import {
  filterConditions,
  defaultFilterConditionValue,
} from '../../../Constants/Constant/ListingPageConstant/ContentListingConstants'
import MUIDateTimePicker from '../../MUIDateTimePicker'
import ProgressBar from '../../Shared/ProgressBar'
import CheckboxComponent from '../../Shared/Checkbox'

/**
 * ListingComponent is a reusable component that displays a list of items.
 *
 * @param {Object} props - The props object for the component.
 * @param {Object} props.cloneProps - Properties for cloning functionality.
 * @param {boolean} props.cloneProps.isClonable - Whether the items can be cloned (true/false).
 * @param {string} props.cloneProps.cloneSegmentIdNode - ID node for clone API payload (e.g., 'parameterId').
 * @param {string} props.cloneProps.apiPayloadIdNode - Exact ID node name required in payload as per Swagger (e.g., 'parameterId', 'segmentId').
 * @param {string} props.cloneProps.apiMidpoint - API endpoint midpoint for cloning as per Swagger(e.g., 'parameter').
 * @param {string} props.cloneProps.redirectViewApiMidpoint - API endpoint midpoint for viewing cloned items (e.g., 'parameter').
 * @param {string} props.cloneProps.titleNode - Node name for title in API response as per Swagger  API response(e.g., 'parameterName', 'widgetTitle').
 */

const ListingComponent: React.FC<UserMuiTableModal> = ({
  showTable = true,
  columns,
  tableRows = [],
  tableTitle,
  headerBtnLable = 'Add new',
  headerBtnVarient = 'contained',
  createButtonLable = 'Create',
  createButtonClick,
  searchFunction,
  handleRowClick,
  paginationOption = [],
  dataCount = 0,
  searchPlaceholder = 'Search',
  showCreateButton = true,
  showSearchField = true,
  isRowClickable = true,
  isCellClickable = true,
  showBackButton = false,
  emptyStateText,
  cloneToDisable = '',
  cloneProps = {
    isClonable: false,
    cloneSegmemt: '',
    apiMidPoint: '',
    redirectViewAPiRouteMidPoint: '',
    titleNodeToDisplay: '',
    title: '',
    openInNewTab: false,
  },
  handelBackButton,
  showHeaderInfo = [],
  searchLabel = '',
  showSearchIcon = true,
  searchFullWidth = false,
  showFilterOptions = false,
  filterFields = [],
  parentName = '',
  lastSearchFieldValue = '',
  setLastSearchFieldValue,
  searchClassName = '',
  searchBarSize = '',
  showVersionColumn = false,
  entityType = 'widget',
  resetPageNumber = false,
  initialCheckedState,
  noDataPlaceHolder = 'No Data Found',
  isFilterSingleSelect = false,
  showAdvanceFilter = false,
  isAssociatedList = false,
  clearFilterValues,
  filterValuesCleared,
  isLoading = false,
  isProgressBar = false,
  shouldSearchFunctionCall = false,
  selectAllProps = {},
  onSelectAllChange,
  isRetainFilters = false,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const localFilters = getSessionStorage('localFilters')

  const [pageData, setPageData] = useState({
    pageCount: 10,
    pageNumber: 1,
  })
  const [colData, setColData] = useState({
    selectedCol: '',
    selectedOptionType: 0,
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchInput, setSearchInput] = useState<string>('')
  const [selectedId, setSelectedId] = useState<number | string>('')
  const [selectedCol, setSelectedCol] = useState({
    selectedCol: '',
    selectedOptionType: 0,
  })
  const [selectedVersion, setSelectedVersion] = useState<any[]>([])
  const [selectedVersionId, setSelectedVersionId] = useState([])
  const [showVersions, setShowVersions] = useState(false)
  const [condition, setCondition] = useState(defaultFilterConditionValue)
  const [openFilter, setOpenFilter] = useState(false)
  const [filterInput, setFilterInput] = useState<string>('')
  const [selectedDateTime, setSelectedDateTime] = useState<any>()
  const [filters, setFilters] = useState<any[]>(localFilters && isRetainFilters ? localFilters : [])
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [selectedProgressBarId, setSelectedProgressBarId] = useState('')
  const [progressBarData, setProgressBarData] = useState<any>({})
  const [shouldListApiCall, setShouldListApiCall] = useState(true)
  const [headerColumns, setHeaderColumns] = useState<any[]>([])
  const [selectAllCheckboxData, setSelectAllCheckboxData] = useState<any>({})

  const searchValue = useDebounce(searchInput, 1000)

  useEffect(() => {
    if (lastSearchFieldValue) {
      setSearchInput(lastSearchFieldValue)
    }
  }, [])
  useEffect(() => {
    if (Object.keys(selectAllProps).length === 0) return
    setSelectAllCheckboxData(selectAllProps)
  }, [selectAllProps])
  useEffect(() => {
    const filteredColumns = columns?.filter((column) => column.label.trim() !== '') || []
    setHeaderColumns(selectAllCheckboxData?.showCheckbox ? filteredColumns : columns ?? [])
  }, [columns, selectAllCheckboxData?.showCheckbox])
  useEffect(() => {
    setShouldListApiCall(shouldSearchFunctionCall)
  }, [shouldSearchFunctionCall])

  useEffect(() => {
    setShowVersions(selectedVersion.length > 0)
  }, [selectedVersion])

  useEffect(() => {
    setShowProgressBar(Object.keys(progressBarData).length > 0)
  }, [progressBarData])

  useEffect(() => {
    if (resetPageNumber) {
      setPageData((pre) => ({ ...pre, pageNumber: 1 }))
    }
  }, [resetPageNumber])

  useEffect(() => {
    if (clearFilterValues) {
      setFilterInput('')
      setSearchInput('')
      isRetainFilters && setSessionStorage('localFilters', null, location.pathname)
      setFilters([])
      setSelectedCol({
        selectedCol: '',
        selectedOptionType: 0,
      })
      if (filterValuesCleared) filterValuesCleared(true)
    }
  }, [clearFilterValues])
  useEffect(() => {
    if (tableRows && tableRows?.length === 0) {
      pageData.pageNumber > 1
        ? setPageData((prev) => ({ ...prev, pageNumber: prev.pageNumber - 1 }))
        : setPageData((prev) => ({ ...prev, pageNumber: 1 }))
      if (!location?.pathname?.includes(sessionStorage.getItem('route') ?? '')) {
        removeLocalFilters()
        return
      }
    }
  }, [tableRows, location])

  useEffect(() => {
    setShouldListApiCall(true)
  }, [searchValue])
  useEffect(() => {
    if (!shouldListApiCall) return
    const column =
      selectedCol.selectedCol === 'stateDisplayName' ? 'state' : selectedCol.selectedCol
    const columnValue = showFilterOptions ? selectedCol.selectedCol : column
    const filter = showFilterOptions ? filters : { filterBY: '', filterText: '' }

    if (searchFunction)
      searchFunction(
        searchValue,
        selectedCol.selectedOptionType,
        columnValue,
        filter,
        pageData.pageNumber,
        pageData.pageCount,
      )
    setShouldListApiCall(false)
  }, [shouldListApiCall])

  const openFilterInSort = () => {
    setOpenFilter(true)
  }
  const closeFilterInSort = () => {
    setOpenFilter(false)
  }
  const handleConditionChange = (event: any) => {
    setCondition(event?.target.value)
  }
  const handleFilterInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterInput(event?.target.value)
  }
  const handleFilterDateChange = (date: any) => {
    setSelectedDateTime(date)
  }
  const getFilterOptions = (columnDef: any, metaAttribute: any) => {
    const filterValueType = metaAttribute.filter((col: { id: any }) => col.id === columnDef)[0]
      .dataType

    switch (filterValueType) {
      case 'number':
        return filterConditions[CONSTANTS.FIELDTYPES.NUMBER]

      case 'string':
        return filterConditions[CONSTANTS.FIELDTYPES.TEXT]

      case 'array':
        return filterConditions[CONSTANTS.FIELDTYPES.TEXT]

      case 'boolean':
        return filterConditions[CONSTANTS.FIELDTYPES.TEXT]

      case 'date':
        return filterConditions[CONSTANTS.FIELDTYPES.DATETIME]

      default:
        return []
    }
  }

  const handleSortIconClick = (event: React.MouseEvent<HTMLSpanElement>, colName: string) => {
    getFilterOptions(colName, columns)
    setPreviousSelectedFilterValue(colName, columns)
    event.preventDefault()
    if (selectedCol.selectedCol !== colName) {
      setColData((pre) => ({
        ...pre,
        selectedCol: colName,
        selectedOptionType: 0,
      }))
    } else {
      setColData(() => ({
        ...selectedCol,
        selectedCol: colName,
      }))
    }
    setAnchorEl(event.currentTarget as any)
  }

  const handleApply = (col: any) => {
    closeFilterInSort()
    onMenuClose(true)
    const obj = {
      filterBy: colData.selectedCol,
      filterCondition: condition,
      filterValue:
        col.dataType === 'date' ? new Date(selectedDateTime).toISOString() : filterInput.trim(),
    }
    handleFilterSubmit(obj, colData.selectedCol)
  }

  const setPreviousSelectedFilterValue = (col: any, cols: any) => {
    const previousFilters = filters.filter((filter) => filter.filterBy === col)
    const colDatatype = cols.filter((filter: any) => filter.id === col)[0]
    if (previousFilters.length) {
      setCondition(previousFilters[0].filterCondition)
      if (colDatatype.dataType === 'date') {
        setSelectedDateTime(new Date(previousFilters[0].filterValue))
      } else {
        setFilterInput(previousFilters[0].filterValue)
      }
    }
  }

  const onMenuClose = (clearCol = false) => {
    setAnchorEl(null)
    if (clearCol) {
      setColData({
        selectedCol: '',
        selectedOptionType: 0,
      })
    }
    setCondition(defaultFilterConditionValue)
    setFilterInput('')
    setSelectedDateTime('')
  }
  const tablePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    event.preventDefault()
    setPageData((pre) => ({ ...pre, pageNumber: page }))
    setShouldListApiCall(true)
  }

  const handlePerPageView = (e: any) => {
    e.preventDefault()
    setPageData((pre) => ({ ...pre, pageNumber: 1, pageCount: e.target.value }))
    setShouldListApiCall(true)
  }

  const handleRefresh = () => {
    setShouldListApiCall(true)
  }

  const handleFilterSubmit = (filter: any, id: string) => {
    const isFilterEmpty = Object.keys(filter).length === 0

    if (isFilterEmpty) {
      handleEmptyFilter(id)
    } else {
      handleNonEmptyFilter(filter, id)
    }
    if (pageData.pageNumber > 1) {
      setPageData((prev) => ({ ...prev, pageNumber: 1 }))
    }
    setShouldListApiCall(true)
  }

  const handleEmptyFilter = (id: string) => {
    if (filters.length > 0) {
      const updatedFilters = filters.filter((filter) => filter.filterBy !== id)
      setFilters(updatedFilters)
      isRetainFilters && setSessionStorage('localFilters', updatedFilters, location.pathname)
    } else {
      setFilters([])
      isRetainFilters && setSessionStorage('localFilters', null, location.pathname)
    }
  }

  const handleNonEmptyFilter = (filter: any, id: string) => {
    const filterExists = filters.some((item) => item.filterBy === id)

    if (filters.length === 0) {
      setFilters([filter])
      isRetainFilters && setSessionStorage('localFilters', [filter], location.pathname)
    } else if (filterExists) {
      const updatedFilters = filters.map((item) => (item.filterBy === id ? filter : item))
      setFilters(updatedFilters)
      isRetainFilters && setSessionStorage('localFilters', updatedFilters, location.pathname)
    } else {
      setFilters((prev) => [...prev, filter])
      isRetainFilters && setSessionStorage('localFilters', [...filters, filter], location.pathname)
    }
  }

  const clearFilter = (id: string) => {
    const updatedFilters = filters.filter((val) => val.filterBy !== id)
    setAnchorEl(null)
    setShouldListApiCall(true)
    setFilters(updatedFilters)
    isRetainFilters && setSessionStorage('localFilters', updatedFilters, location.pathname)
    setCondition(defaultFilterConditionValue)
    setFilterInput('')
    setSelectedDateTime('')
  }

  const checkIfFilterApplied = (id: string) => {
    return filters.filter((val) => val.filterBy === id).length > 0
  }

  const handleVersionIconClick = async (row: any) => {
    if (selectedVersionId === row._id) {
      setShowVersions(!showVersions)
      return
    }

    const selectedParameters = columns?.map((column: any) => column?.id)

    const compiledData = getPayloadForEntityType(entityType, row)
    const payload = {
      ...compiledData,
      selectedParameters: selectedParameters,
    }
    if (!payload) return

    const requestedData = getRequestedData(entityType, payload, row)
    if (!requestedData) return

    const result = await fetchVersionData(requestedData)
    if (result) {
      const transformedData = await processResultData(result?.data)
      setSelectedVersionId(row._id)
      setSelectedVersion(transformedData)
    }
  }

  const preparePayload = (row: any) => {
    const filters = [
      {
        filterBy: 'contentDocId',
        filterCondition: 'equals',
        filterValue: row.documentId,
      },
    ]

    return {
      filters,
    }
  }

  const getPayloadForEntityType = (entityType: string, row: any) => {
    switch (entityType) {
      case 'widget':
        return { widgetId: row.widgetId, _id: row._id }
      case 'container':
        return { containerId: row.containerId, _id: row._id }
      case 'siteNavigation':
        return { siteNavigationId: row.siteNavigationId, _id: row._id }
      case 'advanceSearch':
        return getAdvanceSearchPayload(row)
      default:
        return null
    }
  }

  const getAdvanceSearchPayload = (row: any) => {
    const { contentType, contentId, _id } = row
    if (contentType === 'container') return { containerId: contentId, _id }
    if (contentType === 'widget') return { widgetId: contentId, _id }
    if (contentType === 'site-navigation') return { siteNavigationId: contentId, _id }
    return null
  }

  const getRequestedData = (entityType: string, payload: any, row: any) => {
    switch (entityType) {
      case 'widget':
        return WIDGET_MANAGEMENT_CRUD.VERSION_LIST(payload)
      case 'container':
        return CONTAINER_MANAGEMENT_CRUD.VERSION_LIST(payload)
      case 'siteNavigation':
        return SITE_NAVIGATION_CRUD.VERSION_LIST(payload)
      case 'advanceSearch':
        return getAdvanceSearchRequestedData(row, payload)
      default:
        return null
    }
  }

  const getAdvanceSearchRequestedData = (row: any, payload: any) => {
    delete payload.selectedParameters
    if (row.contentType === 'container') return CONTAINER_MANAGEMENT_CRUD.VERSION_LIST(payload)
    if (row.contentType === 'widget') return WIDGET_MANAGEMENT_CRUD.VERSION_LIST(payload)
    if (row.contentType === 'site-navigation') return SITE_NAVIGATION_CRUD.VERSION_LIST(payload)
    return null
  }

  const fetchVersionData = async (requestedData: any) => {
    try {
      return await mainApiService(requestedData)
    } catch (error) {
      return null
    }
  }
  const getContentType = (item: any) => {
    if (item?.widgetId) return 'widget'
    if (item?.containerId) return 'container'
    return 'siteNavigation'
  }
  const processResultData = async (data: any[]) => {
    const tempData = await getListingRowData(data, columns, PAGES_ROUTES.ManageWidget, ['_id'])
    const displayData = tempData?.map((ele: any, index: any) => transformData(ele, data[index]))
    return displayData
  }

  const transformData = (data: any, initialData: any) => {
    return {
      ...data,
      contentId: initialData.widgetId || initialData.containerId || initialData.siteNavigationId,
      title:
        initialData.widgetTitle ||
        initialData.containerTitle ||
        initialData.siteNavigationTitle ||
        initialData.title,
      contentType: getContentType(initialData),
    }
  }

  const displayValue = (value: any) => {
    if (typeof value === 'string' || Array.isArray(value)) {
      return value ? <TooltipText text={value.toString()} length={40} /> : 'NA'
    }
    return value || 'NA'
  }

  const showChildVersions = (rowURL: any, ind: any) => {
    return selectedVersion.map((versionData: any, index: number) => {
      return (
        <TableRow
          component={rowURL ? Link : TableRow}
          to={rowURL ?? ''}
          key={ind}
          onMouseEnter={() => {
            setSelectedId(versionData._id)
          }}
          onMouseLeave={() => {
            setSelectedId('')
          }}
          onClick={(e: any) => {
            e.stopPropagation()
            if (handleRowClick) handleRowClick(versionData, true)
          }}
          data-testid='table-row-click'
          sx={{
            borderTop: index === 0 ? '12px solid #f5f4f4' : '',
            borderBottom: index === selectedVersion.length - 1 ? '12px solid #f5f4f4' : '',
          }}
        >
          <TableCell
            style={{ cursor: 'pointer' }}
            className={`dashLine-cell ${selectedId === versionData._id ? 'bg-col4' : ''}`}
            align='left'
          >
            <span
              className={`${cloneProps?.isClonable ? '' : 'dashLine-clone'} ${
                index === 0 ? 'listing-dashLine-first' : 'listing-dashLine'
              }`}
            ></span>
          </TableCell>
          {columns?.map((column, index) => {
            const value = versionData[column.id]
            return (
              <TableCell
                key={column.id}
                className={selectedId === versionData._id ? 'bg-col4' : ''}
              >
                <div
                  className={`${
                    isCellClickable ? 'cursor-pointer' : ''
                  } paragraph-regular-6 interFont`}
                  data-testid='tablerow'
                >
                  {displayValue(value)}
                </div>
              </TableCell>
            )
          })}
        </TableRow>
      )
    })
  }

  const handleProgressBar = async (row: any) => {
    if (selectedProgressBarId === row._id) {
      setShowProgressBar(!showProgressBar)
    } else {
      const payload = preparePayload(row)
      const requestedData = AUDIT_LOGS.CONTENT_STATUS(payload)
      const result = await mainApiService(requestedData)
      if (result?.responseCode === 200) {
        setProgressBarData(result?.data)
        setSelectedProgressBarId(row._id)
      } else {
        setProgressBarData({})
        setSelectedProgressBarId('')
      }
    }
  }

  const handleShowProgressBar = (ind: any) => {
    return (
      <TableRow
        key={ind}
        data-testid='table-row-click'
        sx={{
          borderTop: '12px solid #f5f4f4',
          borderBottom: '12px solid #f5f4f4',
        }}
      >
        <TableCell style={{ cursor: 'pointer' }} className={'dashLine-cell'} align='left'>
          <span className={'dashLine-clone-progressBar listing-dashLine-first progress-bar'}></span>
        </TableCell>
        <TableCell colSpan={columns?.length ?? 0 - 1} style={{ background: '#f5f4f4' }}>
          <ProgressBar progressBarData={progressBarData} />
        </TableCell>
      </TableRow>
    )
  }

  const filterContainer = (justifyContent: string, padding: string) => {
    if (!showFilterOptions) return null

    return (
      <Box sx={{ display: 'flex', justifyContent, gap: 3, padding }}>
        {filterFields.map((item) => (
          <ListingFilter
            filters={filters.find((it: any) => it.filterBy === item.id)?.filterValue || []}
            key={item.id}
            searchable={item.searchable}
            clearAll={item.clearall}
            lable={item.lable}
            id={item.id}
            options={item.options ?? []}
            searchFunction={handleFilterSubmit}
            isFilterSingleSelect={isFilterSingleSelect}
            clearFilter={clearFilterValues}
          />
        ))}
      </Box>
    )
  }
  const searchField = ({
    className = false,
    fullWidth = false,
    backgroundColor = 'transparent',
  }) => {
    if (!showSearchField) return null

    const searchBarSizeValue = searchBarSize === 'large' ? 'large' : ''
    const classNameValue = className ? `c-input listing-search ${searchBarSizeValue}` : ''
    return (
      <TextField
        className={classNameValue}
        style={{ backgroundColor }}
        fullWidth={fullWidth}
        id='outlined-search'
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.value?.trim()?.length === 0 && setLastSearchFieldValue && className) {
            setLastSearchFieldValue('')
          }
          setSearchInput(e.target.value)
        }}
        placeholder={searchPlaceholder}
        value={searchInput}
        label={searchLabel}
        inputProps={{ 'data-testid': replaceUnwanted(searchPlaceholder) }}
        autoComplete='off'
        InputProps={{
          id: 'searchId',
          startAdornment: (
            <>
              {showSearchIcon && (
                <InputAdornment position='start'>
                  <Search style={{ color: '#B1B4B6' }} />
                </InputAdornment>
              )}
            </>
          ),
        }}
        type='search'
      />
    )
  }
  const noDataTypography = (typographyStyle: CSSProperties, noDataText: string) => {
    return (
      <Typography
        className='d-flex justify-content-center'
        style={typographyStyle}
        data-testid={`div_${replaceUnwanted(tableTitle)}_no_data_found`}
      >
        {noDataText}
      </Typography>
    )
  }
  const noDataContainer = (typographyStyle: CSSProperties, noDataText: string) => {
    return (
      <div
        className={'d-flex justify-content-center align-items-center flex-column'}
        style={{ paddingTop: '7rem', height: '100%', paddingBottom: '5rem' }}
      >
        <div>
          <NoAssetIcon />
        </div>
        {noDataTypography(typographyStyle, noDataText)}
      </div>
    )
  }
  const displayNoDataContainer = () => {
    if (parentName?.length === 0)
      return !isLoading && noDataTypography({ padding: '50px 50px' }, noDataPlaceHolder)

    if (searchValue?.trim()?.length > 0)
      return noDataContainer(
        { padding: '0 50px', fontWeight: 600 },
        `No ${parentName || 'Data'} Found`,
      )

    return noDataContainer(
      { padding: '0 50px', fontWeight: 'bold' },
      emptyStateText ?? 'Please initiate search to add.',
    )
  }
  const displaySelectAllCheckbox = () => {
    if (!onSelectAllChange) return null
    const { checked } = selectAllCheckboxData
    return (
      <CheckboxComponent
        checkboxId='selectAll'
        onChange={() => onSelectAllChange(!checked)}
        checked={checked}
        checkboxStyle={{ paddingLeft: 0 }}
      />
    )
  }
  const displayTableHeaderFirstColumn = () => {
    if (
      showVersionColumn ||
      isProgressBar ||
      cloneProps?.isClonable ||
      selectAllCheckboxData?.showCheckbox
    ) {
      if (selectAllCheckboxData?.showCheckbox)
        return <TableCell>{displaySelectAllCheckbox()}</TableCell>
      return <TableCell>{''}</TableCell>
    }
    return null
  }

  return (
    <MUIGrid
      className='interFont listing-container'
      style={{
        width: '100%',
        backgroundColor: '#f5f4f4',
        padding: !isAssociatedList ? '20px 24px' : '0 0 20px 0',
      }}
    >
      <Grid className='interFont c-table'>
        {!isAssociatedList ? (
          <>
            <MUIGrid
              container
              className={`d-flex justify-content-between align-items-center ${
                searchFullWidth ? 'search-full-width' : ''
              }`}
            >
              <MUIGrid item md={6} lg={6}>
                {showBackButton && (
                  <BackButton
                    label={`${tableTitle}`}
                    className='col73'
                    handleOnClick={handelBackButton}
                  />
                )}
                {!showBackButton && tableTitle && <p className='listing__title'>{tableTitle}</p>}
              </MUIGrid>

              <MUIGrid
                item
                md={searchFullWidth ? 12 : 6}
                lg={searchFullWidth ? 12 : 6}
                className={searchClassName}
              >
                <MUIGrid container className='d-flex justify-content-end align-items-center'>
                  <MUIGrid direction='row' className='search-container' gap={3} display='flex'>
                    {searchField({ className: true })}
                    {showCreateButton && showTable && (
                      <MUIButton
                        id={replaceUnwanted(
                          headerBtnLable === 'Add new' ? createButtonLable : headerBtnLable,
                        )}
                        className='create__btn'
                        variant={headerBtnVarient}
                        label={headerBtnLable === 'Add new' ? createButtonLable : headerBtnLable}
                        type='button'
                        data-testid='create-btn-click'
                        handleClickCB={createButtonClick}
                      />
                    )}
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
            {showHeaderInfo && (
              <MUIGrid container className='d-flex justify-content-between align-items-center'>
                {showHeaderInfo}
              </MUIGrid>
            )}
            {filterContainer('end', '20px')}
          </>
        ) : (
          <div
            style={{
              height: '11rem',
              backgroundColor: '#F7F8F8',
            }}
          >
            {filterContainer('start', '10px 24px 0 24px')}
            <div className='associatedListSearch'>
              {searchField({ fullWidth: true, backgroundColor: 'white' })}
            </div>
          </div>
        )}

        {showTable && (
          <>
            <TableContainer
              data-testid='listing__table'
              className={`border-6 shadow-table ${!isAssociatedList ? 'mt-2' : 'mt-1'}`}
              style={{ backgroundColor: '#ffff' }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {displayTableHeaderFirstColumn()}
                    {headerColumns?.map((column: any) => {
                      return (
                        <TableCell key={column.id} align='center'>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              alignContent: 'space-between',
                              fontSize: '12px',
                              fontWeight: 'bold',
                            }}
                          >
                            <div className='interFont'>{column.label}</div>
                            {(column.sort || column.showFilter) && (
                              <div
                                data-testid='openfilter'
                                id='openFilter'
                                className='m-4 d-flex align-items-center cursor-pointer'
                                onClick={(event) => {
                                  handleSortIconClick(event, column.id)
                                }}
                              >
                                {checkIfFilterApplied(column.id) && column.showFilter ? (
                                  <SortIcon
                                    style={{
                                      fontSize: '18px',
                                      marginLeft: '7px',
                                    }}
                                  />
                                ) : (
                                  <MoreVertOutlined
                                    style={{
                                      fontSize: '18px',
                                      fill:
                                        column.id === selectedCol.selectedCol
                                          ? '#402080'
                                          : '#b1b4b6',
                                    }}
                                  />
                                )}
                              </div>
                            )}
                            {column.id === colData.selectedCol && (
                              <Menu
                                className='border-12 c-sort'
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => onMenuClose(true)}
                                data-testid='sort-dialog'
                              >
                                <div className='d-flex justify-content-between align-items-center'>
                                  <Typography>Sort By</Typography>
                                  {showAdvanceFilter && column.showFilter && (
                                    <span
                                      className={
                                        checkIfFilterApplied(colData.selectedCol)
                                          ? 'clear-all'
                                          : 'clear-all-disabled'
                                      }
                                      data-testid='clear-all-button'
                                      onClick={() => {
                                        clearFilter(colData.selectedCol)
                                        if (pageData.pageNumber > 1)
                                          setPageData((prev) => ({ ...prev, pageNumber: 1 }))
                                      }}
                                    >
                                      Clear Filter
                                    </span>
                                  )}
                                </div>
                                {column.sort && (
                                  <RadioGroup
                                    aria-label='options'
                                    name='options'
                                    value={colData.selectedOptionType}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      setColData({
                                        ...colData,
                                        selectedOptionType: parseInt(event.target.value),
                                      })
                                      setAnchorEl(null)
                                      setShouldListApiCall(true)
                                      onMenuClose()
                                      setSelectedCol({
                                        ...colData,
                                        selectedOptionType: parseInt(event.target.value),
                                      })
                                      if (pageData.pageNumber > 1)
                                        setPageData((prev) => ({ ...prev, pageNumber: 1 }))
                                    }}
                                  >
                                    <MenuItem
                                      sx={{
                                        paddingLeft: 0,
                                      }}
                                      className='d-flex justify-content-between align-items-center'
                                    >
                                      <FormControlLabel
                                        labelPlacement='start'
                                        value={1}
                                        label={
                                          <div className='d-flex w-100 align-item-center justify-content-center'>
                                            <div className='d-flex align-items-center'>
                                              <SortByAlpha style={{ fontSize: '18px' }} />
                                            </div>
                                            <div className='ml-8p'>
                                              <span className='font-12p'>Ascending</span>
                                            </div>
                                          </div>
                                        }
                                        control={<Radio className='ml-auto' />}
                                      />
                                    </MenuItem>
                                    <MenuItem
                                      sx={{
                                        paddingLeft: 0,
                                      }}
                                      className='d-flex justify-content-between align-items-center'
                                    >
                                      <FormControlLabel
                                        labelPlacement='start'
                                        value={-1}
                                        label={
                                          <div className='d-flex w-100 align-item-center justify-content-center'>
                                            <div className='d-flex align-items-center'>
                                              <DescendingIcon />
                                            </div>
                                            <div className='ml-8p'>
                                              <span className='font-12p'>Descending</span>
                                            </div>
                                          </div>
                                        }
                                        control={<Radio />}
                                      />
                                    </MenuItem>
                                  </RadioGroup>
                                )}
                                {showAdvanceFilter && column.showFilter && (
                                  <div>
                                    <MUIButton
                                      width={150}
                                      fontSize={14}
                                      color='secondary'
                                      height='40px'
                                      type='button'
                                      data-testid='filter-button'
                                      label={'Filter'}
                                      variant='text'
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}
                                      startIcon={<FilterListIcon sx={{ marginRight: '8px' }} />}
                                      endIcon={
                                        <LastPageIcon sx={{ fontSize: '20px !important' }} />
                                      }
                                      handleClickCB={openFilterInSort}
                                    />
                                    {openFilter && (
                                      <Menu
                                        className='border-12 c-sort'
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={closeFilterInSort}
                                        data-testid='filter-value'
                                        sx={{ top: column.sort ? '180px' : '90px' }}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'right',
                                        }}
                                        PaperProps={{
                                          style: {
                                            width:
                                              column && column.dataType.toLowerCase() === 'date'
                                                ? '310px'
                                                : '245px',
                                            maxWidth: '400px',
                                            overflow: 'auto',
                                          },
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            fontWeight: 'bold',
                                            marginBottom: '18px',
                                          }}
                                        >
                                          Filter
                                        </Box>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            height: '170px',
                                          }}
                                        >
                                          <FormControl fullWidth>
                                            <InputLabel id='demo-simple-select-label'>
                                              Condition
                                            </InputLabel>
                                            <Select
                                              labelId='demo-simple-select-helper-label'
                                              id='demo-simple-select-helper'
                                              data-testid='condition-filter'
                                              value={condition}
                                              label='Condition'
                                              onChange={handleConditionChange}
                                            >
                                              {getFilterOptions(column.id, columns).map(
                                                (item, index) => (
                                                  <MenuItem key={item.label} value={item.value}>
                                                    {item.label}
                                                  </MenuItem>
                                                ),
                                              )}
                                            </Select>
                                          </FormControl>
                                          {column && column.dataType.toLowerCase() === 'date' ? (
                                            <div data-testid='date-input'>
                                              <MUIDateTimePicker
                                                name={'StartDate'}
                                                label={'Value'}
                                                type={'datetime'}
                                                onChange={handleFilterDateChange}
                                                value={selectedDateTime}
                                                views={CONSTANTS.DATE_TIME_FORMAT_VIEWS}
                                                error={{}}
                                              />
                                            </div>
                                          ) : (
                                            <TextField
                                              id='outlined-basic'
                                              label='Value'
                                              variant='outlined'
                                              value={filterInput}
                                              onChange={handleFilterInputChange}
                                              onKeyDown={(e: any) => {
                                                e.stopPropagation()
                                              }}
                                              inputProps={{ 'data-testid': 'filter-input' }}
                                            />
                                          )}
                                          <MUIButton
                                            width={150}
                                            fontSize={14}
                                            height='44px'
                                            type='button'
                                            label={'Apply'}
                                            variant='contained'
                                            handleClickCB={() => handleApply(column)}
                                            disabled={!(filterInput || selectedDateTime)}
                                          />
                                        </Box>
                                      </Menu>
                                    )}
                                  </div>
                                )}
                              </Menu>
                            )}
                          </div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading &&
                    tableRows?.length > 0 &&
                    tableRows?.map((row, ind) => {
                      // for masters URL is already available in CONSTANTS
                      const url: string = row?.url ?? ''
                      const id = getDynamicRowValue(row, 'id')
                      const disableRadioCheckBox = row?.checkbox?.props?.className?.includes(
                        'replace-asset-radio disabled-row',
                      )
                      const rowToDisable =
                        row?.checkbox?.props?.disabled ||
                        row?.checkbox?.props?.children?.[0]?.props?.disabled ||
                        disableRadioCheckBox
                      // for everything else passing from the parent component
                      const rowURL: string = row?.rowURL ?? url ?? ''
                      const isCloneDisabled = row[cloneToDisable] === 'NA'
                      return (
                        <>
                          <TableRow
                            component={rowURL ? Link : TableRow}
                            to={rowURL ?? ''}
                            key={row.id}
                            className={`table-link ${isRowClickable ? 'cursor - pointer' : ''} ${
                              rowToDisable ? 'disabled-row' : ''
                            } ${initialCheckedState?.[id] ? 'selected-row' : ''}`}
                            onMouseEnter={() => {
                              setSelectedId(ind)
                            }}
                            onMouseLeave={() => {
                              setSelectedId('')
                            }}
                            onClick={(e: any) => {
                              e.stopPropagation()
                              if (handleRowClick) handleRowClick(row)
                            }}
                            data-testid='table-row-click'
                          >
                            {showVersionColumn || cloneProps?.isClonable ? (
                              <TableCell
                                style={{ cursor: 'pointer' }}
                                className={selectedId === ind ? 'bg-col4' : ''}
                                sx={{ paddingRight: '22px !important' }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div
                                  className={`version-clone-icons ${
                                    !showVersionColumn ? 'flex-start' : ''
                                  }`}
                                  onClick={(e) => e.preventDefault()}
                                >
                                  {showVersionColumn && row?.versionCount > 1 && (
                                    <div
                                      className='version-icon'
                                      data-testid='cloneBtn'
                                      onClick={(e) => {
                                        e.preventDefault()
                                        handleVersionIconClick(row)
                                      }}
                                    >
                                      <VersionIcon />
                                      <span className='version-count'>{row?.versionCount}</span>
                                    </div>
                                  )}

                                  {entityType === 'container' &&
                                  row?.isChildPageRequired?.toLowerCase() === 'yes' ? (
                                    <div
                                      className='listing-edit-icon'
                                      data-testid='container-child-edit-btn'
                                      onClick={() => {
                                        navigate(
                                          `/${PAGES_ROUTES.ManageContainer}/${PAGES_ROUTES.PageTypeView}/${row._id}`,
                                        )
                                      }}
                                    >
                                      <EditIcon />
                                    </div>
                                  ) : entityType === 'container' ? (
                                    row?.isCloneRequired?.toLowerCase() === 'yes' &&
                                    cloneProps?.isClonable ? (
                                      <div className='clone-icons'>
                                        <CloneModal
                                          cloneProps={{
                                            redirectViewAPiRouteMidPoint:
                                              cloneProps.redirectViewAPiRouteMidPoint,
                                            cloneSegmemtIdNodeForApiPayload:
                                              cloneProps.cloneSegmemtIdNodeForApiPayload,
                                            apiMidPoint: cloneProps.apiMidPoint,
                                            titleNodeToDisplay: cloneProps.titleNodeToDisplay,
                                            title: cloneProps.title,
                                            isCloneDisabled: isCloneDisabled,
                                            openInNewTab: cloneProps.openInNewTab,
                                          }}
                                          id={row._id}
                                          onCancel={handleRefresh}
                                        />
                                      </div>
                                    ) : (
                                      <div style={{ width: '28px' }}></div>
                                    )
                                  ) : (
                                    cloneProps?.isClonable && (
                                      <div className='clone-icons'>
                                        <CloneModal
                                          cloneProps={{
                                            redirectViewAPiRouteMidPoint:
                                              cloneProps.redirectViewAPiRouteMidPoint,
                                            cloneSegmemtIdNodeForApiPayload:
                                              cloneProps.cloneSegmemtIdNodeForApiPayload,
                                            apiMidPoint: cloneProps.apiMidPoint,
                                            titleNodeToDisplay: cloneProps.titleNodeToDisplay,
                                            title: cloneProps.title,
                                            isCloneDisabled: isCloneDisabled,
                                            openInNewTab: cloneProps.openInNewTab,
                                          }}
                                          id={row._id}
                                          onCancel={handleRefresh}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </TableCell>
                            ) : null}

                            {isProgressBar ? (
                              <TableCell
                                style={{ cursor: 'pointer' }}
                                className={selectedId === ind ? 'bg-col4' : ''}
                                sx={{ paddingRight: '22px !important' }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div
                                  className={`version-clone-icons ${
                                    !isProgressBar ? 'flex-start' : ''
                                  }`}
                                >
                                  <div
                                    className='version-icon'
                                    data-testid='progress-bar-icon'
                                    onClick={(e) => {
                                      e.preventDefault()
                                      handleProgressBar(row)
                                    }}
                                  >
                                    <ProgressBarIcon />
                                  </div>
                                </div>
                              </TableCell>
                            ) : null}

                            {columns?.map((column) => {
                              const value: string | number | boolean | ReactNode = row[column.id]
                              return (
                                <TableCell
                                  key={column.id}
                                  className={selectedId === ind ? 'bg-col4' : ''}
                                >
                                  <div
                                    className={`${
                                      isCellClickable ? 'cursor-pointer' : ''
                                    } paragraph-regular-6 interFont`}
                                    data-testid='tablerow'
                                  >
                                    {displayValue(value)}
                                  </div>
                                </TableCell>
                              )
                            })}
                          </TableRow>

                          {showVersions &&
                            selectedVersionId === row._id &&
                            showChildVersions(url, ind)}

                          {showProgressBar &&
                            selectedProgressBarId === row._id &&
                            handleShowProgressBar(ind)}
                        </>
                      )
                    })}
                </TableBody>
              </Table>

              {isLoading && (
                <div className='d-flex justify-content-center' style={{ padding: '100px 0' }}>
                  <CircularProgress variant='indeterminate' />
                </div>
              )}
              {tableRows.length === 0 && displayNoDataContainer()}
            </TableContainer>
            <MUIGrid
              container
              className='d-flex align-items-center justify-content-between mt-2 c-pagination'
            >
              {dataCount > 0 && (
                <MUIGrid item direction='row' className='d-flex align-items-center col1'>
                  <SimpleDropdown
                    selectId='pageId'
                    options={paginationOption}
                    handleChange={handlePerPageView}
                    value={pageData.pageCount}
                  />

                  <Typography paddingX={3}>
                    Showing {pageData.pageCount > dataCount ? dataCount : tableRows.length} of{' '}
                    {dataCount} records
                  </Typography>
                </MUIGrid>
              )}
              <MUIGrid item>
                {tableRows.length > 0 && dataCount > 0 && (
                  <MUIPagination
                    testId='userPagination'
                    onChange={tablePaginationChange}
                    count={Math.ceil(dataCount / Number(pageData.pageCount))}
                    page={pageData.pageNumber}
                  />
                )}
              </MUIGrid>
            </MUIGrid>
          </>
        )}
      </Grid>
    </MUIGrid>
  )
}

export default memo(ListingComponent)
