export const PAGES_ROUTES = {
  ErrorPage: '/error',
  LoginPage: '/login',
  AccessDenied: '/access-denied',
  ComingSoon: '/coming-soon',
  NotFound: '/not-found',
  InternalServerError: '/internal-server-error',
  AuthValidatePage: '/api/v1/sso/login',
  RootPage: '/',
  Asset: '/asset',
  Associated: ':associated',
  AssociatedAssets: 'associated-assets',
  Contentid: ':contentid',
  HomePage: '/home',
  ManageContent: 'manage-content',
  contentCategory: ':contentCategory',
  Originals: 'manage-content/originals',
  Movies: 'manage-content/movies',
  Sports: 'manage-content/sports',
  ContentCategory: ':contentCategory',
  IndependentVODAssest: 'manage-content/independent_vod_assets',
  NonVODAssest: 'manage-content/non_vod_assets',
  RolesPage: 'manage-role',
  CreateRolePage: 'create',
  ViewEditRolePage: ':rolePageType',
  ViewTypeRolePage: 'view',
  EditTypeRolePage: 'edit',
  EditRoleId: ':roleId',
  UsersPage: 'userManagement',
  BoltMaster: 'bolt-master',
  AssetsDefination: 'assets-defination',
  AttributeRepository: 'attribute-repo',
  ViewEditAttributePage: ':attributePageType',
  editAttributeId: ':attributeId',
  Reports: 'reports',
  PageTypeCreate: 'create',
  PageTypeView: 'view',
  PageTypeEdit: 'edit',
  NotFoundPage: '*',
  ManageUser: 'manage-user',
  id: ':id',
  userId: ':userId',
  GeoPolicy: 'geo-policy',
  geoPolicyPageType: ':geoPolicyPageType',
  geoPolicyId: ':geoPolicyId',
  assetId: ':assetId',
  Masters: 'masters',
  ManageTips: 'manage-tips',
  TipsId: ':tipsId',
  AffiliatePartners: 'affiliate-partners',
  ViewEditAffiliatePartnersPage: ':affiliatePartnersPageType',
  AffiliatePartnersId: ':affiliatePartnersId',
  CreateAffiliatePartnersPage: 'create',
  Languages: 'languages',
  ViewEditLanguagesPage: ':languagesPageType',
  LanguagesId: ':languagesId',
  CreateLanguagesPage: 'create',
  EntitlementPackages: 'entitlement-packages',
  ViewEditEntitlementPackagesPage: ':entitlementPackagesPageType',
  EntitlementPackagesId: ':entitlementPackagesId',
  CreateEntitlementPackagesPage: 'create',
  DownstreamPlatforms: 'downstream-platforms',
  ViewEditDownstreamPlatformsPage: ':downstreamPlatformsPageType',
  DownstreamPlatformsId: ':downstreamPlatformsId',
  CreateDownstreamPlatformsPage: 'create',
  Countries: 'countries',
  ViewEditCountriesPage: ':countriesPageType',
  CountriesId: ':countriesId',
  CreateCountriesPage: 'create',
  SystemConfiguration: 'systemConfiguration',
  PageType: ':pageType',
  SystemConfigurationPageType: ':systemConfigurationPageType',
  SystemConfigurationId: ':systemConfigurationId',
  ComparisonReports: 'reports/ComparisonReports',
  AuditLogs: 'audit-logs',
  UsersRolesLogs: 'audit-logs/user-and-role-logs',
  MasterLogs: 'audit-logs/master-logs',
  ContentLogs: 'audit-logs/content-logs',
  TranscodingLogs: 'audit-logs/transcoding-logs',
  UsersRolesLogsDetailPage: 'view',
  UsersRolesLogsDetailPageId: ':id',
  MasterLogsDetailPage: 'view',
  MasterLogsDetailPageId: ':id',
  TranscodingLogsDetailPage: 'view',
  TranscodingLogsDetailPageId: ':id',
  ContentLogsDetailPage: 'view',
  ContentLogsDetailPageId: ':id',
  DataMigrationReports: 'DataMigrationReports',
  AuditTrails: 'audit-trails',
  AuditTrailsDetailPage: 'view',
  AuditTrailsDetailPageId: ':id',
  AdvancedSearch: 'advanced-search',
  SavedSearch: 'saved-search',
  ParameterRepository: 'parameter-repository',
  parameterId: ':id',
  ParameterCreate: 'create',
  ViewTypeParameter: 'view',
  EditParameterRepository: 'edit',
  ViewEditParameterPage: ':ParameterPageType',
  ManageSegment: 'manage-segment',
  segmentId: ':segmentId',
  widgetId: ':widgetId',
  Update: 'update',
  View: 'view',
  Create: 'create',

  BoltMasterNew: 'bolt-master',
  ContainerDefinitionList: 'container-definition',
  ContainerDefinitionId: ':containerDefinitionId',

  WidgetDefinitionList: 'widget-definition',
  WidgetDefinitionId: ':widgetDefinitionId',
  ManageWidget: 'manage-widget',
  ManageWidgetCreate: 'create',
  ManageWidgetView: 'view',
  EditWidgetID: ':editWidgetId',
  ManageContainer: 'manage-container',
  containerId: ':containerId',
  SiteNavigation: 'site-navigation',
  siteNavigationId: ':siteNavigationId',
  globalSearch: 'global-search',
  ViewAuditLog: 'audit-logs/item-logs/view/:id',
  ItemAuditLog: 'audit-logs/item-logs',
  ItemAuditLogListing: 'audit-logs/item-logs/:prevPage/:contentId/:id',
  ItemLogs: '/audit-logs/item-logs',
  ChildContainer: 'child-container',
  AssociatedItem: '/associated-items',
  Module: ':module',
  ContentStatus: '/audit-logs/item-status',
  ListingScreenPreferance: 'listing-screen-preference',
}
export const PAGE_NAME = {
  HomeLabel: 'Home',
  ManageContentLabel: 'Manage Content',
  TVShows: 'TV Shows',
  Originals: 'Originals',
  Movies: 'Movies',
  Sports: 'Sports',
  Shorts: 'Shorts',
  IndependentVODAssest: 'Independent VOD Assest',
  NonVODAssest: 'Non VOD Assest',
  AssociatedAssests: 'Associated Assets',
  AuditTrailsLabel: 'Audit Trails',
  AuditTrailsViewLabel: 'Audit Trails View',

  RoleManagementLabel: 'Role Management',
  UserManagementLabel: 'User Management',

  MetaMasterLabel: 'Meta Master',
  BoltMasterLabel: 'Bolt Master',
  AttributeRepoLabel: 'Attribute Repository',
  AssetDefinitionLabel: 'Asset Definition',
  GeoPolicyManagementLabel: 'Geo Policy Management',
  MastersLabel: 'Masters',
  ParameterLabel: 'Parameter Repository',
  ManageTips: 'Manage Tips',

  AuditLogsLabel: 'Audit Logs',
  ContentLogsLabel: 'Content Logs',
  UserAndRoleLogsLabel: 'User And Role Logs',
  MastersLogsLabel: 'Masters Logs',
  TranscodingLogsLabel: 'Transcoding Logs',

  ReportsLabel: 'Reports',

  AffiliatePartnersLabel: 'Affiliate Partner',
  LanguagesLabel: 'Language',
  EntitlementPackagesLabel: 'Entitlement Packages',
  DataMigrationReportsLabel: 'Data Migration Reports',
  DownstreamPlatformsLabel: 'Downstream Platform',
  CountriesLabel: 'Country',
  ComparisonReportsLabel: 'Comparison Reports',

  SegmentManagementLabel: 'Manage Segments',

  ContainerDefinitionLabel: 'Container Definition',
  WidgetDefinitionLabel: 'Widget Definition',
  WidgetManagenmentLabel: 'Manage Widgets',
  ContainerManagementLabel: 'Manage Containers',
  SiteNavigationLabel: 'Site Navigation',
  GlobalSearchLabel: 'Global Search',
  ItemLogsLabel: 'Item Logs',
  ContentStatus: 'Item Status',
  ListingScreenPreferance: 'Listing Screen Preference',
}
