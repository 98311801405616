import { format, getTime } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { jwtDecode } from 'jwt-decode'
import DOMPurify from 'dompurify'
import CONSTANTS, { PAGES_ROUTES } from '../Constants'
import { AUTH_MANAGEMENT, WIDGET_MANAGEMENT_CRUD } from '../Constants/ApiDefinations'
import { MASTER_MANAGEMENT_CONSTANTS } from '../Constants/Constant/Masters'
import { TypeObject, roleModulesModal, selectAllProps } from '../Models'
import mainApiService from '../Services'
import { AppDispatch, store } from '../Store'
import { showAlert } from '../Store/Slice/alert.slice'
// import { generatePresignedUrlForSubtitle } from '../Store/Slice/orchestration.slice'
import { CATEGORY_LABEL, DATA_ENGINES, FORM_FIELD } from '../Constants/ManageWidget'
import {
  getAutomatedMastersData,
  getMastersData,
  getParameterRepositoryDetail,
} from '../Store/Slice/data-engine-category.slice'
import { setAuthConfig } from '../Store/Slice/user.slice'
import { getLocalStorage, setLocalStorage } from './Storage'
import { FieldFunctions } from './constant'
import { Dispatch } from '@reduxjs/toolkit'
import { setHookFormErrors } from '../Store/Slice/masters.slice'
import { setLoader } from '../Store/Slice/loader.slice'
import {
  counterType,
  DataObject,
  IKeyValue,
  ResultObject,
  WidgetFilterType,
} from '../Models/CommonListHeadingModal'
import { getMasterByKey } from '../Store/Slice/system-configuration.slice'
import { MODULE_CONSTANTS } from '../Constants/Texts'
import React from 'react'
import AssetStateLabel from '../Components/Labels/AssetStateLabel'
import { initialState as AssetFieldsIntialState } from '../Components/Shared/DefinitionFields/AssetFields'

export const getStatus = (status: any) => {
  const statusMap: Record<string, string> = {
    [CONSTANTS.ASSET_STATUS.CREATED]: 'Draft-Created',
    [CONSTANTS.ASSET_STATUS.DRAFT]: 'Draft',
    [CONSTANTS.ASSET_STATUS.DRAFT_CREATE]: 'Draft Create',
    [CONSTANTS.ASSET_STATUS.DRAFT_COMPLETE]: 'Draft Complete',
    [CONSTANTS.ASSET_STATUS.DRAFT_UNDER_QC]: 'Draft Under QC',
    [CONSTANTS.ASSET_STATUS.DRAFT_READY_FOR_FEATURING]: 'Draft - Ready for Featuring',
    [CONSTANTS.ASSET_STATUS.SCHEDULE_FEATURE]: 'Scheduled For Featured',
    [CONSTANTS.ASSET_STATUS.FAILED]: 'Failed',
    [CONSTANTS.ASSET_STATUS.PUBLISH_FAIL]: 'Publishing Failed',
    [CONSTANTS.ASSET_STATUS.FEATURED]: 'Featured',
    [CONSTANTS.ASSET_STATUS.ARCHIEVED]: 'Archived',
    [CONSTANTS.ASSET_STATUS.DE_PUBLISH]: 'Depublished',
    [CONSTANTS.ASSET_STATUS.UNFEATURED]: 'Un Featured',
    [CONSTANTS.ASSET_STATUS.RIGHTS_EXPIRED]: 'Rights Expired',
    [CONSTANTS.ASSET_STATUS.DRAFT_CREATE]: 'Draft Create',
    [CONSTANTS.ASSET_STATUS.PUBLISHED]: 'Published',
    [CONSTANTS.ASSET_STATUS.DELETED]: 'Deleted',
  }

  return statusMap[status] || status || ''
}

export const getAssetStatusBackgroundColor = (status: any) => {
  const colorMap: Record<string, string> = {
    [CONSTANTS.ASSET_STATUS.CREATED]: '#0073A1',
    [CONSTANTS.ASSET_STATUS.DRAFT]: '#9F760D',
    [CONSTANTS.ASSET_STATUS.DRAFT_COMPLETE]: '#5C4200',
    [CONSTANTS.ASSET_STATUS.DRAFT_UNDER_QC]: '#402080',
    [CONSTANTS.ASSET_STATUS.DRAFT_READY_FOR_FEATURING]: '#160B2D',
    [CONSTANTS.ASSET_STATUS.SCHEDULE_FEATURE]: '#0073A1',
    [CONSTANTS.ASSET_STATUS.FAILED]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.PUBLISH_FAIL]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.FEATURED]: '#005A30',
    [CONSTANTS.ASSET_STATUS.ARCHIEVED]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.DE_PUBLISH]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.UNFEATURED]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.RIGHTS_EXPIRED]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.DELETED]: '#D4351C',
  }

  return colorMap[status] || '#E6F7FD'
}
export const getColorForState = (stateName: string) => {
  const colors: { [key: string]: string } = {
    [CONSTANTS.COUNT_STATUS.DRAFT]: '#7CAB13',
    [CONSTANTS.COUNT_STATUS.READY_FOR_PUBLISHING]: '#79DEFE',
    [CONSTANTS.COUNT_STATUS.FEATURED]: '#7557FB',
    [CONSTANTS.COUNT_STATUS.UNFEATURED]: '#F96E5A',
    [CONSTANTS.COUNT_STATUS.SCHEDULE_TO_FEATURE]: '#FFB26A',
    [CONSTANTS.COUNT_STATUS.ARCHIEVED]: '#dcdcdc',
  }
  return colors[stateName] || '#000000'
}
export const STATE_ORDER = [
  'draft',
  'ready_for_featuring',
  'featured',
  'unfeatured',
  'scheduled_to_feature',
]
export const stringToHexColor = (str: string) => {
  let hashCode = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hashCode = (hashCode << 5) - hashCode + char
    hashCode &= hashCode
  }

  const colorCode = '#' + (hashCode & 0xffffff).toString(16).padStart(6, '0')

  return colorCode
}
export const getContentCategoryColor = (status: any) => {
  const colorMap: Record<string, string> = {
    ['TV_SHOW']: '#7CAB13',
    ['ORIGINALS']: '#F96E5A',
    ['MOVIES']: '#7557FB',
    ['NON_VOD_ASSETS']: '#FFB26A',
    ['SPORTS']: '#DE76C1',
    ['INDEPENDENT_VOD_ASSETS']: '#79DEFE',
  }

  return colorMap[status] || stringToHexColor(status)
}

export const getMetaCategoryName = (status: any) => {
  const statusMap: Record<string, string> = {
    ['TV_SHOW']: 'TV Shows',
    ['ORIGINALS']: 'Originals',
    ['MOVIES']: 'Movies',
    ['SPORTS']: 'Sports',
    ['INDEPENDENT_VOD_ASSETS']: 'Independent VOD Assets',
    ['NON_VOD_ASSETS']: 'Non VOD Assets',
  }

  return statusMap[status] || status || ''
}
export const getMetaCategoryNameHomepage = (status: any) => {
  const statusMap: Record<string, string> = {
    ['TV_SHOW']: 'TV Shows',
    ['ORIGINALS']: 'Originals',
    ['MOVIES']: 'Movies',
    ['SPORTS']: 'Sports',
    ['INDEPENDENT_VOD_ASSETS']: 'Independent VOD',
    ['NON_VOD_ASSETS']: 'Non VOD',
  }

  return statusMap[status] || status || ''
}
export const toMilliseconds = (hrs: number, min: number, sec: number) =>
  (hrs * 60 * 60 + min * 60 + sec) * 1000

export const setToken = (token: string) => {
  const decoded = jwtDecode(token)
  const { exp = 0 } = decoded || {}
  const expireTimeInMiliSec = exp * 1000
  const authConfig: any = {
    token: token,
    expireTime: expireTimeInMiliSec,
  }
  setLocalStorage(CONSTANTS.LOCAL_KEYS.AUTH_CONFIG, authConfig)
  store.dispatch(setAuthConfig(authConfig) as any)
  setRefreshTokenCallback()
}

export const setRefreshTokenCallback = () => {
  const token: { token: string; expireTime: number } = JSON.parse(
    localStorage.getItem(CONSTANTS.LOCAL_KEYS.AUTH_CONFIG) as string,
  )
  if (token?.expireTime) {
    const expireTimeInMiliSec = new Date(token?.expireTime).setMinutes(
      new Date(token.expireTime).getMinutes() - 5,
    )
    if (new Date(expireTimeInMiliSec) < new Date()) refreshToken()
  }
}

export const refreshToken = async () => {
  const { data = {} } = await mainApiService(AUTH_MANAGEMENT.REFRESH_TOKEN())
  const { token = '' } = data
  if (token) setToken(token)
}

export const getDateInFormat = (date: string, dFormat: string) => {
  if (date === 'NA') return 'NA'
  if (date) return format(new Date(date), dFormat)
  return ''
}
export const convertToUTC = (localDate: string, timeZone: string) => {
  if (!localDate || localDate === 'NA') return 'NA'
  const utcDate = zonedTimeToUtc(localDate, timeZone)
  return utcDate.toISOString()
}

export const isAtLeastOneTrue = (arr: roleModulesModal[]) => {
  for (const module of arr) {
    for (const submodule of module.submodules) {
      const permissions = submodule.permission
      if (Object.values(permissions).some((value) => value === true)) {
        return true
      }
    }
  }
  return false
}

export const isAtLeastOneSelectedPerModule = (arr: roleModulesModal[]) => {
  const hasTruePermission = (submodules: any[]) => {
    for (const submodule of submodules) {
      const permissions = submodule.permission
      if (Object.values(permissions).some((value) => value === true)) {
        return true
      }
    }
    return false
  }
  for (const module of arr) {
    if (hasTruePermission(module.submodules)) {
      return true
    }
  }
  return false
}
export const getWidgetTypeText = (layout: string) => {
  const {
    ASSET_TEXT_WITH_S,
    ASSET_TEXT,
    ITEM_TEXT_WITH_S,
    WIDGET_TEXT_WITH_S,
    CONTAINER_TEXT_WITH_S,
  } = MODULE_CONSTANTS
  if (!layout || layout.trim().length === 0) return ASSET_TEXT_WITH_S
  if (getWidgetDataBasedOnType(layout, 'spotLightLayout')) {
    return ITEM_TEXT_WITH_S
  }
  if (getWidgetDataBasedOnType(layout, 'widgetTabList')) {
    return WIDGET_TEXT_WITH_S
  }
  if (getWidgetDataBasedOnType(layout, 'categoryPageLayout')) {
    return CONTAINER_TEXT_WITH_S
  }
  if (getWidgetDataBasedOnType(layout, 'cardSkinnedVideo')) {
    return ASSET_TEXT
  }
  return ASSET_TEXT_WITH_S
}
export const objectToQueryString = (obj: any): any => {
  const keys = Object.keys(obj)
  const keyValuePairs = keys.map((key) => {
    if (obj[key]) {
      return (key + '=' + obj[key]) as string | number | boolean
    }
  })

  const filteredArray = keyValuePairs.filter((element) => element !== undefined)
  return filteredArray.join('&')
}
export function convertHyphenatedString(input: string) {
  return input
    .toLowerCase()
    .split('-')
    .map((word: string, index: number) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join('')
}
export const getPageTitle = (id: string) => {
  const { VIEW, EDIT, CREATE } = CONSTANTS.PAGE_TYPE
  switch (id) {
    case VIEW as string:
      return VIEW
    case CREATE:
      return CREATE
    default:
      return EDIT
  }
}

export const getSubstring = (value: string, limit: number) => {
  return value?.length > limit ? `${value?.substring(0, limit)}...` : value
}

export const getStringLimit = (value: string, limit: number): string => {
  if (value?.length > limit) {
    return value
  } else return ''
}

export const combineErrorMessage = (unwrappableRes: any) => {
  if (Array.isArray(unwrappableRes?.error?.errors)) {
    if (unwrappableRes?.error?.errors?.length > 0) {
      return unwrappableRes?.error?.errors.join(',\n')
    }
    if (unwrappableRes?.error?.data?.length > 0) {
      return unwrappableRes?.error?.data.join(',\n')
    }
  }

  return unwrappableRes?.error?.message || 'Something went wrong'
}

export const combineErrors = (errors: any) => {
  if (Array.isArray(errors)) {
    if (errors?.length > 0) {
      return errors.join(',\n')
    } else {
      return 'Something went wrong'
    }
  } else {
    return 'Something went wrong'
  }
}

export const handleApiError = (dispatch: Dispatch, result: { error?: { errors: any[] } }) => {
  const toastMessages =
    result?.error?.errors
      ?.filter((item: any) => item.includes('toastMessage'))
      .map((item: any) => item.replace(/["']?toastMessage["']?\s*/i, '').trim()) || []

  if (toastMessages.length > 0) {
    dispatch(showAlert([true, combineErrors(toastMessages), 'error']))
  }
  dispatch(setHookFormErrors(result?.error?.errors))
  dispatch(setLoader(false))
}

export const isValidObject = (obj: any) => {
  if (obj) {
    return Object.keys(obj).length > 0 ? true : false
  }
  return false
}

export const getValueFromObjArr = (obArr: any[], key: string) => {
  let value = ''
  if (obArr?.length > 0 && key) {
    obArr?.forEach((obj: any) => {
      if (isValidObject(obj) && obj?.key === key) {
        value = obj?.value
      }
    })
  }
  return value ? value : ''
}
/**
Returns the first `count` words from a comma-separated string and the remaining count.
@param input - The comma-separated string.
@param count - Number of words to return from the start.
@returns A formatted string like "First, Second + N others" or just "First, Second".
 */
export function getFormattedWords(input: string, count: number): string {
  if (!input || typeof input !== 'string' || count <= 0) {
    return '' // Handle invalid inputs or zero/negative count
  }
  const parts = input?.split(',') // Split the input string by commas
  const selectedWords = parts?.slice(0, count).join(', ') // Get the first `count` words
  const remainingCount = parts?.length - count // Calculate remaining words count
  return remainingCount > 0 ? `${selectedWords} +${remainingCount} others` : selectedWords
}
export function truncatedValue(text: string, length: number) {
  return `${text.slice(0, length)}${text.length >= length ? '...' : ''}`
}
export function trimStrings(value: any): any {
  // If the value is a string, trim it and return it
  if (typeof value === 'string') {
    return value.trim()
  }
  // If the value is an array, recursively trim its elements
  if (Array.isArray(value)) {
    return value.map(trimStrings)
  }
  // If the value is an object, recursively trim its properties
  if (typeof value === 'object' && value !== null) {
    // Create a new object to store the trimmed values
    const trimmedObj: any = {}
    // Loop through each key in the object
    for (const key in value) {
      // Ensure the property is directly on the object and not inherited
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        trimmedObj[key] = trimStrings(value[key])
      }
    }
    return trimmedObj
  }
  // For other types, just return the value as is
  return value
}

export const createAttributePayload = (attributeFormData: any, type: string) => {
  const tmpArr: any[] = []
  attributeFormData?.attributeRef?.forEach((item: any, index: number) => {
    tmpArr.push({
      _id: item?._id,
      sortOrder: index + 1,
    })
  })

  let affiliatePartner = []
  if (attributeFormData?.affiliatePartner?.length > 0) {
    affiliatePartner = attributeFormData?.affiliatePartner.map((x: any) => x.key)
  }

  const defaultPayload = {
    fieldType: attributeFormData?.fieldType ? attributeFormData?.fieldType : undefined,
    dataType: attributeFormData?.dataType ? attributeFormData?.dataType : undefined,
    fieldName: attributeFormData?.fieldName ? attributeFormData?.fieldName?.trim() : undefined,
    isRequired: attributeFormData?.isRequired
      ? attributeFormData?.isRequired === 'Yes'
        ? true
        : false
      : undefined,
    isMultiple: attributeFormData?.isMultiple
      ? attributeFormData?.isMultiple === 'Yes'
        ? true
        : false
      : undefined,
    label: attributeFormData?.label ? attributeFormData?.label : undefined,
    isB2B: attributeFormData?.isB2B
      ? attributeFormData?.isB2B === 'Yes'
        ? true
        : false
      : undefined,
    defaultValue: attributeFormData?.defaultValue ? attributeFormData?.defaultValue : '',
    affiliatePartner: attributeFormData?.affiliatePartner?.length > 0 ? affiliatePartner : [],
    placeholder: attributeFormData?.placeholder ? attributeFormData?.placeholder : undefined,
    master: attributeFormData?.master ? attributeFormData?.master : undefined,
    options: attributeFormData?.dataType ? attributeFormData?.dataType : undefined,
    addMore: attributeFormData?.addMore
      ? attributeFormData?.addMore === 'Yes'
        ? true
        : false
      : undefined,
    status: attributeFormData?.status ? attributeFormData?.status : undefined,
    isMultilingual: attributeFormData?.isMultilingual
      ? attributeFormData?.isMultilingual === 'Yes'
        ? true
        : false
      : undefined,
    esField: attributeFormData?.esField ? attributeFormData?.esField : null,
    description: attributeFormData?.description ? attributeFormData?.description : undefined,
    additionalProperties: attributeFormData?.additionalProperties
      ? JSON.parse(attributeFormData?.additionalProperties)
      : null,

    attributeRef: tmpArr,
    metaCategory: 'NA',
    validations: {},
  }
  if (type === CONSTANTS.PAGE_TYPE.CREATE) {
    const reqData = {
      ...defaultPayload,
      options: attributeFormData?.options?.split(',') || [],
    }
    return reqData
  }
  if (type === CONSTANTS.PAGE_TYPE.EDIT) {
    let options = []
    if (Array.isArray(attributeFormData?.options)) {
      options = attributeFormData?.options?.toString()
      options = options.split(',')
    } else {
      options = attributeFormData?.options?.split(',') || []
    }
    const reqData = {
      ...defaultPayload,
      options: options,
    }
    return reqData
  }
  return {}
}

export const removeDuplicateAttributes = (arr: any[]) => {
  const uniqueArr: any[] = []
  arr?.forEach((x: any) => {
    const index = uniqueArr.findIndex((y: any) => y?.fieldName === x?.fieldName)
    if (index < 0) {
      uniqueArr.push(x)
    }
  })
  return uniqueArr
}

export const isValidArray = (array: any[]) => {
  return typeof array === 'object' && typeof array !== 'string' && array?.length > 0 ? true : false
}

export const isValidJSON = (str: string) => {
  /*
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
  */
  try {
    const o = JSON.parse(str)
    if (o && typeof o === 'object') return true
    return false
  } catch (e) {
    return false
  }
}

// delete element from object if its null/undefined/empty
export const ValidateObject = (Obj: any) => {
  try {
    Object.keys(Obj).forEach((key) => {
      if (Obj[key] === null || Obj[key] === undefined || Obj[key] === '') {
        delete Obj[key]
      }
    })
    return Obj
  } catch (e) {
    return {}
  }
}

export const getSystemConfigurationMasterObject = (systemConfigurationPageType: string) => {
  let result: any = null
  MASTER_MANAGEMENT_CONSTANTS.SYSTEM_CONFIGURATION_LIST?.forEach((sc: any) => {
    const { url = '', key = '', name = '' } = sc
    if (url && key && name) {
      const splittedURL = url?.split('/')
      const pageType = splittedURL[splittedURL?.length - 1]
      if (systemConfigurationPageType === pageType) {
        result = sc
      }
    }
  })
  return result
}

export const emptyFunction = () => {
  return
}

export const getMasterPayload = (masterKey: string, obj: any) => {
  const body = {
    configKey: masterKey,
    configValue: {
      key: obj?.key || '',
      value: obj?.value || '',
    },
  }
  return body
}

const replacer = (match: any, pIndent: any, pKey: any, pVal: any, pEnd: any) => {
  const key = '<span class=json-key>'
  const val = '<span class=json-value>'
  const str = '<span class=json-string>'
  let r = pIndent || ''
  if (pKey) r = r + key + pKey.replace(/[": ]/g, '') + '</span>: '
  if (pVal) r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>'
  return r + (pEnd || '')
}

export const prettyPrint = (obj: any) => {
  const jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm
  return JSON.stringify(obj, null, 3)
    .replace(/&/g, '&amp;')
    .replace(/\\"/g, '&quot;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(jsonLine, replacer)
}

export const updatingSortOrderValue = (array: any[]) => {
  const copyArr = JSON.parse(JSON.stringify(array))
  copyArr?.forEach((item: any, index: number) => {
    item.sortOrder = index
  })
  return copyArr
}

export const uploadInPresignedURL = async (
  presignedUrl: any,
  file: any,
  dispatch: any,
  uploadFileName: any,
) => {
  try {
    if (!presignedUrl) return ''
    if (!file) {
      dispatch(showAlert([true, 'Please select a file', 'error']))
      return
    }
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
    })
    if (response.ok) {
      return uploadFileName
    } else {
      dispatch(showAlert([true, 'Upload Failed', 'error']))
      return ''
    }
  } catch (error) {
    dispatch(showAlert([true, 'Upload Failed', 'error']))
    return ''
  }
}

// export const uploadSubtitleToS3 = async (
//   dispatch: any,
//   uploadFileName: string,
//   file: any,
//   contentId: string,
// ) => {
//   try {
//     let reqUrl: any = ''
//     const result: any = dispatch(
//       generatePresignedUrlForSubtitle(`key=${uploadFileName}&contentId=${contentId}`) as any,
//     )
//     const unwrapRes = unwrapResult(await result)
//     if (unwrapRes?.error) {
//       dispatch(showAlert([true, combineErrorMessage(unwrapRes), 'error']))
//       return reqUrl
//     }
//     const resUrl = unwrapRes?.data?.url || ''
//     const fileName = await uploadInPresignedURL(resUrl, file, dispatch, uploadFileName)
//     reqUrl = fileName ? `${CONSTANTS.S3_SUBTITLE_BUCKET_BASE_URL}/${fileName}` : ''
//     return reqUrl
//   } catch (error: any) {
//     console.log('error', error)
//     return ''
//   }
// }

export const downloadFile = async (dispatch: any, path: string, markAsDoneDetail: any) => {
  try {
    let id = markAsDoneDetail?.legacyHouseId
    if (!id) {
      id = markAsDoneDetail?.blitzId
    }
    const url = `${CONSTANTS.CLOUDFRONT_BASE_URL_SUBTITLE}/subtitles/${id}/${path}`
    const res: any = await fetch(url)
    const blob: any = await res.blob()

    if (blob) {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${path}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  } catch (error: any) {
    dispatch(showAlert([true, `'Error downloading file:' ${error}`, 'error']))
  }
}

export const checkModuleAccess = (role: string | undefined) => {
  let result = true
  if (role) {
    const roleData: any = getLocalStorage(CONSTANTS.LOCAL_KEYS.ROLE_DATA)
    for (let i = 0; i < roleData?.length; i++) {
      for (let j = 0; j < roleData[i]?.submodules?.length; j++) {
        if (roleData[i]?.submodules[j]?.key === role) {
          result = roleData[i]?.submodules[j]?.permission?.canAccess
          break
        }
      }
    }
  }
  return result
}
export const getCapitalise = (value: string) => {
  return value?.charAt(0)?.toUpperCase() + value?.replace('_', ' ')?.slice(1)?.toLowerCase()
}

export const convertSecondsToHours = (seconds: number) => {
  const days = Math.floor(seconds / (3600 * 24))
  const hours = Math.floor((seconds % (3600 * 24)) / 3600)
  const remainingSeconds = seconds % 3600
  const minutes = Math.floor(remainingSeconds / 60)
  const remSeconds = remainingSeconds % 60
  const getValue = (value: number, key: string) => {
    return `${value} ${key}${value > 1 ? 's' : ''}`
  }
  if (days > 0) {
    return `${getValue(days, 'day')}, ${getValue(hours, 'hour')}, ${getValue(
      minutes,
      'minute',
    )}, ${getValue(remSeconds, 'second')}`
  } else if (hours > 0) {
    return `${getValue(hours, 'hour')}, ${getValue(minutes, 'minute')}, ${getValue(
      remSeconds,
      'second',
    )}`
  } else if (minutes > 0) {
    return `${getValue(minutes, 'minute')}, ${getValue(remSeconds, 'second')}`
  } else if (remSeconds > 0) {
    return `${getValue(remSeconds, 'second')}`
  }
  return 'NA'
}

export const fetchOptionsValue = (options: any, value: string) => {
  if (options && options.length) {
    const displayObj = options.find((item: any) => item.key === value)
    return displayObj?.value ?? ''
  }
  return ''
}

export const getValueBasedOnString = (
  value: any,
  fieldType: string,
  tagInput: boolean,
  dataType: string,
  element: {
    attribute?: {
      dataType?: string
      options?: {
        value: any
        label: string
      }[]
    }
  },
): string => {
  if (fieldType !== CONSTANTS.FIELDTYPES.FILE) {
    if ((typeof value !== 'boolean' && !value) || (Array.isArray(value) && value.length === 0)) {
      return 'NA'
    }
    if (
      tagInput ||
      (fieldType === CONSTANTS.FIELDTYPES.SELECT &&
        dataType === CONSTANTS.ASSET_CREATION_OBJ.BOOLEAN)
    ) {
      if (Array.isArray(value)) {
        return value.join(', ')
      } else {
        return typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value
      }
    } else if (fieldType === CONSTANTS.FIELDTYPES.SELECT) {
      if (Array.isArray(value)) {
        return value
          .map((item: any) => fetchOptionsValue(element?.attribute?.options, item))
          .join(', ')
      } else {
        return fetchOptionsValue(element?.attribute?.options, value)
      }
    } else if (
      fieldType === CONSTANTS.FIELDTYPES.DATE ||
      fieldType === CONSTANTS.FIELDTYPES.DATETIME ||
      fieldType === CONSTANTS.FIELDTYPES.TIME
    ) {
      if (dataType === CONSTANTS.FIELDTYPES.NUMBER) {
        return convertSecondsToHours(value)
      }
      return getDateInFormat(value ?? '', CONSTANTS.DATE_FORMAT_TIME)
    } else if (
      fieldType === CONSTANTS.FIELDTYPES.CHECKBOX ||
      fieldType === CONSTANTS.FIELDTYPES.RADIO ||
      fieldType === CONSTANTS.FIELDTYPES.SWITCH
    ) {
      return value ? 'Yes' : 'No'
    } else {
      return value
    }
  }
  return ''
}

export const subStringWithEllipses = (value: string, count = 50, elipses = true): string => {
  if (value.length <= count) {
    return value
  }
  const truncatedString = value.substring(0, count)
  return elipses ? `${truncatedString}...` : truncatedString
}
// Utilized it if you need temporary unique id
export const uniqueId = () => {
  return Math.random() + getTime(new Date())
}

export const replaceUnwanted = (data: string) => {
  return data?.replace(/[^A-Z0-9]+/gi, '_')?.toLowerCase()
}
export const filterObject = <T extends Record<string, string | number>>(obj: T): T => {
  const result: Partial<T> = {}
  for (const key in obj) {
    if (
      obj[key] !== 0 &&
      obj[key] !== '' &&
      key !== 'filterCondition' &&
      key !== 'filterValue' &&
      key !== 'filterBy'
    ) {
      result[key] = obj[key]
    }
  }

  return result as T
}
export const getCurrentStatus = (status: string) => {
  switch (status) {
    case 'PREVIEW_INITIATED':
      return 'Preview request in-progress'
    case 'TRANSCODING_INITIATED':
      return 'Transcoding in-progress'
    case 'VERIFICATION_INITIATED':
      return 'Fetching in-progress'
    default:
      return ''
  }
}

export const getSuccessStatus = (state: string) => {
  switch (state) {
    case 'validation':
      return 'Fetching Successful'
    case 'transcoding':
      return 'Transcoding Success'
    case 'preview':
      return 'Preview Request Successful'
    default:
      return `${state} is Successfully Done`
  }
}

export const changeToCapitalCase = (value: string) => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
}

/**
 * Function to return parsed data of JWT token in the form of object.
 * @function getParsedToken
 * @returns {object} JWT parsed object which is parsed from token stored in localstorage
 */
export const getParsedToken = () => {
  const authConfig: { token: string } = getLocalStorage('auth_config')
  if (authConfig?.token) {
    const parsedToken = jwtDecode(authConfig.token)
    return parsedToken
  }
}

export const logoutCyberArk = (message = '') => {
  if (message) {
    setLocalStorage(CONSTANTS.LOCAL_KEYS.LOGOUT_MESSAGE, { message })
  }
  const authToken: any = getLocalStorage(CONSTANTS.LOCAL_KEYS.AUTH_CONFIG)
  if (authToken?.token) {
    const parsedToken: { sub: { accessToken: string } } = jwtDecode(authToken.token)
    if (parsedToken?.sub?.accessToken) {
      const encodedOrigin = encodeURIComponent(`${window.location.origin}/login`)
      const redirectUrl = `${CONSTANTS.SESSION_END_URL}?id_token_hint=${parsedToken?.sub?.accessToken}&post_logout_redirect_uri=${encodedOrigin}`
      window.location.href = redirectUrl
    }
  } else {
    window.location.href = PAGES_ROUTES.LoginPage
  }
}

export const getDetailedErrorMessage = (jsonString: string, error: { message: string }) => {
  const lines = jsonString.split('\n')
  const positionMatch: any = error.message.match(/position (\d+)/)

  if (positionMatch) {
    const position = parseInt(positionMatch[1], 10)
    let lineNumber = 0
    let charCount = 0
    const lineLengthArr = []
    // let count = 0
    for (let i = 0; i < lines.length; i++) {
      const lineLength = lines[i].length + 1
      lineLengthArr.push(lines[i].length)
      // count += lines[i].length
      const charValue = charCount + lineLength
      if (charValue > position) {
        lineNumber = i + 1
        break
      }

      charCount += lineLength
    }

    const nearbyCode = jsonString.substring(position - 10, position + 10)
    if (
      nearbyCode.includes(',') &&
      nearbyCode.includes('}') &&
      !error.message.includes('non-whitespace')
    ) {
      return `Parse error on line ${lineNumber - 1}: Misplaced comma near line ${
        lineNumber - 1
      } in JSON at position ${charCount - 2} (line ${lineNumber - 1} column ${
        lines[lineNumber - 2].length
      })`
    }

    if (
      nearbyCode.includes(',') &&
      nearbyCode.includes('{') &&
      !error.message.includes('non-whitespace')
    ) {
      return `Parse error on line ${
        lineNumber - 1
      }: Misplaced comma near line ${lineNumber} in JSON at position ${
        positionMatch[1]
      } (line ${lineNumber} column ${lines[lineNumber - 1].length})`
    }

    return `Parse error on line ${lineNumber}: ${error.message}`
  } else if (!error.message.includes('position') && error.message.includes('Unexpected token')) {
    const undefinedPosition: any = []
    const errorIndex: any = []
    let positionCount = 0
    const lineBreakPosition = []
    let errorLineNumber = 0
    let errorLinerPosition = 0
    for (let i = 0; i < lines.length; i++) {
      const lineLength = lines[i]?.length + 1
      const elements = lines[i]?.split(' ')
      positionCount = positionCount + lines[i].length
      lineBreakPosition?.push(lineLength)
      for (const ele of elements) {
        const trimmedElement = `'${ele.trim()}'`
        if (trimmedElement === error.message?.split('Unexpected token')[1]?.split(',')[0]?.trim()) {
          errorIndex.push(lines[i].indexOf(ele.charAt(0)))
          undefinedPosition?.push(ele)
          errorLineNumber = lineBreakPosition.length - 1
          errorLinerPosition = lineBreakPosition[lineBreakPosition.length - 1]
          break
        }
      }
    }
    return `${error.message} at position ${positionCount} (line ${errorLineNumber} column ${errorLinerPosition})`
  }
  return error.message
}

const keys = (keyVal: any) => keyVal.split(',').map((key: any) => key.trim())

const checkOptions = (options: any[], key: string, tempErrorState: any[]) => {
  if (
    options &&
    !options.some((item: any) =>
      keys(key).some((k: any) => item.key?.toLowerCase() === k.toLowerCase()),
    )
  ) {
    tempErrorState.push(key)
  }
}

const getFilteredValue = (options: any[], key: string) => {
  return (
    options?.filter((item: any) =>
      keys(key).some((k: any) => k.toLowerCase() === item.key?.toLowerCase()),
    ) || []
  )
}

export const addMoreJsonComplexFieldHelper = (
  localComplexFields: any,
  fieldData: any,
  fieldName: string,
  setValue?: any,
  fetchedData?: any,
  reset?: any,
) => {
  const oldComplexFields = localComplexFields[fieldName]
  const leftParam = oldComplexFields[0]?.fields?.[0]?.parameter
  const rightParam = oldComplexFields[0]?.fields?.[1]?.parameter
  const leftOptions = fetchedData[leftParam.master ?? '']
  const rightOptions = fetchedData[rightParam.master ?? '']

  const tempErrorState: any = []
  reset?.({ [fieldName]: [] })

  const newFields = fieldData.map(({ key, value }: any, index: number) => {
    const updatedField = {
      id: index + 1,
      fields: oldComplexFields[0]?.fields || [],
    }

    checkOptions(leftOptions, key, tempErrorState)
    checkOptions(rightOptions, value, tempErrorState)

    const initalValue = leftParam?.multiSelect ? getFilteredValue(leftOptions, key) : key ?? ''
    const ratingValue = rightParam?.multiSelect
      ? getFilteredValue(rightOptions, value)
      : value ?? ''

    if (!tempErrorState.length) {
      setValue(`${fieldName}_${index + 1}_${leftParam.fieldName}`, initalValue)
      setValue(`${fieldName}_${index + 1}_${rightParam.fieldName}`, ratingValue)
    }
    return updatedField
  })

  return { newFields, tempErrorState }
}

export const combinedObject = (arrayOfObjects: any, complexFieldName: any) =>
  arrayOfObjects?.[complexFieldName]?.reduce((acc: any, curr: any) => {
    const key = Object.keys(curr)[0] // Get the key from the current object
    const value = curr[key] // Get the value associated with that key

    // If the key already exists in the accumulator, concatenate the values
    if (acc[key]) {
      acc[key] += `, ${value}` // Concatenate with a comma
    } else {
      acc[key] = value // Otherwise, set the value
    }

    return acc // Return the accumulator for the next iteration
  }, {})

export const getComplexFieldOutput = (
  control: any,
  complexFieldName: string,
  fieldNames: string[],
) => {
  const output: any = []
  Object.keys(control._getWatch()).forEach((key) => {
    const match = key.match(new RegExp(`^${complexFieldName}_(\\d+)_${fieldNames[0]}$`))
    if (match) {
      const index = match[1]
      const initalKey = `${complexFieldName}_${index}_${fieldNames[0]}`
      const ratingKey = `${complexFieldName}_${index}_${fieldNames[1]}`
      const keyWatch = control._getWatch()?.[initalKey]
      const valueWatch = control._getWatch()?.[ratingKey]
      const initalValue = Array.isArray(keyWatch)
        ? keyWatch?.map((item: any) => item.key).join(', ')
        : keyWatch
      const ratingValue = Array.isArray(valueWatch)
        ? valueWatch?.map((item: any) => item.key).join(', ')
        : valueWatch

      if (initalValue && ratingValue) {
        output.push({
          [initalValue]: ratingValue,
        })
      }
    }
  })
  return output
}

export const logout = async () => {
  const { responseCode, message, error = {} } = await mainApiService(AUTH_MANAGEMENT.LOGOUT())
  if (responseCode === 200) {
    logoutCyberArk(message)
    return
  }
  if (error) store.dispatch(showAlert([true, error?.message, 'error']))
}
export const getSelectedAttributeDetails = (selectedAttri: any, attributes: any) => {
  const matchingObjects = []

  for (const selectedObj of selectedAttri) {
    const matchingDataObject = attributes.find((ele: any) => ele._id === selectedObj._id)

    if (matchingDataObject) {
      matchingObjects.push(matchingDataObject)
    }
  }

  return matchingObjects
}

export const convertPluraltoSingular = (str: string) => {
  return str.toLowerCase()?.endsWith('s') ? str.slice(0, -1) : str
}

export const AVS_MESSAGES: any = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
  AVS_INGESTION: 'AVS Ingestion',
  AVS_PUBLISH: 'AVS Publishing',
  AVS_DEPUBLISH: 'AVS De-Publish',
}

export const getColor = (status: string) => {
  return status === 'SUCCESS' ? '#4C6A0B' : status === 'FAILED' ? '#D4351C' : ''
}

export const getBGColor = (status: string) => {
  return status === 'SUCCESS' ? '#F4F9EA' : status === 'FAILED' ? '#FFDBD5' : ''
}

export const getStatusMessage = (avsStatus: string, avsAction: string) => {
  const statusMessage = AVS_MESSAGES[avsStatus] || ''
  const actionMessage = AVS_MESSAGES[avsAction] || ''
  return actionMessage && statusMessage ? `${actionMessage}: ${statusMessage}` : ''
}
export function handleResponse(apiResponse: any) {
  let messages: any[] = []
  let isError = false
  let responseCode = null
  function traverse(node: any) {
    if (!node || typeof node !== 'object') return
    for (const key in node) {
      if (key === 'message' && typeof node?.[key] === 'string') {
        messages?.push(node?.[key])
      } else if ((key === 'messages' || key === 'errors') && Array.isArray(node?.[key])) {
        messages = messages?.concat(node?.[key]?.filter((msg: any) => typeof msg === 'string'))
      } else if (
        (key?.toLowerCase() === 'responsecode' || key?.toLowerCase() === 'code') &&
        typeof node?.[key] === 'number'
      ) {
        responseCode = node?.[key]
      } else {
        traverse(node?.[key])
      }
    }
  }
  traverse(apiResponse)
  // Determine if it is an error response
  isError = apiResponse?.status === 'error' || apiResponse?.error === true || responseCode !== 200
  return {
    error: isError,
    responseCode: responseCode,
    message: messages?.join(' '),
  }
}
export function convertAndCheck(input: any) {
  // Attempt to convert the input to a number
  const num = parseFloat(input)
  // Check if the input is a valid number
  if (!isNaN(num) && input === num.toString()) {
    return {
      original: input,
      converted: num,
      type: 'number',
    }
  } else {
    return {
      original: input,
      converted: NaN,
      type: 'string',
    }
  }
}
export function findParameterName(objects: any[], fieldName: string) {
  // Iterate over each object in the array

  for (const obj of objects) {
    // Check if the field name matches the object's field property
    if (obj.fieldName === fieldName) {
      // Return the parameter name if a match is found
      return obj.parameterName
    }
  }
  // Return null if no match is found
  return ''
}
export const validValueOrEmptyString = (value: any) => {
  if (!value) {
    return ''
  }
  return value
}

export const validateNoSpace = (value: string) => {
  return value?.trim() !== '' || 'This Field can not be empty or just spaces'
}
/**
 * To get the uniqe array obj removes deuplicate value based on keys
 * @function removeDuplicates
 * @param arr Array of object from which duplicate value need to be removed
 * @param key the key of by which we need to remove duplicate data from array
 * @returns {array} returns unique array of object based on key.
 */
export function removeDuplicates(arr: TypeObject[], key: string) {
  const seenAssetTypes = new Set()
  const uniqueObjects: TypeObject[] = []

  arr.forEach((obj: TypeObject) => {
    const keyObj = obj[key]
    if (!seenAssetTypes.has(keyObj)) {
      seenAssetTypes.add(keyObj)
      uniqueObjects.push(obj)
    }
  })

  return uniqueObjects
}
export const nullValuesPresent = (data: any) => {
  return Object?.values(data)?.every((value) => value === null)
}

export const getAttrDefValidationName = (validationNameKey: string, validationsList: any) => {
  return (
    (Array.isArray(validationsList) ? validationsList : [])?.find(
      (item: any) => item?.functionName === validationNameKey,
    )?.displayName || 'NA'
  )
}
export const getAttrDefDataSourceName = (dataSourceKey: string, dataSourceList: any) => {
  return (
    (Array.isArray(dataSourceList) ? dataSourceList : [])?.find(
      (item: any) => item?.key === dataSourceKey,
    )?.value || 'NA'
  )
}
export const getAttrDefScopeName = (scopeValueKey: string, scopesList: any) => {
  return (
    (Array.isArray(scopesList) ? scopesList : [])?.find((item: any) => item?.key === scopeValueKey)
      ?.value || 'NA'
  )
}
export const getAttrDefSourceAttributeName = (sourceAttributeKey: string, attributesList: any) => {
  return (
    (Array.isArray(attributesList) ? attributesList : [])?.find(
      (item: any) => item?.fieldName === sourceAttributeKey,
    )?.label || 'NA'
  )
}
export const staticCheckToHideAsterisk = (element: any) => {
  return element.fieldName === 'class' && element.fieldType === 'SELECT'
}

export const groupAndSortBasedOnBlitzId = (data: Record<string, any>[]) => {
  // Step 1: Group by blitzId
  const grouped = data.reduce((acc: any, item: any) => {
    if (!acc[item.blitzId]) {
      acc[item.blitzId] = []
    }
    acc[item.blitzId].push(item)
    return acc
  }, {})

  // Define the order of statuses
  const statusOrder = ['DRAFT', 'PUBLISHED', 'SCHEDULE PUBLISHED']

  // Function to get the order index
  const getStatusOrderIndex = (status: string) => {
    const index = statusOrder.indexOf(status)
    return index === -1 ? statusOrder.length : index
  }

  // Step 2: Sort each group by status order
  for (const blitzId in grouped) {
    grouped[blitzId].sort((a: any, b: any) => {
      return getStatusOrderIndex(a.status) - getStatusOrderIndex(b.status)
    })
  }

  // Convert the grouped object back to an array of grouped arrays
  const groupedArray = Object.entries(grouped).map(([blitzId, items]) => ({
    blitzId: Number(blitzId),
    items: items,
  }))

  return groupedArray
}
export const isValidImageURL = (url: string) => {
  return /\.(jpeg|jpg|gif|png|webp)$/.test(url)
}
export const validateValue = (value: any, dataType: any) => {
  let error = ''
  switch (dataType) {
    case 'String':
      if (typeof value !== 'string') {
        error = 'Default value must be a string'
      }
      break
    case 'Number':
      if (isNaN(value)) {
        error = 'Default value must be a number'
      }
      break
    case 'Boolean':
      if (value !== 'true' && value !== 'false') {
        error = 'Default value must be a boolean (true/false)'
      }
      break
    case 'Date':
      if (isNaN(Date.parse(value))) {
        error = 'Default value must be a valid date'
      }
      break
    case 'File':
      error = 'Default value for files should be handled in file input fields'
      break
    case 'Image File':
    case 'ImageType':
      if (!isValidImageURL(value)) {
        error = 'Default value must be a valid image URL'
      }
      break
    case 'JSON':
      try {
        JSON.parse(value)
      } catch (e) {
        error = 'Default value must be a valid JSON'
      }
      break
    default:
      break
  }
  return error
}

export const replaceHyphensWithSpaces = (text: string) => {
  return text.replace(/-/g, ' ')
}

export const formatMasterKey = (key: string): string => {
  const parts: string[] = key?.split('/') || []
  const lastPart: string = parts[parts?.length - 1]
  return lastPart?.replace(/_/g, ' ')
}
export const createKeyValuePairs = (input: any) => {
  if (Array.isArray(input)) {
    return input.map((str: string) => ({ key: str, value: str }))
  } else if (typeof input === 'string') {
    return [{ key: input, value: input }]
  } else {
    throw new Error('Input must be either an array of strings or a single string')
  }
}

export const toCamelCase = (str: string) => {
  if (typeof str !== 'string' || str.trim().length === 0) return ''

  str = str
    ?.replace(/\s+/g, ' ')
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase(),
    )
    .replace(/\s+/g, '')
  return str
}

export const isFieldRequired = (required: string) => required?.toLowerCase() === 'yes'

// export const getAllMasterAPIUrls = (widgetDefinition: any) => {
//   const { data: { automated: { formData = [] } = {} } = {} } = widgetDefinition || {}
//   const masterAPIUrlsArray: string[] = []
//   formData?.forEach(({ form = [] }) => {
//     form?.forEach((formField: any) => {
//       const { parameter: { master = '' } = {} } = formField || {}
//       if (master && master.includes('system-configurations')) {
//         master.trim()
//         const splittedUrl = master.split('/')
//         const keyName = `?keyName=${splittedUrl[splittedUrl.length - 1]}`
//         splittedUrl.splice(splittedUrl.length - 1, 1)
//         const finalUrl = `${splittedUrl.join('/')}${keyName}`
//         if (!masterAPIUrlsArray.includes(finalUrl)) masterAPIUrlsArray.push(finalUrl)
//       } else {
//         if (master && !masterAPIUrlsArray.includes(master)) masterAPIUrlsArray.push(master)
//       }
//     })
//   })
//   return masterAPIUrlsArray
// }

export const getCorrectMasterAPIUrl = (masterUrl: any) => {
  let finalUrl
  if (
    masterUrl &&
    (masterUrl.includes('system-configurations') || masterUrl.includes('blitz-master'))
  ) {
    masterUrl.trim()
    const splittedUrl = masterUrl.split('/')
    const keyName = `?keyName=${splittedUrl[splittedUrl.length - 1]}`
    splittedUrl.splice(splittedUrl.length - 1, 1)
    const finalUrl = `${splittedUrl.join('/')}${keyName}`
    return finalUrl
  }
  if (masterUrl) {
    return (finalUrl = masterUrl)
    return
  }
}

export const toCapitalCase = (str: string) => {
  if (str) {
    return str
      .split(' ')
      .map((word, index) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
      )
      .join('')
  }
  return '' as string
}

export const dashStrToCapitalCase = (str: string) => {
  if (str) {
    return str
      .split('_')
      .map((word, index) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
      )
      .join('')
  }
  return '' as string
}

export const keyExists = (obj: any, key: any) => {
  if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
    return false
  } else if (Object.hasOwn(obj, key)) {
    return true
  } else if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      const result: any = keyExists(obj[i], key)
      if (result) {
        return result
      }
    }
  } else {
    for (const k in obj) {
      const result: any = keyExists(obj[k], key)
      if (result) {
        return result
      }
    }
  }

  return false
}

export const updateObject = (keyName: any, newVal: any, object: any) => {
  const results: any = {}
  for (const key in object) {
    if (key === keyName) {
      results[key] = newVal
    } else if (typeof object[key] === 'object' && object[key] !== null) {
      results[key] = updateObject(keyName, newVal, object[key])
    } else {
      results[key] = object[key]
    }
  }
  return results
}

export const checkIsSectionHasAddMore = (form: any[]) => {
  return form?.some((formField) => formField?.parameter?.addMore)
}

export const createDeepCopy = (object: any) => {
  return JSON.parse(JSON.stringify(object))
}

const getMasterOptionData = async (
  master = '',
  fieldName = '',
  contentCategoryValue = [] as any,
  assetCategoryValue = [] as any,
) => {
  if (master && fieldName) {
    const url = getCorrectMasterAPIUrl(master)
    const body = { contentCategoryValue, assetCategoryValue }
    const { payload: { data: { data = {}, responseCode = 0 } = {} } = {} } = await store.dispatch(
      getMastersData({ url, body }) as any,
    )
    if (responseCode === 200) {
      switch (fieldName) {
        case FORM_FIELD.CONTENT_CATEGORY:
          return data?.contentCategories || []
        case FORM_FIELD.ASSET_CATEGORY:
          return data?.assetCategories || []
        case FORM_FIELD.ASSET_TYPE:
          return data?.assetTypes || []
        default:
          if (master.includes('system-configurations')) {
            return data || []
          }
      }
    }
  }
  return []
}

const getOptionsListByContentCategoryValues = async (
  master: string,
  fieldName: string,
  widgetDetailData: any,
) => {
  const contentCategoryValues: any =
    (await getInputFormFieldValue(
      widgetDetailData,
      CATEGORY_LABEL.BLITZ_ASSET_CATEGORY,
      FORM_FIELD.CONTENT_CATEGORY,
    )) || {}
  const contentCategoryKeys: any[] = contentCategoryValues?.value?.map((ele: any) => ele?.key)
  const optionsListRes: any[] = await getMasterOptionData(master, fieldName, contentCategoryKeys)
  return optionsListRes || []
}

const getOptionsListByAssetCategoryValues = async (
  master: string,
  fieldName: string,
  widgetDetailData: any,
) => {
  const contentCategoryValues: any =
    (await getInputFormFieldValue(
      widgetDetailData,
      CATEGORY_LABEL.BLITZ_ASSET_CATEGORY,
      FORM_FIELD.CONTENT_CATEGORY,
    )) || {}
  const contentCategoryKeys = contentCategoryValues?.value?.map((ele: any) => ele?.key)
  const assetCategoryValues: any =
    (await getInputFormFieldValue(
      widgetDetailData,
      CATEGORY_LABEL.BLITZ_ASSET_CATEGORY,
      FORM_FIELD.ASSET_CATEGORY,
    )) || {}
  const assetCategoryKeys = assetCategoryValues?.value?.map((ele: any) => ele?.key)
  const optionsListRes = await getMasterOptionData(
    master,
    fieldName,
    contentCategoryKeys,
    assetCategoryKeys,
  )
  return optionsListRes || []
}

export const initializeInputFormUtil = async (
  formData = [] as any,
  pageType = '',
  widgetDetailData = {} as any,
) => {
  const {
    PAGE_TYPE: { UPDATE, EDIT, VIEW, CREATE },
  } = CONSTANTS
  const savedDataEngineType = widgetDetailData?.data?.engine?.toLowerCase() || DATA_ENGINES.manual
  let inputFormKeysWithAddMoreValue: any = {}
  const finalPreparedInputFormArr: any = []
  for (let formDataIndex = 0; formDataIndex < formData?.length; formDataIndex++) {
    const { label = '', form = [] } = formData[formDataIndex]
    const tempCategoryObj: any = {
      isCategoryHasAddMore: checkIsSectionHasAddMore(form),
      categoryLabel: label,
      categoryData: [
        {
          categoryFormFields: [],
        },
      ],
    }
    for (let fieldIndex = 0; fieldIndex < form?.length; fieldIndex++) {
      const formField: any = createDeepCopy(form[fieldIndex])
      formField.parameter.fieldName = dashStrToCapitalCase(formField.parameter.fieldName)
      const { parameter: { master = '', fieldName = '' } = {} } = formField || {}
      const optionsList = await getMasterOptionData(master, fieldName)
      formField['options'] = optionsList
      const tempObj: any = {}
      tempCategoryObj?.isCategoryHasAddMore
        ? (tempObj[toCapitalCase(label)] = true)
        : (tempObj[dashStrToCapitalCase(fieldName)] = false)
      inputFormKeysWithAddMoreValue = { ...inputFormKeysWithAddMoreValue, ...tempObj }
      if (
        (pageType === UPDATE && savedDataEngineType !== DATA_ENGINES.manual) ||
        ((pageType === EDIT || pageType === CREATE) &&
          savedDataEngineType === DATA_ENGINES.manual) ||
        ((pageType === EDIT || pageType === CREATE) && savedDataEngineType === '')
      ) {
        tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
      }
      if (
        (pageType === EDIT || pageType === VIEW) &&
        savedDataEngineType &&
        savedDataEngineType !== DATA_ENGINES.manual
      ) {
        const categoryLabel = toCapitalCase(label)
        if (fieldName === FORM_FIELD.ASSET_CATEGORY) {
          const optionsListRes: any[] = await getOptionsListByContentCategoryValues(
            master,
            fieldName,
            widgetDetailData,
          )
          formField['options'] = optionsListRes
          tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
        } else if (fieldName === FORM_FIELD.ASSET_TYPE) {
          const optionsListRes: any[] = await getOptionsListByAssetCategoryValues(
            master,
            fieldName,
            widgetDetailData,
          )
          formField['options'] = optionsListRes
          tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
        } else if (categoryLabel === CATEGORY_LABEL.RANGE_BY_BLITZ_ATTRIBUTE) {
          const lengthOfCategory = widgetDetailData?.data?.query?.[categoryLabel]?.length
          if (lengthOfCategory > 1) {
            if (fieldIndex === 0) {
              tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
              tempCategoryObj.categoryData[0].categoryFormFields.push(form[1])
              tempCategoryObj.categoryData[0].categoryFormFields.push(form[2])
              for (let tempI = 1; tempI < lengthOfCategory; tempI++) {
                const categoryFormArr = []
                categoryFormArr.push(formField)
                categoryFormArr.push(form[1])
                categoryFormArr.push(form[2])
                tempCategoryObj.categoryData.push({
                  categoryFormFields: categoryFormArr,
                })
              }
            }
          } else {
            tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
          }
        } else if (
          [
            CATEGORY_LABEL.SELECT_BY_BLITZ_ATTRIBUTE,
            CATEGORY_LABEL.EXCLUDE_BY_BLITZ_ATTRIBUTE,
          ].includes(categoryLabel)
        ) {
          const lengthOfCategory = widgetDetailData?.data?.query?.[categoryLabel]?.length
          if (lengthOfCategory > 1) {
            tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
            for (let tempI = 1; tempI < lengthOfCategory; tempI++) {
              const categoryFormArr = []
              categoryFormArr.push(formField)
              tempCategoryObj.categoryData.push({
                categoryFormFields: categoryFormArr,
              })
            }
            for (let tempI = 0; tempI < tempCategoryObj.categoryData?.length; tempI++) {
              const { newObj }: any =
                (await getInputFormFieldValueByIndex(widgetDetailData, label, tempI)) || {}
              tempCategoryObj.categoryData[tempI].categoryFormFields[1] = newObj
            }
          } else {
            const { newObj, value }: any =
              (await getInputFormFieldValue(widgetDetailData, categoryLabel, fieldName)) || {}
            tempCategoryObj.categoryData[0].categoryFormFields = [formField]
            if (isValidObject(newObj) && value && fieldName === FORM_FIELD.BLITZ_ATTRIBUTES) {
              tempCategoryObj.categoryData[0].categoryFormFields[1] = newObj
            }
          }
        } else {
          const { value }: any =
            (await getInputFormFieldValue(widgetDetailData, categoryLabel, fieldName)) || {}
          tempCategoryObj.categoryData[0].categoryFormFields.push(formField)
          if (formField?.conditions?.length > 0 && value) {
            getNewFieldObjForOthersAttributeChange(value, formField).then((re) => {
              re?.forEach((item: any) => {
                tempCategoryObj.categoryData[0].categoryFormFields.push(item)
              })
            })
          }
        }
      }
    }
    finalPreparedInputFormArr.push(tempCategoryObj)
  }
  return {
    inputFormKeysWithAddMoreValue,
    finalPreparedInputFormArr,
  }
}

export const getInputFormFieldValueByIndex = async (
  widgetDetailData = {} as any,
  categoryLabel = '',
  index = -1,
) => {
  categoryLabel = toCapitalCase(categoryLabel)
  const { data: { query = {} } = {} } = widgetDetailData || {}
  const formFieldArrByCategory = query[categoryLabel]
  const { parameterId = '', attributeKey = '' } = formFieldArrByCategory?.[index] || {}
  const newObj: any = await getNewFieldObjForBlitzAttributeChange(parameterId)
  const fieldValue = getFieldValueObj(attributeKey, newObj)
  return fieldValue
}

export const getFieldValueByCategoryIndexnFieldName = async (
  widgetDetailData = {} as any,
  categoryLabel = '',
  fieldName = '',
  index = -1,
) => {
  const { data: { query = {} } = {} } = widgetDetailData || {}
  const formFieldArrByCategory = query[categoryLabel]
  const { rangeKey = '', attributeKey = '', attributeValue } = formFieldArrByCategory?.[index] || {}
  if (fieldName === FORM_FIELD.RANGE_BY) {
    const fieldValue = getFieldValueObj(rangeKey, {})
    return fieldValue
  }
  if (fieldName === FORM_FIELD.BLITZ_ATTRIBUTES) {
    const fieldValue = getFieldValueObj(attributeKey, {})
    return fieldValue
  }
  if (
    [CATEGORY_LABEL.SELECT_BY_BLITZ_ATTRIBUTE, CATEGORY_LABEL.EXCLUDE_BY_BLITZ_ATTRIBUTE].includes(
      categoryLabel,
    )
  ) {
    const fieldValue = getFieldValueObj(attributeValue, {})
    return fieldValue
  }
  const fieldValue = getFieldValueObj(formFieldArrByCategory?.[index]?.[fieldName], {})
  return fieldValue
}

export const getInputFormFieldValue = async (
  widgetDetailData = {} as any,
  categoryLabel = '',
  fieldNameFromInputForm = '',
) => {
  let fieldValue: any
  if (isValidObject(widgetDetailData) && isValidObject(widgetDetailData?.data?.query)) {
    const { data: { query = {} } = {} } = widgetDetailData || {}
    const formFieldByFieldName = query[fieldNameFromInputForm]
    if (isValidObject(formFieldByFieldName) && !Array.isArray(formFieldByFieldName)) {
      const { value }: any = formFieldByFieldName || {}
      if (typeof value === 'string') {
        fieldValue = getFieldValueObj(value)
        return fieldValue
      }
    }
    if (formFieldByFieldName === undefined || Array.isArray(formFieldByFieldName)) {
      const formFieldArrByCategory = query[categoryLabel]
      if (Array.isArray(formFieldArrByCategory)) {
        for (
          let categoryFieldIndex = 0;
          categoryFieldIndex < formFieldArrByCategory.length;
          categoryFieldIndex++
        ) {
          const {
            parameterId = '',
            dataType,
            fieldName = '',
            value,
            attributeKey = '',
            attributeValue,
            rangeKey = '',
          } = formFieldArrByCategory[categoryFieldIndex] || {}
          if (fieldNameFromInputForm === fieldName) {
            if (
              fieldNameFromInputForm !== FORM_FIELD.RANGE_BY &&
              fieldNameFromInputForm !== FORM_FIELD.BLITZ_ATTRIBUTES
            ) {
              if (typeof value === 'object' && Array.isArray(value)) {
                fieldValue = getFieldValueObj(value)
                break
              }
              if (typeof value === 'string' && dataType === 'Number' && !Array.isArray(value)) {
                fieldValue = getFieldValueObj(value)
                break
              }
              if (typeof value === 'string' && !Array.isArray(value)) {
                fieldValue = getFieldValueObj(value)
                break
              }
            }
            if (fieldNameFromInputForm === FORM_FIELD.BLITZ_ATTRIBUTES) {
              if (parameterId) {
                const newObj: any = await getNewFieldObjForBlitzAttributeChange(parameterId)
                fieldValue = getFieldValueObj(attributeKey, newObj)
              } else {
                fieldValue = getFieldValueObj(attributeKey)
              }
              break
            }
            if (fieldNameFromInputForm === FORM_FIELD.RANGE_BY) {
              fieldValue = getFieldValueObj(rangeKey)
              break
            }
          }
          if (categoryLabel === CATEGORY_LABEL.RANGE_BY_BLITZ_ATTRIBUTE) {
            const firstObj = formFieldArrByCategory[categoryFieldIndex]
            fieldValue = {
              value: firstObj[fieldNameFromInputForm],
              newObj: {},
            }
            break
          }
          if (
            [
              CATEGORY_LABEL.SELECT_BY_BLITZ_ATTRIBUTE,
              CATEGORY_LABEL.EXCLUDE_BY_BLITZ_ATTRIBUTE,
            ].includes(categoryLabel)
          ) {
            if (attributeKey === fieldNameFromInputForm) {
              fieldValue = getFieldValueObj(attributeValue)
              break
            }
          }
        }
      }
    }
  }
  return fieldValue
}

export const getNewFieldObjForOthersAttributeChange = async (value: any, mainField?: any) => {
  const { conditions } = mainField
  const newObj: any = []
  if (mainField) {
    const newField = conditions.find((item: any) => item.parameterValue === value)
    if (newField && newField?.parameterValue && newField?.dependentParameters?.length > 0) {
      for (let i = 0; i < newField?.dependentParameters?.length; i++) {
        const dependentParameters = newField?.dependentParameters[i]
        const formField: any = createDeepCopy(dependentParameters)

        const { parameterId: { master = '' } = {} } = formField || {}

        const splittedUrl = master.split('/')
        const keyName = `keyName=${splittedUrl[splittedUrl.length - 1]}`
        const res = await store.dispatch(getMasterByKey(keyName) as any)

        if (res?.payload?.responseCode === 200) {
          if (formField.parameterId) {
            formField.options = res.payload.data
            formField.parameter = formField.parameterId
            formField.parameterRepositoryId = formField.parameter._id
            formField.parameter.fieldName = dashStrToCapitalCase(formField.parameter.fieldName)
          }

          newObj.push({
            required: 'No',
            grid: 6,
            validations: [],
            conditions: [],
            parameterRepositoryId: formField.parameter._id,
            options: res.payload.data || [],
            parameter: formField.parameterId,
          })
        }
      }
    }
  }
  return newObj
}

export const getNewFieldObjForBlitzAttributeChange = async (additionalField: any) => {
  let newObj = {}

  if (additionalField) {
    const { payload: { responseCode = 0, data = {} } = {} }: any = await store.dispatch(
      getParameterRepositoryDetail(
        typeof additionalField === 'object' ? additionalField._id : additionalField,
      ) as any,
    )
    if (responseCode === 200) {
      const { master = '', fieldType = '' } = data || {}
      if (master && fieldType?.toLowerCase() === 'select') {
        const masterUrl = getCorrectMasterAPIUrl(master)
        const masterRes = await store.dispatch(getAutomatedMastersData({ url: masterUrl }) as any)
        if (masterRes?.payload?.data?.responseCode === 200) {
          newObj = {
            required: 'No',
            grid: 6,
            validations: [],
            conditions: [],
            parameterRepositoryId: additionalField,
            options: masterRes?.payload?.data?.data || [],
            parameter: data,
          }
        }
      }
      if (!master && fieldType?.toLowerCase() !== 'select') {
        newObj = {
          required: 'No',
          grid: 6,
          validations: [],
          conditions: [],
          parameterRepositoryId: additionalField,
          parameter: data,
        }
      }
    }
  }
  return newObj
}

const getFieldValueObj = (fieldValue: any, newObj = {}) => {
  return {
    value: fieldValue,
    newObj: isValidObject(newObj) ? newObj : {},
  }
}

export const convertURL = (url: string) => {
  return `${CONSTANTS.CLOUDFRONT_BASE_URL}${url.slice(CONSTANTS.S3_BUCKET_BASE_URL?.length)}`
}
export function getKeyByType(item: any, type: 'id' | 'title'): string {
  if (!item || typeof item !== 'object') {
    return ''
  }
  const keysMap: { [key: string]: string[] } = {
    title: ['assetTitle', 'widgetTitle', 'containerTitle', 'siteNavigationTitle', 'title'],
    id: ['assetId', 'widgetId', 'containerId', 'contentId', 'siteNavigationId', 'id'],
  }

  const keysToCheck = keysMap[type]
  if (!keysToCheck) {
    return ''
  }

  for (const key of keysToCheck) {
    if (item[key]) {
      return key
    }
  }
  return ''
}
export const getModifiedData = (data: any[], reason?: string) => {
  // Check if data is already modified and return it as-is
  const isPayload = reason === 'payload'
  if (data.every((item) => item.id || item.contentId) && !isPayload) {
    return data
  }
  return (
    data?.map((item: any, index: number) => {
      const idKey = getKeyByType(item, 'id')
      const titleKey = getKeyByType(item, 'title')
      const id = item[idKey]
      const title = isPayload ? '' : item[titleKey] || item.title || ''
      const type =
        item?.type && (item.type === 'widget' || item.type === 'asset' || item.type === 'container')
          ? item?.type?.toLowerCase()
          : idKey.replace('Id', '')
      const module = isPayload ? type : undefined
      const position = index + 1
      return isPayload ? { contentId: `${id}`, module, position } : { id, title, type, position }
    }) || []
  )
}
export const prepareDefaultDataObject = async (
  finalPreparedInputFormArr = [],
  widgetDetailData = {},
) => {
  let defaultDataTempObj: any = {}
  for (let formDataIndex = 0; formDataIndex < finalPreparedInputFormArr?.length; formDataIndex++) {
    const {
      isCategoryHasAddMore = false,
      categoryLabel = '',
      categoryData = [],
    } = finalPreparedInputFormArr[formDataIndex]
    const categoryLabelKey = toCapitalCase(categoryLabel)
    const tempObj: any = {}
    if (isCategoryHasAddMore) {
      tempObj[categoryLabelKey] = []
    }
    for (let categoryFormIndex = 0; categoryFormIndex < categoryData?.length; categoryFormIndex++) {
      const { categoryFormFields = [] } = categoryData[categoryFormIndex]
      for (let formFieldIndex = 0; formFieldIndex < categoryFormFields?.length; formFieldIndex++) {
        const formField = categoryFormFields[formFieldIndex]
        const { parameter: { fieldName = '' } = {} } = formField || {}
        let fieldValue: any
        if (
          [
            CATEGORY_LABEL.SELECT_BY_BLITZ_ATTRIBUTE,
            CATEGORY_LABEL.EXCLUDE_BY_BLITZ_ATTRIBUTE,
          ].includes(categoryLabelKey)
        ) {
          if (formFieldIndex === 0) {
            const { value }: any =
              (await getInputFormFieldValueByIndex(
                widgetDetailData,
                categoryLabel,
                categoryFormIndex,
              )) || {}
            fieldValue = value
          } else {
            const { value }: any =
              (await getInputFormFieldValue(widgetDetailData, categoryLabelKey, fieldName)) || {}
            fieldValue = value
          }
        } else if (categoryLabelKey === CATEGORY_LABEL.RANGE_BY_BLITZ_ATTRIBUTE) {
          const { value }: any =
            (await getFieldValueByCategoryIndexnFieldName(
              widgetDetailData,
              categoryLabelKey,
              fieldName,
              categoryFormIndex,
            )) || {}
          fieldValue = value
        } else {
          const { value }: any =
            (await getInputFormFieldValue(widgetDetailData, categoryLabelKey, fieldName)) || {}
          fieldValue = value
        }
        if (isCategoryHasAddMore) {
          tempObj[categoryLabelKey][categoryFormIndex] = {
            ...tempObj[categoryLabelKey][categoryFormIndex],
            [fieldName]: fieldValue,
          }
        } else {
          tempObj[fieldName] = fieldValue
        }
        defaultDataTempObj = { ...defaultDataTempObj, ...tempObj }
      }
    }
  }
  return defaultDataTempObj
}

export const debounceFunction = (func: any, timeout = 500) => {
  let timer: any
  return (...args: any[]) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export function cleanObject(obj: { [key: string]: any }) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined && v !== null))
}

// Helper function to extract dynamic keys
export function extractDynamicData(prefix: string, originalData: any) {
  const dynamicData: any[] = []
  const regex = new RegExp(`^${prefix}_\\d+_(.+)$`)
  Object.keys(originalData).forEach((key) => {
    const match = key.match(regex)
    if (match) {
      const index = parseInt(key.split('_')[1], 10)
      const attribute = match[1]
      if (!dynamicData[index]) dynamicData[index] = {}
      dynamicData[index][attribute] = originalData[key]
    }
  })
  return dynamicData.map(cleanObject).filter((item: any) => Object.keys(item).length === 2)
}

export const greatestCommonDivisor = (firstNumber: number, secondNumber: number): number => {
  return secondNumber === 0
    ? firstNumber
    : greatestCommonDivisor(secondNumber, firstNumber % secondNumber)
}

export const calculateAspectRatio = (width: number, height: number): string => {
  const divisor = greatestCommonDivisor(width, height)
  return `${width / divisor}:${height / divisor}`
}

export const isWithinTolerance = (
  width: number,
  height: number,
  validWidth: number,
  validHeight: number,
) => {
  // Calculate the 10% tolerance range for width
  const minWidth = validWidth * 0.9
  const maxWidth = validWidth * 1.1

  // Calculate the 10% tolerance range for height
  const minHeight = validHeight * 0.9
  const maxHeight = validHeight * 1.1

  // Check if both width and height are within the tolerance range
  const isWidthValid = width >= minWidth && width <= maxWidth
  const isHeightValid = height >= minHeight && height <= maxHeight

  return isWidthValid && isHeightValid
}

export const getFormattedValue = (data: any) => {
  let result = 'NA'
  if (data?.parameterId && data?.value) {
    switch (data?.parameterId?.fieldType?.toLowerCase()) {
      case 'time':
        result = getDateInFormat(data?.value, CONSTANTS.TIME_FORMAT_3)
        break

      case 'year':
        result = getDateInFormat(data?.value, CONSTANTS.DATE_YEAR_FORMAT)
        break

      case 'datetime':
        result = getDateInFormat(data?.value, CONSTANTS.DATE_FORMAT_TIME)
        break

      case 'date':
        result = getDateInFormat(data?.value, CONSTANTS.DATE_FORMAT)
        break

      default:
        result = data?.value
        break
    }
  }

  return result
}

export const getFormattedValueBasedOnType = (value: any, fieldType: string) => {
  let result = 'NA'
  if (fieldType && value) {
    switch (fieldType?.toLowerCase()) {
      case 'time':
        result = getDateInFormat(value, CONSTANTS.TIME_FORMAT_3)
        break

      case 'year':
        result = getDateInFormat(value, CONSTANTS.DATE_YEAR_FORMAT)
        break

      case 'datetime':
        result = getDateInFormat(value, CONSTANTS.DATE_FORMAT_TIME)
        break

      case 'date':
        result = getDateInFormat(value, CONSTANTS.DATE_FORMAT)
        break

      default:
        result = value
        break
    }
  }

  return result
}

export const openLinkInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

export const validateField = (value: string, validations: any[], fieldName: string) => {
  for (const validation of validations) {
    switch (validation.functionName) {
      case FieldFunctions.EQUALS: {
        // Add logic for equals validation
        break
      }
      case FieldFunctions.ALPHA: {
        const regex = new RegExp(validation.customValue)
        if (!regex.test(value)) {
          return `${fieldName} must be alphabetic`
        }
        break
      }
      case FieldFunctions.ALPHANUM: {
        const regex = /^[a-zA-Z0-9]+$/
        if (!regex.test(value)) {
          return `${fieldName} must be alphanumeric`
        }
        break
      }
      case FieldFunctions.ALNUM_WITH_SPECIAL_CHARS: {
        const regex = new RegExp(validation.customValue)
        if (!regex.test(value)) {
          return `${fieldName} must be alphanumeric with special characters`
        }
        break
      }
      case FieldFunctions.MAX:
        if (value.length > parseInt(validation.customValue, 10)) {
          return `${fieldName} should not exceed ${validation.customValue} characters.`
        }
        break
      case FieldFunctions.MIN: {
        if (value.length < parseInt(validation.customValue, 10)) {
          return `${fieldName} should be at least ${validation.customValue} characters.`
        }
        break
      }
      case FieldFunctions.PATTERN: {
        const regex = new RegExp(validation.customValue)
        if (!regex.test(value)) {
          return `${fieldName} is invalid.`
        }
        break
      }
      case FieldFunctions.URI: {
        const regex = new RegExp(
          '^(https?:\\/\\/|http:\\/\\/)?' + // protocol (http or https optional)
            '((([a-z0-9](?:[a-z0-9-]*[a-z0-9])?)\\.)+[a-z]{2,}|' + // domain name
            'localhost|' + // localhost
            '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // IP address
            '\\[?[a-f0-9]*:[a-f0-9:%.]*\\]?)' + // IPv6
            '(\\:\\d+)?(\\/[-a-z0-9+&@#/%=~_|$?!:.]*[a-z0-9+&@#/%=~_|$])?$', // path
          'i',
        )
        if (!regex.test(value)) {
          return `${fieldName} is invalid.`
        }
        break
      }
      case FieldFunctions.DATE_GREATER_THAN: {
        // Add logic for date greater than validation
        break
      }
      case FieldFunctions.DATE_LESS_THAN: {
        // Add logic for date less than validation
        break
      }
      case FieldFunctions.DATE_LESS_THAN_EQUALS: {
        // Add logic for date less than or equals validation
        break
      }
      case FieldFunctions.DATE_GREATER_THAN_EQUALS: {
        // Add logic for date greater than or equals validation
        break
      }
      case FieldFunctions.ISO: {
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?Z$/ // ISO date format
        if (!regex.test(value)) {
          return `${validation.displayName} is invalid.`
        }
        break
      }
      case FieldFunctions.VALIDATE_ASPECT_RATIO: {
        // Add logic for aspect ratio validation
        break
      }
      case FieldFunctions.VALIDATE_FILE_TYPE: {
        // Add logic for file type validation
        break
      }
      case FieldFunctions.VALIDATE_RESOLUTION: {
        // Add logic for resolution validation
        break
      }
      case FieldFunctions.COUNTRY_FOR_RATINGS: {
        // Add logic for country for ratings validation
        break
      }
      case FieldFunctions.LIMIT: {
        // Add logic for limit validation
        break
      }
      // Add more validation cases as needed
      default:
        break
    }
  }
  return true
}

const formatErrorMessage = (message: string) => {
  return message.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase())
}

export const setFormErrors = (errors: any, setError: any) => {
  let imageErrors = null
  errors.forEach((error: string) => {
    const errorData = error
      .split('"')
      .filter(Boolean)
      .filter((item: string) => item !== ' ' && item)
    const fieldNameData = errorData[0].split('.')
    let fieldName = ''
    const message = errorData[errorData.length - 1]

    if (fieldNameData.length > 1) {
      const lastElement = fieldNameData[fieldNameData.length - 1]
      const secondLastElement = fieldNameData[fieldNameData.length - 2]

      if (secondLastElement.includes('[') && secondLastElement.includes(']')) {
        fieldName = `${secondLastElement}.${lastElement}`
      } else {
        fieldName = lastElement
      }
    } else {
      fieldName = fieldNameData[0]
    }

    let formattedString = formatErrorMessage(fieldName)
    if (fieldName.includes('].')) {
      const splitString = fieldName.split('].')[1]
      formattedString = formatErrorMessage(splitString)
    }
    const errorMessage = formattedString + ' ' + message.trim()
    if (setError === 'images') {
      imageErrors = {
        fieldName,
        errorMessage,
      }
    } else {
      setError(fieldName, {
        type: 'manual',
        message: errorMessage,
      })
    }
  })

  if (imageErrors) {
    return imageErrors
  }
}
// Utility function to dynamically fetch ID, title, type, or other fields
export const getDynamicRowValue = (data: any, fieldType: string) => {
  // Define regular expressions for different field types
  const regexMappings: { [key: string]: RegExp } = {
    id: /^(assetId|widgetId|containerId|contentId|siteNavigationId|id)$/,
    title: /^(assetTitle|widgetTitle|containerTitle|contentTitle|siteNavigationTitle|title)$/,
    type: /^(assetType|widgetType|containerType|siteNavigationType|contentType|type)$/,
    // You can add more categories if needed
  }
  // If the fieldType exists in the mapping, use the corresponding regex
  const regex = regexMappings[fieldType.toLowerCase()]
  if (!regex) return ''
  // Iterate over the data's keys and return the matching value
  for (const key of Object.keys(data)) {
    if (regex.test(key) && data[key]) {
      return data[key]
    }
  }
  // Return an empty string if no match is found
  return ''
}

export const displayMultipleTitle = (data: IKeyValue[] | null | undefined): string => {
  if (!data) return ''
  const predicate = (item: IKeyValue | string) => (typeof item === 'object' ? item.value : item)
  return data.map(predicate).join(', ')
}

export const getFieldValues = (value: any, fieldType: string) => {
  if (!value) return 'NA'

  if (Array.isArray(value)) {
    if (value.length === 0) return 'NA'
    return displayMultipleTitle(value)
  }

  return getFormattedValueBasedOnType(value, fieldType)
}

export const handleMarkAsDoneButton = async (dispatch: AppDispatch, requestedData: any) => {
  dispatch(setLoader(true))
  try {
    const result: any = await mainApiService(requestedData)
    if (result?.responseCode === 200) {
      dispatch(showAlert([true, result.message, 'success']))
      return result
    } else {
      handleApiError(dispatch, result)
      return 'error'
    }
  } catch (error) {
    dispatch(showAlert([true, 'An error occurred while processing your request.', 'error']))
  } finally {
    dispatch(setLoader(false))
  }
}

export const findKeyByWords = (sentence: any) => {
  const words = sentence.toUpperCase().split(' ')
  const key = CONSTANTS.TIPS_KEY.filter((item) =>
    words.every((word: any) => item.value.includes(word)),
  ).map((item) => item.key)

  return `key=${key?.[0]}`
}

export const getNavigationLink = (item: any) => {
  const { moduleName, contentMongoId } = item

  switch (moduleName) {
    case 'Container':
      return `/manage-container/view/${contentMongoId}`

    case 'Site Navigation':
      return `/site-navigation/view/${contentMongoId}`

    case 'Widget':
      return `/manage-widget/view/${contentMongoId}`

    default:
      return '/home'
  }
}

export const getDefaultFieldTitleValues = (data: any, parameterRef: any) => {
  return {
    title: data?.parameterName,
    value: 'NA',
    updatedValue: '',
    fieldName: data?.fieldName,
    parameterRef,
  }
}
export const getConditionalFieldValues = (data: any, prefilledData: any) => {
  const conditionalFieldToShow: any[] = []
  if (data?.conditions?.length > 0) {
    data.conditions.forEach((condition: any) => {
      const isItemValueInDetail = checkIsItemValueInDetail(
        prefilledData?.parameters,
        data?.parameter?.fieldName,
        condition?.parameterValue,
      )
      if (isItemValueInDetail && condition?.dependentParameters?.length > 0) {
        condition.dependentParameters.forEach((dependentParameter: any) => {
          conditionalFieldToShow.push(
            getDefaultFieldTitleValues(dependentParameter?.parameterId, []),
          )
        })
      }
    })
  }

  return conditionalFieldToShow
}

export const getPayloadFieldValue = (value: any, type: string) => {
  const dateAndTimeTypes = ['date', 'time', 'datetime', 'year']
  if (value && Array.isArray(value) && value.length === 0) return []
  if (value && dateAndTimeTypes.includes(type.toLowerCase()) && isNaN(new Date(value).getTime()))
    return undefined
  return value
}

export const getFieldBorderBottomValue = (data: any[], index: number) => {
  if (data?.length < 2) return 'none'
  if (index === data.length - 1) return 'none'
  return '1px solid rgba(0, 0, 0, 0.16)'
}

export const findPrefilledValue = (
  fromListToFind: any[],
  fieldNameOrValue: string,
  type: 'parameters' | 'conditions' = 'parameters',
) => {
  const predicate =
    type === 'conditions'
      ? (item: any) => fieldNameOrValue === item?.parameterValue
      : (item: any) => fieldNameOrValue === item?.parameterId?.fieldName

  return fromListToFind.find(predicate)
}

export const getConditionalFieldsToShow = (formData: any[], formFieldValues: any) => {
  const conditionalFieldsToShow: string[] = []
  formData?.forEach((form: any) => {
    form?.tabContent?.formData.forEach((item: any) => {
      if (item?.form?.length > 0) {
        item.form.forEach((formItem: any) => {
          const { conditions, parameter } = formItem
          if (conditions?.length > 0) {
            const currentFieldValue = formFieldValues?.[toCamelCase(parameter.fieldName)]
            const isCurrentValueAvailableInCondition = findPrefilledValue(
              conditions,
              currentFieldValue,
              'conditions',
            )
            if (isCurrentValueAvailableInCondition) {
              conditionalFieldsToShow.push(parameter.fieldName)
            }
          }
        })
      }
    })
  })
  return conditionalFieldsToShow
}
export const getPageName = (pageType: any) => {
  switch (pageType) {
    case 'widget':
      return { pageName: 'Manage Widget', pageLink: 'manage-widget' }
    case 'segment':
      return { pageName: 'Manage Segment', pageLink: 'manage-segment' }
    case 'site-navigation':
      return { pageName: 'Site Navigation', pageLink: 'site-navigation' }
    case 'container':
      return { pageName: 'Manage Conatiner', pageLink: 'manage-container' }
    default:
      return ''
  }
}

export const getHeaderData = (detailData: any, module: any) => {
  let headerData = []
  const behaviorFormData = detailData?.behaviour?.parameters
  let segment: any
  if (behaviorFormData?.length > 0) {
    const segmentData = behaviorFormData.find(
      (item: any) => item?.parameterId?.fieldName === 'segment',
    )

    segment = segmentData?.value || 'NA'
  }
  switch (module) {
    case 'container': {
      const getAssetTypeValues = () => {
        const assetType = detailData?.data?.parameters?.find(
          (item: any) => item?.parameterId?.fieldName === 'asset_types',
        )

        return assetType?.value || 'NA'
      }

      const ContainerHeader: { [key: string]: any } = {
        containerId: detailData?.containerId || 'NA',
        assetType: getAssetTypeValues(),
        createdOn: detailData?.createdAt || 'NA',
        segment: segment,
        lastUpdatedOn: detailData?.updatedAt || 'NA',
        lastFeaturedOn: detailData?.lastFeaturedDate || 'NA',
      }

      let keyToPresentInHeader: { key: string; title: string; type?: string }[] = []
      const layout =
        detailData?.layout?.toLowerCase() as keyof typeof CONSTANTS.CONTAINER_LAYOUT_HEADER_INFO
      if (
        layout &&
        Object.prototype.hasOwnProperty.call(CONSTANTS.CONTAINER_LAYOUT_HEADER_INFO, layout)
      ) {
        keyToPresentInHeader = CONSTANTS.CONTAINER_LAYOUT_HEADER_INFO[layout]
      } else {
        keyToPresentInHeader = CONSTANTS.CONTAINER_LAYOUT_HEADER_INFO['other layout']
      }
      for (const item of keyToPresentInHeader) {
        const updatedInfo: any = {
          title: item.title,
          value: ContainerHeader[item.key],
        }
        if (Object.prototype.hasOwnProperty.call(item, 'type')) {
          updatedInfo.type = item.type
        }
        headerData.push(updatedInfo)
      }

      break
    }

    case 'widget': {
      headerData = [
        {
          title: 'Segment',
          value: segment,
        },
        {
          title: 'Data Engine',
          value: detailData?.data?.engine
            ? `${detailData?.data?.engine.charAt(0).toUpperCase()}${detailData?.data?.engine
                .slice(1)
                .toLowerCase()}`
            : 'NA',
        },
        {
          title: 'Widget Id',
          value: detailData?.widgetId,
        },
        { title: 'Created on', value: detailData?.createdAt || 'NA', type: 'date' },
        {
          title: 'Last Updated on',
          value: detailData?.updatedAt || 'NA',
          type: 'date',
        },
        {
          title: 'Last Featured on',
          value: detailData?.lastFeaturedDate || 'NA',
          type: 'date',
        },
      ]
      break
    }

    case 'site-navigation': {
      headerData = [
        {
          title: 'Widget Id',
          value: detailData?.siteNavigationId,
        },
        {
          title: 'Segment',
          value: segment,
        },
        {
          title: 'Last Featured on',
          value: detailData?.lastFeaturedDate || 'NA',
          type: 'date',
        },
        { title: 'Created on', value: detailData?.createdAt || 'NA', type: 'date' },
        {
          title: 'Last Updated on',
          value: detailData?.updatedAt || 'NA',
          type: 'date',
        },
      ]
      break
    }

    case 'segment': {
      headerData = [
        { title: 'Created on', value: detailData?.createdAt || 'NA', type: 'date' },
        {
          title: 'Last Updated on',
          value: detailData?.updatedAt || 'NA',
          type: 'date',
        },
      ]
      break
    }

    default:
      break
  }

  return headerData
}

export const checkIsFormValid = (requiredFields: string[], fieldsValueData: any) => {
  return requiredFields?.every((field) => {
    const fieldValue = fieldsValueData?.[field]
    if (fieldValue && typeof fieldValue === 'object') {
      if (Array.isArray(fieldValue)) return fieldValue.length > 0
      return Object.keys(fieldValue).length > 0
    }
    return !!fieldValue
  })
}

export const checkIsItemValueInDetail = (dataToCheck: any[], fieldName: string, value: any) => {
  return dataToCheck?.find(
    (parameter: any) =>
      parameter?.parameterId?.fieldName === fieldName && parameter?.value === value,
  )
}

export const isImageTab = (item: any) => {
  return item?.key?.toLowerCase().includes('image')
}
export const isFieldHasValue = (value: any): boolean => {
  if (!value) return false
  if (typeof value === 'object') {
    return Array.isArray(value) ? value.length > 0 : Object.keys(value).length > 0
  }
  return value.trim() !== ''
}

export const checkFieldValue = (fieldName: string, prefilledData: any[]): boolean => {
  const fieldData = prefilledData?.find(
    (data: any) => data?.parameterId?.fieldName === fieldName || data?.fieldName === fieldName,
  )
  return fieldData ? isFieldHasValue(fieldData?.value) : false
}

export const checkComplexFieldValue = (fieldName: any, prefilledData: any[]): boolean => {
  return Object.keys(fieldName).every((key) => {
    const requiredFieldsValue = fieldName[key]
    const complexPrefilledValue = prefilledData?.find(
      (data: any) => data?.parameterId?.fieldName === key || data?.fieldName === key,
    )

    if (
      !complexPrefilledValue ||
      !Array.isArray(complexPrefilledValue?.parameterRef) ||
      (Array.isArray(complexPrefilledValue?.parameterRef) &&
        complexPrefilledValue?.parameterRef.length === 0)
    ) {
      return false
    }

    return requiredFieldsValue.every((field: any) => {
      return complexPrefilledValue?.parameterRef.every((dataArray: any[]) => {
        const paramRefValue = dataArray?.find(
          (item: any) => item?.parameterId?.fieldName === field || item?.fieldName === field,
        )
        return isFieldHasValue(paramRefValue?.value)
      })
    })
  })
}

export const areAllRequiredFieldsPresent = (
  requiredFields: any[],
  prefilledData: any[],
): boolean => {
  return requiredFields?.every((fieldName: any) => {
    return typeof fieldName === 'string'
      ? checkFieldValue(fieldName, prefilledData)
      : checkComplexFieldValue(fieldName, prefilledData)
  })
}

export const getRequiredFields = (formData: any) => {
  const requiredFieldsList: any[] = []
  formData?.forEach((formItem: any) => {
    if (formItem?.parameter?.fieldType?.toLowerCase() === 'complextype') {
      const complexRequiredFields: string[] = []
      formItem?.parameterRef?.forEach((subItem: any) => {
        if (isFieldRequired(subItem?.required) && subItem?.parameter?.fieldName)
          complexRequiredFields.push(subItem.parameter.fieldName)
      })
      if (complexRequiredFields.length > 0)
        requiredFieldsList.push({ [formItem?.parameter?.fieldName]: complexRequiredFields })
    } else if (isFieldRequired(formItem?.required) && formItem?.parameter?.fieldName)
      requiredFieldsList.push(formItem.parameter.fieldName)
  })

  return requiredFieldsList
}

export const extractUserInfo = (item: any) => {
  const userInfo: any = {}
  if (item?.updatedBy?.userEmail) {
    userInfo.updatedBy = item.updatedBy.userEmail
  }
  if (item?.createdBy?.userEmail) {
    userInfo.createdBy = item.createdBy.userEmail
  }
  return userInfo
}

export const checkComplexType = (data: any, selectedTabs?: any) => {
  if (!data) return false
  return data?.tabs
    ?.find((tab: any) => tab?.key === selectedTabs?.key)
    ?.tabContent?.formData?.[0]?.form?.some(
      (form: any) => form?.parameter?.fieldType?.toLowerCase() === 'complextype',
    )
}

export const isConditionMet = (data: any, parameter: any, condition: any) => {
  if (!data || !parameter || !condition) return false
  return (
    data?.[toCamelCase(parameter?.fieldName)]?.trim() === condition?.parameterValue &&
    condition?.dependentParameters?.length > 0
  )
}

export const extractDependentParameters = (data: any, parameter: any, conditions: any[]) => {
  const parameters: any[] = []
  if (conditions.length === 0 || !data || !parameter) return parameters
  for (const condition of conditions) {
    if (isConditionMet(data, parameter, condition)) {
      const { dependentParameters } = condition
      dependentParameters.forEach((parameterData: any) => {
        const { parameterId } = parameterData
        const extractedValue = data[toCamelCase(parameterId?.fieldName)]
        parameters.push({
          parameterId: parameterId?._id,
          fieldName: parameterId?.fieldName,
          value: getPayloadFieldValue(extractedValue, parameterId?.fieldType),
        })
      })
    }
  }
  return parameters
}

export const transformDynamicObjectToArray = (data: Record<string, any>) => {
  const groupedData: Record<string, any> = {}

  // Iterate over the keys of the object
  Object.keys(data).forEach((key) => {
    // Use a regular expression to match keys with the pattern
    const match = key.match(/(.*?)_(\d+)_(.*)/)
    if (match) {
      const [_, base, index, subKey] = match
      // Initialize the base key group if it doesn't exist
      if (!groupedData[base]) {
        groupedData[base] = []
      }

      // Ensure the result array for the base key has an object for the current index
      if (!groupedData[base][+index - 1]) {
        groupedData[base][+index - 1] = {}
      }

      // Assign the value to the correct property
      groupedData[base][+index - 1][subKey] = data[key]
    }
  })

  return groupedData
}

export const handleComplexType = (parameter: any, formItem: any, data: any) => {
  if (!parameter || !formItem || !data) return null
  const transformedArr: any = transformDynamicObjectToArray(data)
  const fieldValue = transformedArr?.[parameter?.fieldName] || []

  return {
    parameterId: parameter?._id,
    fieldName: parameter?.fieldName,
    value: '',
    parameterRef: fieldValue
      ? fieldValue
          ?.map(
            (subItem: any) =>
              formItem?.parameterRef.map((subParameter: any) => {
                const subParamField = toCamelCase(subParameter?.parameter?.fieldName)
                return {
                  parameterId: subParameter?.parameter?._id,
                  fieldName: subParameter?.parameter?.fieldName,
                  value:
                    Array.isArray(subItem?.[subParamField]) && subItem[subParamField].length === 0
                      ? ''
                      : subItem[subParamField],
                  _id: subParameter?.parameter?._id,
                }
              }),
          )
          .filter(Boolean)
      : [],
    _id: parameter?._id,
  }
}

export const handleSimpleType = (parameter: any, data: any) => {
  if (!parameter || !data) return null
  return {
    parameterId: parameter?._id,
    fieldName: parameter?.fieldName,
    value: getPayloadFieldValue(data?.[toCamelCase(parameter?.fieldName)], parameter?.fieldType),
  }
}

export const getImagePrefilledData = (prefilledFormData: any) => {
  if (
    !prefilledFormData ||
    !Array.isArray(prefilledFormData) ||
    (Array.isArray(prefilledFormData) && prefilledFormData.length === 0)
  )
    return []
  const imageData = prefilledFormData?.filter(
    (item: any) => item?.parameterId?.dataType?.toLowerCase()?.includes('image_type'),
  )
  if (!imageData || (imageData && imageData?.length === 0)) return []

  return imageData.map((item: any) => ({
    ...item,
    parameterId: item?.parameterId?._id,
  }))
}
export const getParameterDetails = (data: any, formData: any, prefilledFormData: any) => {
  const parameters: any[] = []
  if (!data || !formData) return parameters
  const formTabsList = formData?.tabs

  if (formTabsList.length > 0) {
    formTabsList.forEach((item: any) => {
      if (Object.hasOwn(item, 'tabContent') && !isImageTab(item)) {
        const formData = getFormData(item)
        parameters.push(...extractParametersFromFormData(formData, data))
      }
    })
  }
  parameters.push(...getImagePrefilledData(prefilledFormData))
  return parameters
}

export const extractParametersFromFormData = (formData: any, data: any) => {
  const parameters: any[] = []
  if (!formData || !data) return parameters
  const extractedParameters = extractParameters(formData, data)
  parameters.push(...extractedParameters)

  formData?.[0]?.form?.forEach((formItem: any) => {
    const { parameter, conditions } = formItem
    if (parameter?.fieldType?.toLowerCase() !== 'complextype') {
      parameters.push(...extractDependentParameters(data, parameter, conditions))
    }
  })

  return parameters
}

export const getFormData = (item: any) => {
  if (!item?.tabContent) return []
  return item?.tabContent?.formData?.filter((item: any) => item.label === '')
}

export const extractParameters = (formData: any, data: any) => {
  if (!formData || !data) return []
  return (
    formData?.[0]?.form?.flatMap((formItem: any) => {
      const { parameter } = formItem
      if (parameter?.fieldType?.toLowerCase() === 'complextype') {
        return handleComplexType(parameter, formItem, data)
      } else {
        return handleSimpleType(parameter, data)
      }
    }) || []
  )
}

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return 'NA'
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const getSanitizedValue = (value: any) => {
  return DOMPurify.sanitize(value)
}

export const getSelectedDefinitionAttributeDetails = (selectedAttri: any, attributes: any) => {
  const matchingObjects = []
  for (const selectedObj of selectedAttri) {
    const matchingDataObject = attributes?.find((ele: any) => ele?._id === selectedObj)

    if (matchingDataObject) {
      matchingObjects?.push(matchingDataObject)
    }
  }

  return matchingObjects?.length ? matchingObjects : selectedAttri
}

export const getDefinitionFilteredParameters = (
  accordianName: string,
  selectedParameters: any,
  parametersList: any,
) => {
  const selectedParametersByOther = Object.keys(selectedParameters).reduce(
    (acc: string[], key: string) => {
      if (key !== accordianName) {
        selectedParameters[key]?.tabs?.forEach((item: any) => {
          item.parameters.forEach((param: any) => {
            acc.push(param._id)
          })
        })
      }
      selectedParameters[key]?.tabs?.forEach((item: any) => {
        item.parameters.forEach((param: any) => {
          if (!param.parameterRef) {
            parametersList?.map((parameter: any) => {
              if (param._id === item?._id) {
                parameter?.parameterRef?.forEach((element: any) => {
                  acc?.push(element)
                })
              }
            })
          } else {
            if (Array.isArray(param?.parameterRef)) {
              param?.parameterRef?.forEach((ref: any) => {
                if (typeof ref === 'string') {
                  acc.push(ref)
                } else if (ref?._id) {
                  acc?.push(ref?._id)
                }
              })
            }
          }
        })
      })

      return acc
    },
    [],
  )
  return parametersList?.filter((param: any) => !selectedParametersByOther?.includes(param?._id))
}

export const handleDefinitionParameterChange = (
  e: any,
  index: any,
  newValue: any,
  accordianName: any,
  reason: any,
  tabName: any,
  tabs: any,
  accordiansContent: any,
  setAccordiansContent: any,
  selectedParameters: any,
  setSelectedParameters: any,
  setSelectedParametersList: any,
  dispatch: any,
  parametersList: any,
) => {
  if (reason === CONSTANTS?.AUTOCOMPLETE_EVENT_TYPES.INPUT) {
    return
  }
  let requiredArrayForAPIPayload: any = []
  if (reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.CLEAR) {
    const copyAccordiansContent = JSON.parse(JSON.stringify(accordiansContent))
    copyAccordiansContent[index].tabs = copyAccordiansContent?.[index]?.tabs?.map((item: any) => {
      if (item?.key === tabName) {
        return { ...item, parameters: [] }
      } else {
        return item
      }
    })
    setAccordiansContent(copyAccordiansContent)
  }
  if (
    reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.SELECTOPTION ||
    reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.REMOVEOPTION
  ) {
    const copyAccordiansContent = JSON.parse(JSON.stringify(accordiansContent))
    let copyNewValue = [...newValue]
    copyNewValue = copyNewValue?.map((item: any) => {
      if (item?.fieldType?.toLowerCase() === 'complextype' && item?.parameterRef?.length) {
        return {
          ...item,
          parameterRef: getSelectedDefinitionAttributeDetails(
            item?.parameterRef,
            getDefinitionFilteredParameters(
              accordianName?.toLowerCase(),
              selectedParameters,
              parametersList,
            ),
          ),
        }
      } else {
        return item
      }
    })
    copyAccordiansContent[index].tabs = copyAccordiansContent?.[index]?.tabs?.map((item: any) => {
      if (item?.key === tabName) {
        return { ...item, parameters: copyNewValue }
      } else {
        return item
      }
    })
    setAccordiansContent(copyAccordiansContent)

    const updatedSelectedParameters = { ...selectedParameters }
    updatedSelectedParameters[accordianName] = newValue
    setSelectedParameters(updatedSelectedParameters)
    requiredArrayForAPIPayload = newValue?.map((item: any) => {
      const parameterObj: any = {
        parameterRepositoryId: item?._id,
        required: 'No',
      }
      // Check for COMPLEXTYPE and append additional attributes if necessary
      if (item?.fieldType?.toLowerCase() === 'complextype' && item?.parameterRef?.length) {
        parameterObj.parameterRef = getSelectedDefinitionAttributeDetails(
          item.parameterRef,
          getDefinitionFilteredParameters(
            accordianName?.toLowerCase(),
            selectedParameters,
            parametersList,
          ),
        )
      }

      if (parameterObj?.parameterRef) {
        parameterObj.parameterRef = parameterObj?.parameterRef?.map((item: any) => {
          return {
            parameterRepositoryId: item?._id,
            required: 'No',
          }
        })
      }
      return parameterObj
    })
  }
  const obj: any = {}
  const newPayload = tabs?.map((item: any) => {
    if (item.key === tabName) {
      return {
        ...item,
        parameters: requiredArrayForAPIPayload,
      }
    }
    const newParameters = item?.parameters?.map((item: any) => {
      const parameterObj: any = {
        parameterRepositoryId: item?._id,
        required: 'No',
      }
      // Check for COMPLEXTYPE and append additional attributes if necessary
      if (item?.fieldType?.toLowerCase() === 'complextype' && item?.parameterRef?.length) {
        parameterObj.parameterRef = getSelectedDefinitionAttributeDetails(
          item?.parameterRef,
          getDefinitionFilteredParameters(
            accordianName?.toLowerCase(),
            selectedParameters,
            parametersList,
          ),
        )
      }

      if (parameterObj?.parameterRef) {
        parameterObj.parameterRef = parameterObj.parameterRef?.map((item: any) => {
          return {
            parameterRepositoryId: item?._id,
            required: 'No',
          }
        })
      }
      return parameterObj
    })
    return { ...item, parameters: newParameters }
  })
  obj[accordianName] = { tabs: newPayload }

  dispatch(setSelectedParametersList(obj) as any)
}

export function transformArray(tabsData?: any) {
  const newObj = {
    tabs: tabsData?.tabs?.map((item: any) => {
      let inputArray = item?.parameters

      if (!Array.isArray(inputArray)) {
        // Return an empty array if inputArray is not valid
        return []
      }
      inputArray = inputArray?.map((item) => {
        if (
          !item ||
          !item?.parameterRepositoryId ||
          typeof item?.parameterRepositoryId?._id !== 'string'
        ) {
          // Skip invalid items by returning a default object or handle as per requirements
          return {
            parameterRepositoryId: '',
            required: 'No',
            conditions: [],
            validations: [],
          }
        }

        const parameterRef =
          item?.parameterRef?.map((refItem: any) => ({
            ...refItem,
            parameterRepositoryId: refItem?.parameterRepositoryId?._id,
          })) || []
        const { _id: parameterRepositoryId } = item.parameterRepositoryId
        const { required = 'No', conditions = [], validations = [] } = item
        return { parameterRepositoryId, required, conditions, validations, parameterRef }
      })

      return {
        ...item,
        parameters: inputArray,
      }
    }),
  }

  return newObj
}

export const setAssetCategoryDefinitionData = (
  forFieldName: string,
  isReset: boolean,
  setValue: any,
  localDispatch: any,
  data: any,
  assetCategoryKeysArray: any,
) => {
  if (forFieldName === 'assetConfig.contentCategory') {
    !isReset && setValue('assetConfig.assetCategory', [])
    !isReset && setValue('assetConfig.assetType', [])
    localDispatch({ type: 'SET_ASSET_OPTIONS', payload: data?.assetCategories || [] })
    return
  }
  if (forFieldName === 'assetConfig.assetCategory') {
    !isReset && setValue('assetConfig.assetType', [])
    !isReset && setValue('assetConfig.assetCategory', assetCategoryKeysArray)
    localDispatch({ type: 'SET_TYPE_OPTIONS', payload: data?.assetTypes || [] })
    return
  }
}

export const updateDefinitionConfigDefaults = (configData: any) => {
  let updatedConfig = { ...configData }

  if (!updatedConfig?.layoutInfo?.length) {
    updatedConfig = {
      ...updatedConfig,
      layoutInfo: [{ type: '', layouts: [] }],
    }
  }

  if (!updatedConfig?.isMultiSelection) {
    updatedConfig = {
      ...updatedConfig,
      isMultiSelection: 'yes',
    }
  }

  if (!updatedConfig?.isFilterRequired) {
    updatedConfig = {
      ...updatedConfig,
      isFilterRequired: 'no',
    }
  }

  return updatedConfig
}

export const assetFieldsReducer = (
  state: typeof AssetFieldsIntialState,
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case 'SET_CATEGORY_OPTIONS':
      return { ...state, categoryOptions: action.payload }
    case 'SET_ASSET_OPTIONS':
      return { ...state, assetOptions: action.payload }
    case 'SET_TYPE_OPTIONS':
      return { ...state, typeOptions: action.payload }
    default:
      return state
  }
}

export const getStringArray = (array: string[] | undefined): string => {
  if (array && array.length > 0) {
    return array.join(', ')
  } else {
    return ' '
  }
}

export const getItemParamters = (item: any, parametersList: any, acc: any) =>
  item.parameters.forEach((param: any) => {
    if (!param.parameterRef) {
      parametersList?.map((parameter: any) => {
        if (param._id === item?._id) {
          parameter?.parameterRef?.forEach((element: any) => {
            acc?.push(element)
          })
        }
      })
    } else {
      if (Array.isArray(param?.parameterRef)) {
        param?.parameterRef?.forEach((ref: any) => {
          if (typeof ref === 'string') {
            acc.push(ref)
          } else if (ref?._id) {
            acc?.push(ref?._id)
          }
        })
      }
    }
  })
export const handleLastUpdateAPI = async (payload: any) => {
  const result = await mainApiService(WIDGET_MANAGEMENT_CRUD.CHECK_LAST_UPDATED(payload))
  return result
}

const getDetailByIdType = (idType: 'container' | 'widget' | 'siteNavigation') => {
  switch (idType) {
    case 'container':
      return store.getState().manageContainer.containerDetails
    case 'widget':
      return store.getState().manageWidget.widgetDetails
    case 'siteNavigation':
      return store.getState().siteNavigation.details
    default:
      throw new Error(`Unknown ID type: ${idType}`)
  }
}

export const checkLastUpdatedPayloadFunction = (
  idType: 'container' | 'widget' | 'siteNavigation',
) => {
  const detailData = getDetailByIdType(idType)
  if (!detailData) {
    return {
      responseCode: 404,
      errorMessage: `No data found for ${idType} details.`,
    }
  }

  return {
    contentId: detailData.containerId || detailData.widgetId || detailData.siteNavigationId,
    contentDocId: detailData._id,
    module: idType,
    lastEditDate: detailData.updatedAt,
  }
}

export const compareData = (array1: any, array2: any) => {
  const normalizeData = (data: any) => {
    if (Object.keys(data).length === 0) return null

    const parameters = data?.parameters || data
    const assetInfo = data?.assetInfo || []
    const widgetInfo = data?.widgetInfo || []

    return {
      parameters: parameters?.map((param: any) => ({
        parameterId:
          typeof param.parameterId === 'object' ? param.parameterId._id : param.parameterId,
        fieldName: param.fieldName,
        value: param.value,
      })),
      assetInfo,
      widgetInfo,
    }
  }

  const normalizedArray1 = normalizeData(array1)
  const normalizedArray2 = normalizeData(array2)

  if (normalizedArray1 && normalizedArray2) {
    const map1 = normalizedArray1?.parameters?.reduce((acc: any, item: any) => {
      acc[item.fieldName] = item.value
      return acc
    }, {})

    const map2 = normalizedArray2?.parameters?.reduce((acc: any, item: any) => {
      acc[item.fieldName] = item.value
      return acc
    }, {})

    const allFields = new Set([...Object.keys(map1), ...Object.keys(map2)])

    for (const field of allFields) {
      const value1 = map1[field]
      const value2 = map2[field]

      if (!(field in map1)) {
        return false
      }

      if (!(field in map2)) {
        if (value1 !== '') {
          return false
        }
        continue
      }

      if (value1 === '' && value2) {
        return false
      }

      if (value1 !== '') {
        if (
          (value1 === '' && Array.isArray(value2) && value2.length === 0) ||
          (Array.isArray(value1) && value1.length === 0 && value2 === '')
        ) {
          continue
        }

        if (!compareValues(value1, value2)) {
          return false
        }
      }
    }
    if (!compareValues(normalizedArray1.assetInfo, normalizedArray2.assetInfo)) {
      return false
    }

    if (!compareValues(normalizedArray1.widgetInfo, normalizedArray2.widgetInfo)) {
      return false
    }

    return true
  }
  return false
}

export const compareNestedData = (data1: any, data2: any) => {
  const normalizeData = (data: any) => {
    if (!data) return null

    // Handle both array and object inputs
    const items = Array.isArray(data) ? data : data?.parameters || data
    return Array.isArray(items) ? items : [items]
  }

  const normalizedData1 = normalizeData(data1)
  const normalizedData2 = normalizeData(data2)

  if (!normalizedData1 || !normalizedData2) {
    return false
  }

  return compareValues(normalizedData1, normalizedData2)
}

export const compareValues = (value1: any, value2: any): boolean => {
  const isEmpty = (value: any) => {
    if (value === undefined || value === null || value === '') return true
    if (Array.isArray(value) && value.length === 0) return true
    if (typeof value === 'object' && Object.keys(value).length === 0) return true
    return false
  }

  if (isEmpty(value1) && isEmpty(value2)) {
    return true
  }

  if (value1 === undefined || value1 === null) {
    return value2 === undefined || value2 === null
  }

  if (typeof value1 !== typeof value2) {
    return false
  }

  switch (typeof value1) {
    case 'string':
      return value1 === value2

    case 'number':
      return value1 === value2

    case 'boolean':
      return value1 === value2

    case 'object': {
      if (value1 === null || value2 === null) {
        return value1 === value2
      }

      if (Array.isArray(value1) && Array.isArray(value2)) {
        if (value1.length !== value2.length) {
          return false
        }
        if (Array.isArray(value1[0])) {
          return value1.every((subArr1: any, index: number) =>
            compareValues(subArr1, value2[index]),
          )
        }

        if (value1.length > 0 && typeof value1[0] === 'object') {
          const sortedArr1 = [...value1].sort((a, b) => {
            const aKey = Object.values(a)[0]
            const bKey = Object.values(b)[0]
            return String(aKey).localeCompare(String(bKey))
          })

          const sortedArr2 = [...value2].sort((a, b) => {
            const aKey = Object.values(a)[0]
            const bKey = Object.values(b)[0]
            return String(aKey).localeCompare(String(bKey))
          })

          return sortedArr1.every((item1, index) => {
            const item2 = sortedArr2[index]
            const allKeys = new Set([...Object.keys(item1), ...Object.keys(item2)])

            return Array.from(allKeys).every((key) => {
              if (key === '_id') return true

              if (item1[key] === '' && (!item2[key] || item2[key] === '')) {
                return true
              }

              if ((!item1[key] || typeof item1[key] === undefined) && item2[key]) {
                return false
              }
              return compareValues(item1[key], item2[key])
            })
          })
        }

        const sorted1 = [...value1].sort()
        const sorted2 = [...value2].sort()
        return JSON.stringify(sorted1) === JSON.stringify(sorted2)
      }

      const keys1 = Object.keys(value1).filter((key) => key !== '_id')
      const keys2 = Object.keys(value2).filter((key) => key !== '_id')

      if (keys1.length !== keys2.length) {
        return false
      }

      return keys1.every((key) => {
        if (!(key in value2)) {
          return value1[key] === '' || value1[key] === null
        }
        if (value1[key] === '' && (!value2[key] || value2[key] === '')) {
          return true
        }

        return compareValues(value1[key], value2[key])
      })
    }

    default:
      return JSON.stringify(value1) === JSON.stringify(value2)
  }
}

export const transformArrayForBehaviour = (arr: any[]) => {
  if (!arr || arr.length === 0) return []
  return arr?.map((item: any) => {
    const transformedItem: any = {}

    transformedItem.parameterId =
      typeof item.parameterId === 'object' ? item.parameterId._id : item.parameterId
    transformedItem.fieldName = item.fieldName
    transformedItem.value = item.value

    if (item.parameterRef && item.parameterRef.length > 0) {
      transformedItem._id = item._id
      transformedItem.parameterRef = item.parameterRef.map((refGroup: any[]) =>
        refGroup.map((refItem: any) => ({
          parameterId:
            typeof refItem.parameterId === 'object' ? refItem.parameterId._id : refItem.parameterId,
          fieldName: refItem.fieldName,
          value: refItem.value,
          _id: refItem._id,
        })),
      )
    }

    return transformedItem
  })
}

export const getFieldValuesData = (
  pageType: string | undefined,
  prefilledData: any[],
  fieldData: any = {},
) => {
  if (pageType !== CONSTANTS.PAGE_TYPE.EDIT) return fieldData

  if (Object.keys(fieldData).length > 0) return fieldData

  const result: { [key: string]: any } = {}

  prefilledData?.forEach((item) => {
    const { parameterId, value, parameterRef } = item
    if (parameterId?.fieldType?.toLowerCase() !== 'file') {
      if (parameterRef && Array.isArray(parameterRef) && parameterRef.length > 0) {
        parameterRef.forEach((group: any, groupIndex: number) => {
          group.forEach((param: any) => {
            const key = `${parameterId?.fieldName}_${groupIndex + 1}_${param.fieldName}`
            result[key] = param.value
          })
        })
      } else {
        result[parameterId?.fieldName] = value
      }
    }
  })

  return result
}

export const getAssetNodes = (response: any, excludedNode: string[], type?: string) => {
  if (!response?.moduleInfo) {
    const nodeResult = []
    for (const key in response) {
      if (!excludedNode.includes(key?.toLowerCase())) {
        if (type === 'assetId') {
          nodeResult.push({
            label: key?.charAt(0)?.toUpperCase() + key.slice(1),
            value: response?.[key],
          })
        } else {
          nodeResult.push(response[key])
        }
      }
    }

    return nodeResult
  }

  return null
}

export const getEmptyStateText = (text: string) => {
  if (!text || text.trim().length === 0) return ''
  return `Please initiate search to add ${text.trim()}.`
}

export const getRadioFilterTitleText = (text: string) => {
  if (!text || text.trim().length === 0) return ''
  return `Please select an ${text.trim()} category to start searching`
}

export const getWidgetDataBasedOnType = (
  layout: string,
  widgetFilterType: keyof WidgetFilterType,
) => {
  const {
    WIDGET_TYPES_LIST,
    SINGLE_ASSET_SELECT,
    WIDGET_TAB_IN_WIDGET_ASSET_MODAL,
    WIDGET_FILTER,
    TABS_IN_ASSET_MODAL,
    CONTAINER_TAB_IN_WIDGET_ASSET_MODAL,
    RADIO_OPTIONS_WITHOUT_GOB,
    ASSET_FILTER,
  } = CONSTANTS
  const widgetModuleData: WidgetFilterType = {
    singleAssetSelect: SINGLE_ASSET_SELECT,
    categoryPageLayout: WIDGET_TYPES_LIST.categoryPageLayout,
    widgetTabList: WIDGET_TAB_IN_WIDGET_ASSET_MODAL,
    containerTabList: CONTAINER_TAB_IN_WIDGET_ASSET_MODAL,
    celebrityLayout: WIDGET_TYPES_LIST.celebrityLayout,
    spotLightLayout: WIDGET_TYPES_LIST.spotlightLayout,
    widgetTabFilter: WIDGET_FILTER,
    assetTabFilter: ASSET_FILTER,
    siKeyMomentsTabView: WIDGET_TYPES_LIST.siKeyMomentsTabView,
    tabLayout: WIDGET_TYPES_LIST.tabLayout,
    tabsInModal: TABS_IN_ASSET_MODAL,
    radioOptionsWithoutGob: RADIO_OPTIONS_WITHOUT_GOB,
    filterBasedLayoutLandscape: WIDGET_TYPES_LIST.filterBasedLayoutLandscape,
    filterBasedLayoutPortrait: WIDGET_TYPES_LIST.filterBasedLayoutPortrait,
    filterBasedLayoutSquare: WIDGET_TYPES_LIST.filterBasedLayoutSquare,
    cardSkinnedVideo: WIDGET_TYPES_LIST.cardSkinnedVideo,
  }

  if (!layout || !widgetFilterType || !widgetModuleData[widgetFilterType]) return false

  const widgetModuleDataValue = widgetModuleData[widgetFilterType]
  if (typeof widgetModuleDataValue === 'string') {
    return layout.trim().toLowerCase() === widgetModuleDataValue.trim().toLowerCase()
  }

  return widgetModuleDataValue.includes(layout.trim().toLowerCase())
}

export const getCheckedValue = (checked?: boolean | 'undefined') => {
  if (!checked) return false
  if (checked === 'undefined') return undefined
  return checked
}

export const getSortTypeValue = (sortType: number, colType: string | number) => {
  const { ASSET_TITLE, ASSET_TYPE } = MODULE_CONSTANTS
  if (sortType && String(colType) === 'title') return ASSET_TITLE
  if (String(colType) === 'type') return ASSET_TYPE
  return colType
}

export const getFilterValues = (
  filterBy: 'layout' | 'type' | 'state',
  filterValue: string | string[],
  filterCondition: 'in' | 'contains' = 'in',
) => {
  return {
    filterBy,
    filterCondition,
    filterValue,
  }
}

export const convertObjectToArray = (obj: any) => {
  if (!obj || typeof obj !== 'object' || Object.keys(obj).length === 0) return []
  return Object.keys(obj).map((key: string) => {
    const title = key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase())
    return {
      title: title,
      url: obj[key],
    }
  })
}

export const findIdInObject = (obj: DataObject): { id: string; module: string } | undefined => {
  if (!obj) return undefined
  const { ASSET, WIDGET, CONTAINER } = MODULE_CONSTANTS
  if (obj.assetId) {
    return { id: obj.assetId, module: ASSET }
  }
  if (obj.widgetId) {
    return { id: obj.widgetId, module: WIDGET }
  }
  if (obj.containerId) {
    return { id: obj.containerId, module: CONTAINER }
  }
  if (obj.siteNavigationId) {
    return { id: obj.siteNavigationId, module: 'siteNavigation' }
  }
  if (obj.contentId) {
    return { id: obj.contentId, module: obj.module }
  }
  return undefined
}

export const buildContentArray = (data: DataObject[]): ResultObject[] => {
  if (!data || data.length === 0) return []
  const results: ResultObject[] = []
  data.forEach((obj, index) => {
    const result = findIdInObject(obj)
    if (result) {
      results.push({
        contentId: `${result.id}`,
        module: result.module,
        position: index + 1,
      })
    }
  })
  return results
}

export const getImageNodeValue = (data: any) => {
  if (!data) return []
  const images = data?.images?.value || data?.blitzImages?.value
  if (images && typeof images === 'object' && Object.keys(images).length) {
    return convertObjectToArray(images)
  }
  return []
}

export const getWidgetCheckedOrDisabledValue = (
  listToTest: any[],
  dataToMatch: any,
  filterType: string,
) => {
  if (!listToTest?.length || !dataToMatch || !filterType) return false
  return listToTest?.some((el: any) => {
    const IDKeyAsset = getKeyByType(el, 'id')
    return (
      el[filterType] !== null &&
      dataToMatch[filterType] !== null &&
      el[IDKeyAsset].toString() === dataToMatch[filterType].toString()
    )
  })
}
export const deepCompareArrays = (arr1: any[], arr2: any[]): boolean => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false
  if (arr1.length !== arr2.length) return false

  return arr1.every((obj, index) => {
    const obj2 = arr2[index]

    if (typeof obj !== 'object' || typeof obj2 !== 'object') return obj === obj2

    const keys1 = Object.keys(obj)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) return false

    return keys1.every((key) => {
      if (Array.isArray(obj[key]) && Array.isArray(obj2[key])) {
        return deepCompareArrays(obj[key], obj2[key]) // Recursively compare nested arrays
      } else if (typeof obj[key] === 'object' && typeof obj2[key] === 'object') {
        return deepCompareArrays([obj[key]], [obj2[key]]) // Recursively compare nested objects
      } else {
        return obj[key] === obj2[key] // Compare primitive values
      }
    })
  })
}

export const setSessionStorage = (key: string, value: any, route?: string) => {
  if (route) {
    sessionStorage.setItem('route', route)
  }
  sessionStorage.setItem(key, JSON.stringify(value))
}

export const getSessionStorage = (key: string) =>
  sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key) ?? '') : ''

export const removeLocalFilters = () => {
  sessionStorage.removeItem('route')
  sessionStorage.removeItem('pageData')
  sessionStorage.removeItem('localFilters')
}

const getURL = (event: any) => {
  if (event?.isChildPageRequired?.toLowerCase() === 'yes') {
    return `/${PAGES_ROUTES.ManageContainer}/${PAGES_ROUTES.ChildContainer}/${event._id}`
  } else {
    return `/${PAGES_ROUTES.ManageContainer}/${PAGES_ROUTES.PageTypeView}/${event._id}`
  }
}

export const getListingRowData = (
  data: any,
  columnHeader: any,
  pageRoute: string,
  extraParams?: any,
  module?: string,
) => {
  const tempData: any = []
  if (columnHeader?.length === 0) return tempData

  data?.forEach((ele: any, index: number) => {
    const row: any = {}

    columnHeader?.forEach((key: any) => {
      const dataId = key?.id
      switch (dataId) {
        case 'engine':
          row[dataId] =
            ele[dataId] === 'NA'
              ? ele[dataId]
              : ele[dataId]?.charAt(0).toUpperCase() + ele[dataId]?.slice(1).toLowerCase()
          break
        case 'createdAt':
        case 'updatedAt':
        case 'lastFeaturedDate':
        case 'featuredDateTime':
        case 'unFeaturedDateTime':
          row[dataId] = getDateInFormat(ele[dataId] ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS)
          break
        case 'state':
          row[dataId] = React.createElement(AssetStateLabel, { state: ele.stateDisplayName })
          break
        default:
          row[dataId] = ele[dataId]
          break
      }
    })

    extraParams &&
      extraParams.forEach((param: any) => {
        if (ele[param] !== undefined) {
          row[param] = ele[param]
        }
      })

    if (module === 'container') {
      row.rowURL = getURL(ele)
    } else {
      row.rowURL = `/${pageRoute}/${PAGES_ROUTES.PageTypeView}/${ele._id}`
    }

    tempData.push(row)
  })
  return tempData
}

export const isEveryItemInListSelected = (selectedItemList: any[], listData: any[]) => {
  const allSelectedItemsIdSet = new Set(
    selectedItemList.map((item) => item[getKeyByType(item, 'id')]?.toString()),
  )

  return listData.every((item) =>
    allSelectedItemsIdSet.has(item[getKeyByType(item, 'id')]?.toString()),
  )
}

export const handleSelectAllCheckOrUncheck = (
  selectedItemsLists: any[],
  listData: any[],
  selectAllCheckedPageNumber: {
    [key: string]: Set<number>
  },
  selectedTab: string,
  currentPageNumber: number,
) => {
  let updatedSelectAllPageNumber = { ...selectAllCheckedPageNumber }

  const isCurrentPageSelected = updatedSelectAllPageNumber?.[selectedTab]?.has?.(currentPageNumber)
  if (selectedItemsLists.length < listData.length) {
    if (isCurrentPageSelected) {
      updatedSelectAllPageNumber = handleCurrentPageSelectionTrack(
        updatedSelectAllPageNumber,
        selectedTab,
        currentPageNumber,
        'removed',
      )
    }
  } else {
    if (!isCurrentPageSelected) {
      updatedSelectAllPageNumber = handleCurrentPageSelectionTrack(
        updatedSelectAllPageNumber,
        selectedTab,
        currentPageNumber,
        'added',
      )
    }
  }
  return {
    isSelectAllChecked: isEveryItemInListSelected(selectedItemsLists, listData),
    selectedPageNumber: updatedSelectAllPageNumber,
  }
}

export const handleCurrentPageSelectionTrack = (
  selectAllCheckedPageNumber: {
    [key: string]: Set<number>
  },
  selectedTab: string,
  currentPageNumber: number,
  selectionType: counterType = 'removed',
) => {
  if (
    !selectedTab ||
    !selectAllCheckedPageNumber ||
    Object.keys(selectAllCheckedPageNumber).length === 0
  )
    return selectAllCheckedPageNumber

  const selectedTabCheckedPageNumber = selectAllCheckedPageNumber[selectedTab] ?? new Set()

  const updatedSet = new Set(selectedTabCheckedPageNumber)

  if (selectionType === 'removed') {
    updatedSet.delete(currentPageNumber)
  } else {
    updatedSet.add(currentPageNumber)
  }

  return {
    ...selectAllCheckedPageNumber,
    [selectedTab]: updatedSet,
  }
}

export const isItemSelectedOrSaved = (list: any[], itemIdValue: string | number) => {
  if (list.length === 0 || !itemIdValue) return false
  return list.some((selectedItem) => {
    const selectedItemIdKey = getKeyByType(selectedItem, 'id')
    return selectedItem[selectedItemIdKey]?.toString() === itemIdValue.toString()
  })
}
export const mapPositionsToIds = (listDataToCheck: any[]): Map<number, string> => {
  if (listDataToCheck.length === 0) return new Map()

  listDataToCheck.sort((firstItem, secondItem) => firstItem.position - secondItem.position)
  const positionMap = new Map<number, string>()
  listDataToCheck.forEach((item: any) => {
    let { position } = item
    if (!position) return
    while (positionMap.has(position)) {
      position++
    }
    const itemId = getDynamicRowValue(item, 'id')
    if (!itemId) return
    positionMap.set(position, itemId.toString())
  })
  return positionMap
}

const getItemIdValue = (item: any) => {
  if (!item) return ''
  const itemId = getDynamicRowValue(item, 'id')
  if (typeof itemId === 'object') return itemId?.value ?? ''
  return itemId?.toString() ?? ''
}
export const updateArrayWithMappedPositions = (listData: any[], mapPositionsToIdsList: any[]) => {
  if (mapPositionsToIdsList.length === 0 || listData.length === 0) return listData
  const itemMappedPositionIds = mapPositionsToIds(mapPositionsToIdsList)

  const idToPosition = new Map([...itemMappedPositionIds].map(([pos, id]) => [id, pos - 1]))

  const result = Array(listData.length).fill(null)
  const remainingItems: any[] = []

  listData.forEach((item) => {
    const itemId = getItemIdValue(item)
    if (itemId && idToPosition.has(itemId.toString())) {
      const position = idToPosition.get(itemId.toString())
      if (position !== undefined) {
        result[position] = item
      } else {
        remainingItems.push(item)
      }
    } else {
      remainingItems.push(item)
    }
  })

  let remainingIndex = 0
  for (let i = 0; i < result.length; i++) {
    if (!result[i]) {
      result[i] = remainingItems[remainingIndex++]
    }
  }
  return result.filter(Boolean)
}
