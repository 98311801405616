import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../../../Constants'
import { getCapitalise, getPageTitle } from '../../../../Helpers/Util'
import { selectEditObj } from '../../../../Store/Slice/system-configuration.slice'
import MastersEditorLayout from '../Layouts/MastersEditorLayout'
import SystemConfigurationForm from './SystemConfigurationForm'
import updateTagMetaData from '../../../../Helpers/UpdateTagMetaData'

const SystemConfigurationEditor = ({
  systemConfigurationPageType,
  mastersPageType,
  mastersId,
  masterObj,
}: any) => {
  const navigate = useNavigate()
  const editSystemConfig = useSelector(selectEditObj)

  const onClickBackNavigation = (e: { preventDefault: () => void }) => {
    e?.preventDefault()
    navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${masterObj}`)
  }
  useEffect(() => {
    updateTagMetaData({ title: `Masters: ${getCapitalise(systemConfigurationPageType)}` })
    return () => {
      updateTagMetaData({})
    }
  }, [])

  return (
    <MastersEditorLayout
      mastersPageType={mastersPageType}
      pageText={systemConfigurationPageType}
      onClickBackNavigation={onClickBackNavigation}
      breadcrumbs={
        BREADCRUMS.CREATE_SYSTEMCONFIGURATION(
          mastersPageType,
          getPageTitle(mastersPageType as string),
          'Value',
        ) as []
      }
    >
      {mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE && mastersId && (
        <SystemConfigurationForm
          mastersPageType={mastersPageType}
          masterObj={masterObj}
          mastersId={mastersId}
          editSystemConfig={editSystemConfig}
        />
      )}
      {mastersPageType === CONSTANTS.PAGE_TYPE.CREATE && (
        <SystemConfigurationForm mastersPageType={mastersPageType} masterObj={masterObj} />
      )}
    </MastersEditorLayout>
  )
}

export default React.memo(SystemConfigurationEditor)
