import { Checkbox } from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { getCheckedValue } from '../../../Helpers/Util'

interface ICheckboxComponent {
  checkboxId: string | number
  checked?: boolean | 'undefined'
  onChange: () => void
  disabled?: boolean
  dataTestId?: string
  checkboxStyle?: React.CSSProperties
}

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const CheckboxComponent: React.FC<ICheckboxComponent> = ({
  checkboxId,
  onChange,
  checked,
  dataTestId,
  disabled,
  checkboxStyle,
}: ICheckboxComponent) => {
  return (
    <Checkbox
      id={checkboxId.toString()}
      icon={icon}
      checkedIcon={checkedIcon}
      style={{ marginRight: 8, ...checkboxStyle }}
      checked={getCheckedValue(checked)}
      disabled={disabled}
      onChange={onChange}
      data-testid={dataTestId ?? `checkbox-${checkboxId}`}
    />
  )
}

export default CheckboxComponent
