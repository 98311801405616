import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import CONSTANTS from '../Constants'
import { getLocalStorage } from '../Helpers/Storage'
import {
  checkLastUpdatedPayloadFunction,
  getParsedToken,
  handleLastUpdateAPI,
  logoutCyberArk,
  setRefreshTokenCallback,
} from '../Helpers/Util'
import { store } from '../Store'
import { showAlert } from '../Store/Slice/alert.slice'

axios.interceptors.request.use(
  (config) => {
    const initialHeader = config.headers['Content-Type']
    const configCopy = { ...config }
    configCopy.headers['Content-Type'] = initialHeader || 'application/json'
    const parsedToken: any = getParsedToken()
    if (parsedToken?.sub?.sessionId) {
      configCopy.headers['device_id'] = `${parsedToken.sub.sessionId}${
        parsedToken?.exp ? '-' + parsedToken.exp : ''
      }`
      configCopy.headers[
        'session_id'
      ] = `${parsedToken.sub.sessionId}-${window?.navigator?.userAgent}`
    }
    if (!configCopy.headers['Authorization'] && !config.headers['upload']) {
      const { token = '' } = getLocalStorage('auth_config') as any
      if (token) configCopy.headers['Authorization'] = ` bearer ${token}`
    }
    return configCopy
  },
  (error) => Promise.reject(error),
)

axios.interceptors.response.use(
  function (response) {
    setRefreshTokenCallback()
    return response?.data
  },
  function (error) {
    switch (error?.response?.status) {
      case 401:
        logoutCyberArk(error?.response?.data?.message)
        break
      case 500:
        store.dispatch(showAlert([true, 'Internal Server Error', 'error']))
        // window.location.href = PAGES_ROUTES.ErrorPage
        break
    }
    if (error?.response?.data) throw error.response.data
    throw error
  },
)

const mainApiService = async ({
  baseUrl,
  url,
  method,
  data,
  params,
  headers,
  withoutBaseURL = false,
  signal,
  validate = false,
}: {
  baseUrl?: string | undefined
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
  headers?: AxiosRequestConfig['headers']
  withoutBaseURL?: boolean
  signal?: any
  validate?: boolean
}): Promise<{
  data?: AxiosRequestConfig['data']
  error?: any
  responseCode?: any
  message?: any
  errorMessage?: string[]
  isCheckFailed?: boolean
}> => {
  try {
    const bUrl = baseUrl ?? CONSTANTS.BASE_URL
    let apiURL: string = bUrl + url
    if (withoutBaseURL) {
      apiURL = url
    }
    if (validate) {
      const idType = data?.containerId
        ? 'container'
        : data?.widgetId
        ? 'widget'
        : data.siteNavigationId
        ? 'siteNavigation'
        : null
      if (idType) {
        const res = await handleLastUpdateAPI(checkLastUpdatedPayloadFunction(idType))
        if (!res?.data?.isEditable) {
          return {
            isCheckFailed: true,
            responseCode: 400,
            error: { errors: [res?.data?.alertMessage] },
            errorMessage: res?.data?.alertMessage,
          }
        }
      }
    }

    const result = await axios({
      url: apiURL,
      method,
      data,
      params,
      headers,
      ...(signal ? { signal } : {}),
    })
    return result as AxiosResponse
  } catch (axiosError) {
    const err = axiosError as any
    const errorMessage = err?.response?.data?.errors || []
    return { error: err, errorMessage }
  }
}

export default mainApiService
