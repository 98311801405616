import React, { useEffect } from 'react'
import { ChipButton, MUIBox } from '../../Shared'
import { EditIcon } from '../../Icons'
import { useNavigate } from 'react-router-dom'
import { DeleteOutlineOutlined } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { TooltipText } from '../../Shared/TooltipText/TooltipText'
import CONSTANTS, { PAGES_ROUTES } from '../../../Constants'
import { RoleConfirmationModal } from '../../../Models'
import RoleConfirmation from '../../Dialogs/RoleConfirmation'
import { MANAGE_TIPS } from '../../../Constants/ApiDefinations'
import mainApiService from '../../../Services'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { getSanitizedValue } from '../../../Helpers/Util'
import updateTagMetaData from '../../../Helpers/UpdateTagMetaData'

interface TipsInformationModel {
  pageInfo: any
}

const TipsInformation: React.FC<TipsInformationModel> = ({ pageInfo }) => {
  const [dialogProps, setDialogProps] = React.useState<RoleConfirmationModal>({
    heading: '',
    description: '',
    color: 'primary',
    open: false,
  })
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const openDialog = (type: string) => {
    if (type === 'delete-warning') {
      setDialogProps({
        heading: CONSTANTS.DELETE_TIP,
        description: CONSTANTS.MESSAGES.SEGMENT_BLOCK_ERROR,
        color: 'warning',
        Icon: 'DeleteRoleWarIcon',
        open: true,
        iconColor: 'warning',
        cancelButtonLabel: null,
        successButtonLabel: 'Okay',
        handleSuccess: handleClose,
      })
    }
    if (type === 'delete') {
      setDialogProps({
        heading: CONSTANTS.DELETE_TIP,
        description: (
          <div>
            <p>Are you sure you want to delete this tooltip?</p>
          </div>
        ),
        color: 'error',
        Icon: 'DeleteRoleIcon',
        open: true,
        iconColor: 'error',
        successButtonLabel: 'Delete',
        cancelButtonLabel: 'Cancel',
        handleSuccess: handleDelete,
      })
    }
  }
  const handleDeleteClick = () => {
    openDialog('delete')
  }

  const handleClose = () => {
    setDialogProps({
      heading: '',
      description: '',
      color: 'primary',
      open: false,
    })
  }

  const handleDelete = async () => {
    const requestedData = MANAGE_TIPS.TIPS_DELETE(pageInfo?._id)

    const result = await mainApiService(requestedData)
    if (result?.responseCode === 200) {
      dispatch(showAlert([true, result?.message, 'success']))
      handleClose()
      navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ManageTips}`)
    } else {
      handleClose()
      setDialogProps({
        heading: CONSTANTS.DELETE_TIP,
        description: result?.message,
        color: 'warning',
        Icon: 'DeleteRoleIcon',
        open: true,
        iconColor: 'warning',
        cancelButtonLabel: null,
        successButtonLabel: 'Okay',
        handleSuccess: handleClose,
      })
      dispatch(showAlert([true, result?.message, 'error']))
    }
  }
  useEffect(() => {
    updateTagMetaData({ title: 'Manage Tips' })
    return () => {
      updateTagMetaData({})
    }
  }, [])
  return (
    <>
      <MUIBox>
        <div>
          <div className='heading-6 d-flex text-left rolebox__header-padding'>
            <div className='d-flex align-items-center'>Tips Contents</div>
            <div className='ml-auto d-flex'>
              <div
                className='mr-1'
                data-testid='edit__chip-btn'
                onClick={() =>
                  navigate(
                    `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ManageTips}/edit/${pageInfo?._id}`,
                  )
                }
              >
                <ChipButton type='primary' Icon={EditIcon} label='Edit' />
              </div>

              <div onClick={handleDeleteClick} data-testid='delete__chip-btn'>
                <ChipButton type='danger' Icon={DeleteOutlineOutlined} label='Delete' />
              </div>
            </div>
          </div>
          <div className={'hr'}></div>
          <div className=''>
            <Grid container>
              <Grid item xs={6} md={3}>
                <div className='sub-heading-6 view__role-list  text-left col34'>Key</div>
              </Grid>
              <Grid item xs={6} md={9}>
                <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>
                  <TooltipText text={pageInfo?.key?.value} length={40} />
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6} md={3}>
                <div className='sub-heading-6 view__role-list  text-left col34'>Value</div>
              </Grid>
              <Grid item xs={6} md={9}>
                <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>
                  {pageInfo?.value}
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6} md={3}>
                <div className='sub-heading-6 view__role-list  text-left col34'>Module</div>
              </Grid>
              <Grid item xs={6} md={9}>
                <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>
                  {pageInfo?.module?.value}
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6} md={3}>
                <div className='sub-heading-6 view__role-list  text-left col34'>
                  Tooltip Description
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                <div
                  className='sub-heading-6 view__role-list  text-left col36 word-wrap content-container'
                  dangerouslySetInnerHTML={{ __html: getSanitizedValue(pageInfo?.description) }}
                ></div>
              </Grid>
            </Grid>
          </div>
        </div>
      </MUIBox>
      <RoleConfirmation {...dialogProps} handleClose={handleClose} />
    </>
  )
}

export default React.memo(TipsInformation)
